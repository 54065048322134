import styled, { css } from 'styled-components'

import * as breakpoint from '../../constants/breakpoints'
import * as color from '../../constants/colors'
import * as sizes from '../../constants/sizes'

import * as copy from '../copy'
import * as icons from '../../svg/icons'

import { Button, TextInput } from './input'

export const BaseWrapper = styled.div`
    box-sizing: border-box;
    border: none;
    position: relative;
`

export const ResultWrapper = styled.li<{ isFocused: boolean }>`
    @keyframes resultFadeIn {
        to {
            opacity: 1;
        }
    }
    animation: resultFadeIn 600ms forwards;
    cursor: pointer;
    opacity: 0;

    @media screen and (${breakpoint.TABLET}) {
        opacity: 1;
        background: ${({ isFocused }) => (isFocused ? `${color.SAND_2}` : 'transparent')};
    }
`

const BaseDrawer = styled.div`
    max-height: 0;
`

export const Drawer = styled(BaseDrawer)<{ isOpen: boolean }>`
    ${({ isOpen }) => {
        if (isOpen) {
            return css`
                max-height: 500px;
                /* needs improvements */
                /* transition: max-height 600ms cubic-bezier(0.85, 0, 0.15, 1); */
            `
        }
        if (!isOpen) {
            return css`
                visibility: hidden;
            `
        }
    }};
`

export const ScrollList = styled.ul``

export const Result = styled(copy.Text1)`
    height: 60px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;

    padding: 15px;

    @media screen and (${breakpoint.TABLET}) {
        height: 50px;
    }
`

export const History = styled(Result)`
    ${icons.Icon} {
        position: relative;
        top: -2px;
    }

    ${icons.Clock} {
        margin-right: 12px;
    }

    ${icons.Trash} {
        margin-left: 12px;
    }
`

export const HistoryText = styled.div`
    flex-grow: 1;
`

export const NoResult = styled.div`
    padding: 10px 15px;
`

export const Layout = styled.div`
    @media not screen and (${breakpoint.TABLET}) {
        background-color: ${color.SAND_1};
        animation: fadeIn 1s forwards;
        @keyframes fadeIn {
            to {
                opacity: 1;
            }
        }
    }
    @media screen and (${breakpoint.TABLET}) {
        width: 100%;
    }
`

export const SearchDisplay = styled.div`
    overflow: auto;

    @media screen and (${breakpoint.TABLET}) {
        overflow: initial;
        background-color: ${color.SAND_1};
        border-radius: 0 0 12px 12px;
        position: absolute;
        top: 100%;
        width: 100%;
    }
`

export const Close = styled.div`
    margin-top: 25px;
    margin-bottom: 13px;
    height: 40px;
    width: 40px;

    @media screen and (${breakpoint.TABLET}) {
        display: none;
    }
`

export const Wrapper = styled(BaseWrapper)<{ isOpen: boolean }>`
    @media not screen and (${breakpoint.TABLET}) {
        ${SearchDisplay} {
            ${({ isOpen }) => {
                if (isOpen)
                    return css`
                        display: block;
                    `
                if (!isOpen)
                    return css`
                        display: none;
                    `
            }}
        }
        ${Layout} {
            ${({ isOpen }) => {
                if (isOpen)
                    return css`
                        display: flex;
                        flex-direction: column;
                        height: calc(100vh - ${sizes.HEADER_MOBILE_INTERNAL_HEIGHT});
                        left: 0;
                        opacity: 0;
                        overflow: hidden;
                        padding: 0 20px;
                        position: fixed;
                        top: calc(${sizes.HEADER_MOBILE_INTERNAL_HEIGHT});
                        width: 100%;
                        z-index: 100;
                    `
                if (!isOpen) return css``
            }}
        }
        ${Close} {
            flex: none;
            ${({ isOpen }) => {
                if (isOpen)
                    return css`
                        display: block;
                    `
                if (!isOpen)
                    return css`
                        display: none;
                    `
            }}
        }
    }

    @media screen and (${breakpoint.TABLET}) {
        ${({ isOpen }) => {
            if (isOpen) {
                return css`
                    ${TextInput} {
                        border-bottom: none;
                        border-bottom-left-radius: 0;
                    }
                    ${Button} {
                        border-bottom: none;
                        border-bottom-right-radius: 0;
                    }
                    ${SearchDisplay} {
                        transform: translateY(-2px);
                        border: 1px solid ${color.GRAY_3};
                        border-top: none;
                    }
                `
            }
        }}
    }
`

export const ToggleInput = styled.div`
    @media screen and (${breakpoint.TABLET}) {
        display: none;
    }
`
