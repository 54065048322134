import useSWRImmutable from 'swr/immutable'

import * as core from '@bob/core-services'
import * as entity from '@bob/entities'

export function useShippingRates(
    country: string | undefined,
    items: {
        quantity: number
        shopifyVariantId: number
    }[],
    vendors: string[]
): {
    error?: any
    freeShippingThresholds?: entity.shipping.FreeShippingThresholds
    rates?: entity.shipping.Rate[]
    supportedCountries?: entity.shipping.SupportedCountries
} {
    /* Why is the useSWR key so contrived ? Why not just use [country, items, vendors] ?
    Well, because even though the contents of `items` or `vendors` might not change, the objects
    might change. So we need to "flatten" the object down to an array of primitives (string and numbers)
    */
    const key = [
        country,
        ...items.flatMap(item => [item.quantity, item.shopifyVariantId]),
        ...vendors,
        'shipping-rates'
    ]

    const { data, error } = useSWRImmutable(key, {
        fetcher: async () => {
            if (country === undefined || items.length === 0 || vendors.length === 0) {
                return undefined
            }
            const response = await core.shipping.client.getShippingRates(
                country,
                items,
                vendors
            )

            return response.doThrow()
        },
        dedupingInterval: 300
    })

    return {
        error,
        freeShippingThresholds: data?.freeShippingThresholds,
        rates: data?.rates,
        supportedCountries: data?.supportedCountries
    }
}
