import * as logger from '@bob/logger'
import { IS_IN_BROWSER } from '@bob/utils/browser'
import {
    Query,
    rawRequester,
    Request,
    GraphQLClient,
    ClientError
} from '@bob/utils/graphql'

export { fragment, query, gqlToQuery } from '@bob/utils/graphql'

const SHOPIFY_BASE_URL = process.env.NEXT_PUBLIC_SHOPIFY_URL
const SHOPIFY_API_VERSION = process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION

const STOREFRONT_REQUEST = rawRequester(
    new GraphQLClient(`${SHOPIFY_BASE_URL}/api/${SHOPIFY_API_VERSION}/graphql.json`, {
        headers: {
            'X-Shopify-Storefront-Access-Token':
                process.env.NEXT_PUBLIC_SHOPIFY_TOKEN || ''
        }
    })
)

const ADMIN_REQUEST = rawRequester(
    new GraphQLClient(
        `${SHOPIFY_BASE_URL}/admin/api/${SHOPIFY_API_VERSION}/graphql.json`,
        {
            headers: {
                'X-Shopify-Access-Token': process.env.SHOPIFY_ADMIN_TOKEN || ''
            }
        }
    )
)

const MAX_RETRIES = 5

export function requester(
    rawRequest: typeof STOREFRONT_REQUEST | typeof ADMIN_REQUEST
): Request {
    return request

    async function request<RESULT = any, VARIABLES = Record<string, any>>(
        query: Query<RESULT, VARIABLES>,
        variables: VARIABLES,
        allowRetry = IS_IN_BROWSER,
        retry = 0
    ): Promise<RESULT> {
        try {
            return rawRequest(query, variables, allowRetry)
        } catch (error) {
            if (!(error instanceof ClientError)) {
                throw error
            }

            const response = error.response

            if (response === undefined) {
                logger.err(`[Shopify] Unknown request error`, {
                    request: { query, variables },
                    error
                })
                throw error
            }

            // Exponential backoff
            if (response.status === 200 && allowRetry && retry < MAX_RETRIES) {
                const wait = Math.floor(2 ** retry + Math.random() * 200 + 500)
                await new Promise((res, rej) => {
                    setTimeout(res, wait)
                })
                return request(query, variables, allowRetry, retry + 1)
            }

            let message
            if (response.errors?.length) {
                message = ` "${response.errors[0].message}"`
            }

            logger.err(`[Shopify] Request error - Code ${response.status}${message}`, {
                request: { query, variables },
                error
            })

            throw error
        }
    }
}

export const request = requester(STOREFRONT_REQUEST)
export const adminRequest = requester(ADMIN_REQUEST)
