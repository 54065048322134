import styled from 'styled-components'
import type { StyledComponent } from 'styled-components'

import * as copy from './copy'

export const Base = styled(copy.TextHyperlink).attrs(props => ({
    as: 'a',
    ...props
}))`
    cursor: pointer;
` as StyledComponent<'a', any>
