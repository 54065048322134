import styled, { css } from 'styled-components'

import { Picture, PictureProps } from './Picture'
import { BLOB_VARIANTS, BlobBackground, BaseBlobBackgroundProps } from './BlobBackground'

const FACTORS: Record<keyof typeof BLOB_VARIANTS, number> = {
    1: 1 / 7,
    2: 1 / 9,
    3: 1 / 15,
    4: 1 / 12,
    5: 1 / 20,
    6: 1 / 20
}

export type BlobPictureProps = Omit<
    BaseBlobBackgroundProps,
    'marginLeft' | 'marginRight' | 'marginTop' | 'marginBottom'
> &
    Omit<PictureProps, 'color' | 'size'> & {
        size: number
        sizing?: 'cover' | 'contain'
    }

function BlobPictureComponent({
    size = 100,
    className,
    color = 'yellow',
    variant = 1,
    sizing = 'cover',
    ...pictureProps
}: BlobPictureProps) {
    const factor = FACTORS[variant]
    return (
        <BlobBackground
            color={color}
            variant={variant}
            marginLeft={size * factor}
            marginRight={size * factor}
            marginTop={size * factor}
            marginBottom={size * factor}
            className={className}
        >
            <Picture sizing={sizing} {...pictureProps} />
        </BlobBackground>
    )
}

const BaseBlobPicture = styled(BlobPictureComponent)`
    ${Picture} {
        display: block;
        border-radius: 50%;
    }
`

export const BlobPicture = styled(BaseBlobPicture)`
    ${({ size }) => {
        return css`
            ${Picture} {
                width: ${size}px;
                height: ${size}px;
            }
        `
    }}
`
