import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type MomentGridProps = React.HTMLAttributes<HTMLDivElement>

function MomentGridComponent({ children, ...rest }: MomentGridProps): React.ReactElement {
    return (
        <S.momentGrid.Grid {...rest}>
            {React.Children.map(children, child => {
                return <S.momentGrid.Item>{child}</S.momentGrid.Item>
            })}
        </S.momentGrid.Grid>
    )
}

export const MomentGrid = styled(MomentGridComponent)``
