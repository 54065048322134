import * as React from 'react'

type LocalStorageInterface = {
    storage: Record<string, string> | undefined
    setStorage: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>
}

export const localStorageContext = React.createContext<LocalStorageInterface | undefined>(
    undefined
)

export function useLocalStorageContext(): LocalStorageInterface {
    const localStorageInterface = React.useContext(localStorageContext)

    if (localStorageInterface === undefined) {
        throw Error("can't useLocalStorage outside LocalStorageProvider")
    }

    return localStorageInterface
}
