import * as React from 'react'
import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'

export const Grid = styled.div<React.HTMLAttributes<HTMLDivElement>>`
    display: flex;
    @media not screen and (${breakpoint.TABLET}) {
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;
        overflow-x: auto;
        width: calc(100% + 30px);
        margin: 0 -20px;
        padding-bottom: 10px;
        scroll-snap-type: x mandatory;
    }
    @media screen and (${breakpoint.TABLET}) {
        flex-wrap: wrap;
        margin: -10px;
    }
`

export const Item = styled.div<React.HTMLAttributes<HTMLDivElement>>`
    @media not screen and (${breakpoint.TABLET}) {
        padding: 5px 20px 0;
    }
    @media screen and (${breakpoint.TABLET}) {
        padding: 0 10px;
    }
`
