import * as React from 'react'
import styled from 'styled-components'

import { useOnClickOutside, useScrollLock, useTransition } from '../hooks'
import * as S from '../styles'

export type PopupProps = {
    children: React.ReactNode
    trigger: React.RefObject<HTMLElement>
    className?: string
    description: string
    footer?: React.ReactNode
    onClose?: () => void
    open: boolean
    title: string
}

function PopupComponent({
    children,
    trigger,
    className,
    description,
    footer,
    onClose,
    open = false,
    title
}: PopupProps): React.ReactElement {
    useScrollLock(open)
    const { onTransitionEnd, step, unmounted } = useTransition(open)

    const $popupRef = React.useRef<HTMLDivElement>(null)
    const $popupContentRef = React.useRef<HTMLDivElement>(null)

    useOnClickOutside([$popupRef, trigger], () => onClose && onClose())

    // Listen to content scroll and mutation to show inset shadow when overflow exists.
    const listenToScroll = React.useCallback(event => {
        requestAnimationFrame(() => {
            handlePopupContentChange(event.target)
        })
    }, [])

    React.useEffect(() => {
        const node = $popupContentRef.current
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                handlePopupContentChange(mutation.target as HTMLDivElement)
            })
        })

        if (open && node !== null) {
            node.addEventListener('scroll', listenToScroll)
            observer.observe(node, { childList: true })
        } else if (!open) {
            node?.removeEventListener('scroll', listenToScroll)
            observer.disconnect()
        }

        return () => {
            node?.removeEventListener('scroll', listenToScroll)
            observer.disconnect()
        }
    }, [open])

    const [contentBottomReached, setContentBottomAsReached] = React.useState(false)

    if (unmounted) return <></>

    return (
        <>
            <S.popup.Overlay onClick={handleOverlayClick} visible={step === 'during'} />
            <S.popup.Wrapper
                className={className}
                onTransitionEnd={onTransitionEnd}
                open={open}
                ref={$popupRef}
                step={step}
            >
                <S.popup.Header>{title}</S.popup.Header>
                <S.popup.Description>{description}</S.popup.Description>
                <S.popup.Content popupHasFooter={!!footer} ref={$popupContentRef}>
                    {children}
                </S.popup.Content>
                {footer && (
                    <S.popup.Footer showInsetShadow={!contentBottomReached}>
                        {footer}
                    </S.popup.Footer>
                )}
            </S.popup.Wrapper>
        </>
    )

    function handleOverlayClick(event: React.MouseEvent<HTMLDivElement>): void {
        if ($popupRef.current === null) {
            return
        }

        if (!$popupRef.current.contains(event.target as Node)) {
            onClose && onClose()
        }
    }

    function handlePopupContentChange(popupContent: HTMLDivElement) {
        const contentBottomReached =
            popupContent.offsetHeight + popupContent.scrollTop >=
            popupContent.scrollHeight
        setContentBottomAsReached(contentBottomReached)
    }
}

export const Popup = styled(PopupComponent)``
