export const GRAY_1 = '#899094'
export const GRAY_2 = '#4E595F'
export const GRAY_3 = '#13222A'

export const WHITE = '#FFFFFF'

export const SAND_1 = '#FFFDF6'
export const SAND_2 = '#FAF7EF'
export const SAND_3 = '#F6F3EA'
export const SAND_4 = '#EBE4D1'

export const BLUE = '#B3EEEA'
export const LIGHT_GREEN = '#80C9AC'
export const ORANGE = '#F0A781'
export const PINK = '#FFC5AA'
export const YELLOW = '#FBDA65'

export const PINE_GREEN_1 = '#058C7C'
export const PINE_GREEN_2 = '#067366'

export const ERROR = '#D77979'

export const HYPERLINK = '#298BAD'
export const OVERLAY = '#434343'
