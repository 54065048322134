type Crumb = { label: string; path: string }
type Entry = { label: string; path: string; crumbs: Crumb[] }

export function homepage(): Entry {
    const crumb = {
        label: 'Accueil',
        path: '/'
    }
    return { ...crumb, crumbs: [crumb] }
}

export function aboutPage({ slug, title }: { slug: string; title: string }): Entry {
    const crumb = {
        label: title,
        path: `/a-propos/${slug}`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function account(): Entry {
    const crumb = {
        label: 'Mon compte',
        path: `/mon-compte`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function articles(params?: {
    environmentalPillar?: string
    searchableTag?: string
    socialPillar?: string
}): Entry {
    let path = '/guide'

    if (params && Object.keys(params).length > 0) {
        path += `?${new URLSearchParams(params).toString()}`
    }

    const crumb = {
        label: 'Guides et actus',
        path
    }

    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function article({ slug, title }: { slug: string; title: string }): Entry {
    const crumb = {
        label: title,
        path: `/guide/${slug}`
    }
    return {
        ...crumb,
        crumbs: [...articles().crumbs, crumb]
    }
}

export function brands(params?: {
    environmentalPillar?: string
    searchableTag?: string
    socialPillar?: string
}): Entry {
    let path = '/les-marques'

    if (params && Object.keys(params).length > 0) {
        path += `?${new URLSearchParams(params).toString()}`
    }

    const crumb = {
        label: 'Les marques',
        path
    }

    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function brand({ slug, name }: { slug: string; name: string }): Entry {
    const crumb = {
        label: name,
        path: `/les-marques/${slug}`
    }
    return {
        ...crumb,
        crumbs: [...brands().crumbs, crumb]
    }
}

export function cart(): Entry {
    const crumb = {
        label: 'Panier',
        path: '/panier'
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function faq(): Entry {
    const crumb = {
        label: 'FAQ',
        path: `/faq`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function favorites(): Entry {
    const crumb = {
        label: 'Mes favoris',
        path: `/mon-compte/mes-favoris`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function giftIdeas(params?: { [name: string]: string }): Entry {
    let path = `/idees-cadeaux`

    if (params && Object.keys(params).length > 0) {
        path += `?${new URLSearchParams(params).toString()}`
    }

    const crumb = {
        label: 'Idées cadeaux',
        path
    }

    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function login(): Entry {
    const crumb = {
        label: 'Connexion',
        path: '/login'
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function manifesto(): Entry {
    const crumb = {
        label: 'Manifeste',
        path: '/manifeste'
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function newsletter(): Entry {
    const crumb = {
        label: 'Infolettre',
        path: '/infolettre'
    }
    return {
        ...crumb,
        crumbs: []
    }
}

export function notFound(): Entry {
    const crumb = {
        label: '404',
        path: `/404`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function orders(): Entry {
    const crumb = {
        label: 'Mes commandes',
        path: `/mon-compte/mes-commandes`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function order({
    shopifyOrderNumber,
    vendor
}: {
    shopifyOrderNumber: number
    vendor: string
}): Entry {
    const crumb = {
        label: 'Mon Compte',
        path: `/account/order/${shopifyOrderNumber}-${vendor}`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function orderReturn({
    shopifyOrderNumber,
    vendor
}: {
    shopifyOrderNumber: number
    vendor: string
}): Entry {
    const crumb = {
        label: 'Mon Compte',
        path: `/account/order/${shopifyOrderNumber}-${vendor}/return`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function orderReturnSummary({
    shopifyOrderNumber,
    vendor
}: {
    shopifyOrderNumber: number
    vendor: string
}): Entry {
    const crumb = {
        label: 'Mon Compte',
        path: `/account/order/${shopifyOrderNumber}-${vendor}/return-summary`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function orderReview({
    shopifyOrderNumber,
    vendor
}: {
    shopifyOrderNumber: number
    vendor: string
}): Entry {
    const crumb = {
        label: 'Mon Compte',
        path: `/account/order/${shopifyOrderNumber}-${vendor}/review`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function products({
    params,
    title
}: {
    params?: { [name: string]: string }
    title?: string
}): Entry {
    let path = '/magasin/les-produits'

    if (params && Object.keys(params).length > 0) {
        path += `?${new URLSearchParams(params).toString()}`
    }

    const crumb = {
        label: title ?? 'Nos produits',
        path
    }

    return {
        ...crumb,
        crumbs: [...store().crumbs, crumb]
    }
}

export function product({
    handle,
    id,
    title,
    category
}: {
    handle: string
    id: number
    title: string
    category?: { slug: string; name: string }
}): Entry {
    const crumb = {
        label: title,
        path: `/magasin/${handle}-${id}`
    }
    const params: { [name: string]: string } = {}
    if (category) {
        params.category = category.slug
    }

    return {
        ...crumb,
        crumbs: [...products({ params, title: category?.name }).crumbs, crumb]
    }
}

export function profile(): Entry {
    const crumb = {
        label: 'Mon profil',
        path: `/mon-compte/mon-profil`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function reviews(): Entry {
    const crumb = {
        label: 'Mes avis',
        path: `/mon-compte/mes-avis`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function review({ variantId }: { variantId: number }): Entry {
    const crumb = {
        label: 'Mon Compte',
        path: `/account/reviews/${variantId}`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function store(): Entry {
    const crumb = {
        label: 'Magasin',
        path: `/magasin`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function tag({ name, slug }: { name: string; slug: string }): Entry {
    const crumb = {
        label: name,
        path: `/tags/${slug}`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}

export function userReview(): Entry {
    const crumb = {
        label: 'Mon Compte',
        path: `/account/reviews/`
    }
    return {
        ...crumb,
        crumbs: [...homepage().crumbs, crumb]
    }
}
