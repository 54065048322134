import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as blob from '../Blob'

const SYMBOL_ID = 'blob-6-def'

FullBlob6.filename = SYMBOL_ID
export function FullBlob6(props: blob.SpecificIconProps): React.ReactElement {
    return (
        <blob.Blob {...props} viewBox="0 0 97 90" name="blob-6">
            <defs>
                <path
                    id={SYMBOL_ID}
                    d="m81.082 14.592c11.947 11.909 19.147 30.589 14.483 45.027-4.5823 14.356-20.948 24.471-36.085 28.386-15.138 3.997-29.13 1.713-39.931-4.4047-10.883-6.1178-18.656-16.232-19.475-26.102-0.81826-9.87 5.2369-19.577 12.356-30.344 7.037-10.849 15.22-22.758 27.575-26.102 12.438-3.4259 29.13 1.6314 41.077 13.541z"
                />
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </blob.Blob>
    )
}

export const Blob6 = styled(function Blob6(
    props: blob.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <blob.Blob {...props} viewBox="0 0 97 90" name="blob-6">
            <use href={href} />
        </blob.Blob>
    )
})``
