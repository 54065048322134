import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-trash-def'

FullTrash.filename = SYMBOL_ID
export function FullTrash(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="trash">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M9.54677 3.29847C9.73788 3.10736 9.99708 3 10.2673 3H14.3054C14.5757 3 14.8349 3.10736 15.026 3.29847C15.2171 3.48958 15.3245 3.74878 15.3245 4.01905V5.03809H9.2483V4.01905C9.2483 3.74878 9.35566 3.48958 9.54677 3.29847ZM7.2483 5.03809V4.01905C7.2483 3.21835 7.56638 2.45044 8.13256 1.88426C8.69874 1.31808 9.46664 1 10.2673 1H14.3054C15.1061 1 15.874 1.31808 16.4402 1.88426C17.0064 2.45044 17.3245 3.21835 17.3245 4.01905V5.03809H21.3716V7.03809H20.3531V20.1714C20.3531 20.9721 20.035 21.74 19.4688 22.3062C18.9026 22.8724 18.1347 23.1905 17.334 23.1905H7.23877C6.43807 23.1905 5.67017 22.8724 5.10399 22.3062C4.5378 21.74 4.21973 20.9721 4.21973 20.1714V7.03809H3.2002V5.03809H5.21924H7.2483ZM8.2483 7.0381H16.3245H18.3531V20.1714C18.3531 20.4417 18.2457 20.7009 18.0546 20.892C17.8635 21.0831 17.6043 21.1905 17.334 21.1905H7.23877C6.96851 21.1905 6.70931 21.0831 6.5182 20.892C6.32709 20.7009 6.21973 20.4417 6.21973 20.1714V7.0381H8.2483ZM10.2666 10.0859C10.8189 10.0859 11.2666 10.5337 11.2666 11.0859V17.1431C11.2666 17.6954 10.8189 18.1431 10.2666 18.1431C9.71432 18.1431 9.2666 17.6954 9.2666 17.1431V11.0859C9.2666 10.5337 9.71432 10.0859 10.2666 10.0859ZM15.3047 11.0859C15.3047 10.5337 14.857 10.0859 14.3047 10.0859C13.7524 10.0859 13.3047 10.5337 13.3047 11.0859V17.1431C13.3047 17.6954 13.7524 18.1431 14.3047 18.1431C14.857 18.1431 15.3047 17.6954 15.3047 17.1431V11.0859Z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const Trash = styled(function Trash(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="trash">
            <use href={href} />
        </icon.Icon>
    )
})``
