import * as React from 'react'
import styled from 'styled-components'

import { BlobBackground, BLOB_COLORS, BLOB_VARIANTS } from '../components/BlobBackground'
import * as S from '../styles'

export type MomentLinkProps = React.HTMLAttributes<HTMLDivElement> & {
    blob: {
        color: keyof typeof BLOB_COLORS
        variant: keyof typeof BLOB_VARIANTS
    }
    illustration: React.ReactNode
    title: string
}

function MomentLinkComponent({
    blob,
    illustration,
    title,
    ...rest
}: MomentLinkProps): React.ReactElement {
    return (
        <S.momentLink.Link {...rest}>
            <S.momentLink.Illustration>{illustration}</S.momentLink.Illustration>
            <S.momentLink.Title>{title}</S.momentLink.Title>
            <BlobBackground color={blob.color} variant={blob.variant} />
        </S.momentLink.Link>
    )
}

export const MomentLink = styled(MomentLinkComponent)``
