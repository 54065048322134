export function scrollToVisibility(
    container: HTMLElement | null,
    target: HTMLElement | null
): void {
    if (!container || !target) return

    const containerHeight = container.clientHeight
    const containerScrolled = container.scrollTop
    const targetPos = target.offsetTop
    const targetHeight = target.clientHeight

    if (targetPos < containerScrolled) {
        // target over scroll window, must scroll up to see it
        const y = targetPos
        container.scroll(0, y)
    } else if (targetPos + targetHeight > containerScrolled + containerHeight) {
        // target under scroll window, must scroll down to see it
        const y = targetPos + targetHeight - containerHeight
        container.scroll(0, y)
    }
}
