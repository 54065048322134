import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-chevron-right-def'

FullChevronRight.filename = SYMBOL_ID
export function FullChevronRight(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="chevron-right">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M9.707 5.293L16.414 12l-6.707 6.707-1.414-1.414L13.586 12 8.293 6.707l1.414-1.414z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const ChevronRight = styled(function ChevronRight(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="chevron-right">
            <use href={href} />
        </icon.Icon>
    )
})``
