import * as React from 'react'

const svgDirContext = React.createContext<string | undefined>(undefined)

export const SvgDirProvider = svgDirContext.Provider

export function useSvgHref(filename: string, id = filename): string {
    const dir = React.useContext(svgDirContext)

    if (dir === undefined) {
        return id
    }

    return `${dir}${filename}.svg#${id}`
}
