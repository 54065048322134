import * as React from 'react'

import * as S from '../../../styles'

import * as autocomplete from '../../../behaviours/autocomplete'

export const scrollContext = React.createContext<React.RefObject<HTMLElement> | null>(
    null
)

export function useScrollContext(): React.RefObject<HTMLElement> | null {
    return React.useContext(scrollContext)
}

type SearchDrawerProps = {
    children?: React.ReactNode
    close: () => void
    height?: number
    isOpen: boolean
    id: string
    register: autocomplete.AutocompleteListApi['register']
    scrollRef: React.RefObject<HTMLUListElement>
}

export function SearchDrawer({
    children,
    isOpen,
    id,
    register,
    scrollRef
}: SearchDrawerProps): React.ReactElement<SearchDrawerProps> {
    return (
        <>
            <S.form.autocomplete.Drawer isOpen={isOpen}>
                <S.form.autocomplete.ScrollList
                    aria-labelledby={id}
                    ref={scrollRef}
                    role="listbox"
                    tabIndex={-1}
                >
                    <scrollContext.Provider value={scrollRef}>
                        <autocomplete.AutocompleteContextProvider value={register}>
                            {children}
                        </autocomplete.AutocompleteContextProvider>
                    </scrollContext.Provider>
                </S.form.autocomplete.ScrollList>
            </S.form.autocomplete.Drawer>
        </>
    )
}
