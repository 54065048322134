import { RequestOptions, normalizeHeaders, HttpRequest, HttpResponse } from './requester'
import 'url-search-params-polyfill'
import { setCookie, getCookie, removeCookie } from '@bob/cookie-helper'
import { VERCEL_URL } from '@bob/utils'

type HttpMethod = 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT'

type OmitBetter<T, K extends keyof T> = T extends any
    ? Pick<T, Exclude<keyof T, K>>
    : never

type ClientOptions<BODY, URL_PARAMS extends string> = OmitBetter<
    RequestOptions,
    'method' | 'body'
> & {
    version?: string
    getParams?: Record<string, string> | string | URLSearchParams
    urlParams?: Record<URL_PARAMS, string>
    body?: BODY
    withToken?: boolean
}

export type ClientConfig = {
    rootUrl: string
    endpoints: Record<string, Endpoint>
    options?: Partial<ClientOptions<any, string>>
    request: HttpRequest
}

export type Endpoint = {
    options?: Partial<ClientOptions<any, string>>
    methods: (HttpMethod | { method: HttpMethod; options?: ClientOptions<any, string> })[]
    url: string | ((urlParams: Record<string, string>) => string)
}

export type BaseMethod = (
    url: string | ((urlParams: Record<string, string>) => string),
    options?: ClientOptions<any, string>
) => Promise<HttpResponse>

export type ClientMethod<
    REQUEST_BODY = any,
    RESPONSE_BODY = unknown,
    URL_PARAMS extends string = string
> = (
    options?: Partial<ClientOptions<REQUEST_BODY, URL_PARAMS>>
) => Promise<HttpResponse<RESPONSE_BODY>>

type Method = BaseMethod & {
    [s: string]: ClientMethod
}

export type OAuth2Grants = {
    shopify_order_number: {
        email: string
        shopify_order_number: number
    }
    refresh_token: {
        refresh_token: string
    }
}

export type OAuth2Response = {
    access_token: string
    expires_in: number
    refresh_token: string
    scope: string
    token_type: string
}

export type OAuth2 = <GRANT extends keyof OAuth2Grants>(
    grant: GRANT,
    params: OAuth2Grants[GRANT],
    options?: {
        getCookie?: typeof getCookie
        setCookie?: typeof setCookie
        removeCookie?: typeof removeCookie
    }
) => Promise<HttpResponse<OAuth2Response>>

export type Client = {
    delete: Method
    get: Method
    patch: Method
    post: Method
    put: Method
    oauth2: OAuth2
    endpoints: Record<string, Endpoint>
}

export function client({
    rootUrl,
    endpoints,
    options: rootOptions = {},
    request
}: ClientConfig): Client {
    const methods: any = {
        delete: httpMethod('DELETE'),
        get: httpMethod('GET'),
        patch: httpMethod('PATCH'),
        post: httpMethod('POST'),
        put: httpMethod('PUT'),
        oauth2: async <GRANT extends keyof OAuth2Grants>(
            grant: GRANT,
            params: OAuth2Grants[GRANT],
            options: {
                getCookie?: typeof getCookie
                setCookie?: typeof setCookie
                removeCookie?: typeof removeCookie
            } = {}
        ) => {
            const response = await request<OAuth2Response>(
                VERCEL_URL === undefined ? '/api/oauth2' : VERCEL_URL + '/api/oauth2',
                {
                    method: 'POST',
                    type: 'json',
                    body: {
                        grant_type: grant,
                        ...params
                    },
                    setCookie: options.setCookie,
                    getCookie: options.getCookie,
                    removeCookie: options.removeCookie
                }
            )

            return response.caseOf({
                left: () => {
                    ;(options?.removeCookie ?? removeCookie)(
                        process.env.NEXT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME ?? ''
                    )
                    ;(options?.removeCookie ?? removeCookie)(
                        process.env.NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_NAME ?? ''
                    )
                    return response
                },
                right: success => {
                    ;(options?.setCookie ?? setCookie)(
                        process.env.NEXT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME ?? '',
                        success.body.access_token
                    )
                    ;(options?.setCookie ?? setCookie)(
                        process.env.NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_NAME ?? '',
                        success.body.refresh_token
                    )
                    return response
                }
            })
        }
    }

    for (const [endpointName, definition] of Object.entries(endpoints)) {
        const endpointOptions = definition.options
        for (const entry of definition.methods) {
            const method =
                typeof entry === 'string'
                    ? entry.toLowerCase()
                    : entry.method.toLowerCase()
            const methodOptions = typeof entry === 'string' ? undefined : entry.options
            if (!(method in methods)) {
                throw Error(`unknown HTTP method ${method}`)
            }
            const httpMethod = methods[method]
            httpMethod[endpointName] = (
                options: Partial<ClientOptions<any, string>> = {}
            ): Promise<HttpResponse> => {
                return httpMethod(
                    definition.url,
                    deepMergeOptions(
                        endpointOptions || {},
                        methodOptions || {},
                        options || {}
                    )
                )
            }
        }
    }

    return {
        ...methods,
        endpoints
    }

    function httpMethod(method: HttpMethod) {
        return (
            url: string | ((urlParams?: Record<string, string>) => string),
            methodOptions: Partial<ClientOptions<any, string>> = {}
        ): Promise<HttpResponse> => {
            const options = deepMergeOptions(rootOptions, methodOptions)
            const headers = normalizeHeaders(options.headers || {})
            if (options.version) {
                headers['Ulule-Version'] = options.version
            }
            const urlMaker = typeof url === 'string' ? () => url : url
            return request(getFullUrl(urlMaker, options.urlParams, options.getParams), {
                ...options,
                method,
                headers
            } as RequestOptions)
        }
    }

    function getFullUrl(
        urlMaker: (urlParams?: Record<string, string>) => string,
        urlParams?: Record<string, string>,
        getParams?: Record<string, string> | string | URLSearchParams
    ): string {
        const url = rootUrl + urlMaker(urlParams)
        if (getParams !== undefined) {
            const urlSearchParams = new URLSearchParams(getParams)
            return url + '?' + urlSearchParams.toString()
        }
        return url
    }
}

// Transform Tuple of types ([A, B, ...]) to an Union of those types ( A|B|... ).
type TupleToUnion<T extends any[]> = T[number]

// Transform an Union of types ( A|B|... ) to an Intersection of those types ( A & B & ...)
// Uses the fact that functions are contravariant: This means that a wider type for a given function
// will have narrower types for parameters. So if i have a function where a parameters is an union of types,
// the wider type for my function will have an intersection of those types as parameters.
// first with `U extends any ? (k: U) => void : never` we transform our Union to a function taking
// the union as first parameter (with the help of an always true type conditional).
// Then, we use another type conditional to widen this type (using the condition `extends (k: infer I) => void`)
// to get in `I` the intersection of the types that where in the union `U`.
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
    k: infer I
) => void
    ? I
    : never

function deepMergeOptions<
    A extends Record<string, any>,
    B extends Record<string, any>,
    REST extends any[]
>(
    object1: A,
    object2: B,
    ...objects: REST
): A & B & UnionToIntersection<TupleToUnion<REST>> {
    let merged = baseDeepMergeOptions(object1, object2)
    while (objects.length !== 0) {
        merged = baseDeepMergeOptions(merged, objects.shift())
    }
    return merged as any
}

function baseDeepMergeOptions<
    A extends Record<string, any>,
    B extends Record<string, any>
>(object1: A, object2: B): A & B {
    const merged: any = {}
    for (const [key, value1] of Object.entries(object1)) {
        if (!(key in object2)) {
            merged[key] = value1
            continue
        }

        const value2 = object2[key]
        if (typeof value1 === 'object' && typeof value2 === 'object') {
            if (key === 'body') {
                // don't merge body, override
                merged[key] = value2
            } else {
                merged[key] = baseDeepMergeOptions(value1, value2)
            }
        } else if (value2 !== undefined || value2 !== null) {
            merged[key] = value2
        } else {
            merged[key] = value1
        }
    }

    for (const [key, value2] of Object.entries(object2)) {
        if (!(key in object1)) {
            merged[key] = value2
        }
    }

    return merged
}
