export function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function slugify(str: string, separator = ' '): string {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/[^a-z0-9 _-]/g, '')
        .trim()
        .replace(/[\s_]/g, separator)
}

const CSS_PROPERTY_WHITELIST = [
    'font-style',
    'font-variant',
    'font-weight',
    'text-decoration',
    'text-align'
]

export function sanitizeDescriptionStyle(description: string) {
    return description.replace(/style=['"](.*?)['"]/g, (_, style: string) => {
        return `style="${style
            .split(';')
            .filter(rule =>
                CSS_PROPERTY_WHITELIST.some(property =>
                    rule.toLowerCase().startsWith(property)
                )
            )
            .join(';')}"`
    })
}
