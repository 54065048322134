import Link from 'next/link'
import { styles as S } from '@bob/design-system'

type HyperlinkProps = {
    uri: string
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

export function Hyperlink({ children, uri }: HyperlinkProps): React.ReactElement {
    const url = new URL(uri)

    const isJavascript = url.protocol.includes('javascript')

    const [first, second] = url.hostname.split('.')
    const isRootDomain =
        first === 'bienoubien' || first === 'www' ? second === 'bienoubien' : false

    if (isRootDomain) {
        const href = url.pathname + url.search + url.hash

        return (
            <Link prefetch={false} href={href} passHref>
                <S.wysiwyg.Hyperlink>{children}</S.wysiwyg.Hyperlink>
            </Link>
        )
    }

    // Required to launch Axeptio widget
    if (isJavascript) {
        return <S.wysiwyg.Hyperlink href={uri}>{children}</S.wysiwyg.Hyperlink>
    }

    return (
        <S.wysiwyg.Hyperlink href={uri} rel="noopener noreferer external" target="_blank">
            {children}
        </S.wysiwyg.Hyperlink>
    )
}
