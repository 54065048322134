import styled from 'styled-components'

import { Link } from './Link'
import * as S from '../styles'

export type BannerProps = {
    href?: string
    message: string
    vendor?: string
}

function BannerComponent({ href, message, vendor }: BannerProps): React.ReactElement {
    return (
        <S.banner.Banner>
            <S.banner.Text>
                {message}{' '}
                {href && vendor && (
                    <Link prefetch={false} href={href} passHref>
                        {vendor}
                    </Link>
                )}
            </S.banner.Text>
        </S.banner.Banner>
    )
}

export const Banner = styled(BannerComponent)``
