import * as React from 'react'
import styled from 'styled-components'

import { SizedSvg } from '../SizedSvg'

export type BlobProps = React.SVGAttributes<SVGElement> & {
    children: React.ReactNode
    className?: string
    name: string
    onClick?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
    /** @default 24 */
    size?: number | string
    title?: string
    /** @default '0 0 110 110' */
    viewBox?: string
}

export type SpecificIconProps = Omit<BlobProps, 'children' | 'name'> & {
    children?: React.ReactNode
}

/** Base SVG Icon Component */
function BlobComponent({
    children,
    className,
    name,
    onClick,
    size = 24,
    title,
    viewBox,
    ...svgProps
}: BlobProps): React.ReactElement<BlobProps> {
    return (
        <SizedSvg
            aria-labelledby={title && 'title'}
            className={className}
            clipRule="evenodd"
            fillRule="evenodd"
            height={size}
            id={`icon-${name}`}
            onClick={onClick}
            preserveAspectRatio="xMidYMid meet"
            $size={size}
            viewBox={viewBox}
            width={size}
            xmlns="http://www.w3.org/2000/svg"
            {...svgProps}
        >
            {title && <title id="title">{name}</title>}
            {children}
        </SizedSvg>
    )
}

export const Blob = styled(BlobComponent)<BlobProps>``
