import * as React from 'react'

type GeolocationInterface = {
    geolocatedCountry: string | undefined
}

export const geolocationContext = React.createContext<GeolocationInterface | undefined>(
    undefined
)

export function useGeolocationContext(): GeolocationInterface {
    const geolocation = React.useContext(geolocationContext)

    if (geolocation === undefined) {
        throw Error(`can't useGeolocationContext outside <GeolocationProvider />`)
    }

    return geolocation
}
