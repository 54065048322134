import styled from 'styled-components'

import * as S from '../styles'

import { useLinkWrapper } from '../hooks'

export type LinkProps = {
    passHref?: boolean
    prefetch?: boolean
    replace?: boolean
    scroll?: boolean
    shallow?: boolean
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

function LinkComponent({
    children,
    href,
    passHref = false,
    replace = false,
    scroll = true,
    shallow = false,
    ...baseAnchorProps
}: LinkProps): React.ReactElement {
    const LinkWrapper = useLinkWrapper()

    return (
        <LinkWrapper
            href={href}
            passHref={passHref}
            replace={replace}
            scroll={scroll}
            shallow={shallow}
        >
            <S.link.Base {...baseAnchorProps}>{children}</S.link.Base>
        </LinkWrapper>
    )
}

export const Link = styled(LinkComponent)``
