export const HEADER_SHADOW_HEIGHT = '14px'

export const HEADER_DESKTOP_HEIGHT = '95px'
export const HEADER_DESKTOP_INTERNAL_HEIGHT = '81px' // HEADER_DESKTOP_HEIGHT - HEADER_SHADOW_HEIGHT

export const HEADER_MOBILE_HEIGHT = '85px'
export const HEADER_MOBILE_INTERNAL_HEIGHT = '71px' // HEADER_MOBILE_HEIGHT - HEADER_SHADOW_HEIGHT

export const MAIN_DESKTOP_CONTAINER_WIDTH = '1360px'
export const MAIN_MOBILE_CONTAINER_WIDTH = '1320px'
