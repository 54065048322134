import * as React from 'react'

import styled from 'styled-components'

import * as S from '../../../styles'
import * as icons from '../../../svg/icons'
import { Input } from '../Input'

export type SearchInputProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'value'
> & {
    value?: string
}

const SearchInputComponent = React.forwardRef<HTMLInputElement, SearchInputProps>(
    function SearchInputComponent({ ...inputProps }: SearchInputProps, ref) {
        const inputRef = React.useRef<HTMLInputElement>(null)

        const Icon =
            inputRef.current?.value === '' || inputRef.current === null
                ? icons.Search
                : icons.Cross

        function handleClear(): void {
            const input = inputRef.current
            const valueDescriptor = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
            )
            const nativeValueSetter = valueDescriptor?.set
            if (input === null || nativeValueSetter === undefined) {
                return
            }

            const event = document.createEvent('Event')
            event.initEvent('input', true, true)
            nativeValueSetter.call(input, '')
            input.dispatchEvent(event)
        }

        const setRef = React.useCallback(function setRef(node: HTMLInputElement) {
            const anyInputRef = inputRef as any
            anyInputRef.current = node
            if (ref) {
                const anyRef = ref as any
                if (typeof ref === 'function') {
                    ref(node)
                } else {
                    anyRef.current = node
                }
            }
        }, [])

        return (
            <Input
                {...inputProps}
                ref={setRef}
                type="search"
                small
                button={{
                    onClick: handleClear,
                    children: <Icon onClick={() => inputRef.current?.focus()} />
                }}
            />
        )
    }
)

export const SearchInput = styled(SearchInputComponent)<SearchInputProps>`
    ${S.form.input.Button} {
        &:hover,
        &:focus {
            background-color: transparent;
        }
    }
`
