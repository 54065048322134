import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'
import { Modal, ModalProps } from './Modal'
import { Button } from './Button'

export type ConfirmationModalProps = ModalProps & {
    primary: { handler: () => void; label: React.ReactNode }
    secondary: { handler: () => void; label: React.ReactNode }
}

function ConfirmationModalComponent({
    children,
    primary,
    secondary,
    ...modalProps
}: ConfirmationModalProps): React.ReactElement<ConfirmationModalProps> {
    return (
        <Modal {...modalProps}>
            {children}
            <S.modal.Controls>
                <Button small onClick={primary.handler}>
                    {primary.label}
                </Button>
                <Button small onClick={secondary.handler}>
                    {secondary.label}
                </Button>
            </S.modal.Controls>
        </Modal>
    )
}

export const ConfirmationModal = styled(ConfirmationModalComponent)`
    ${S.modal.ModalWrapper} {
        position: static;
        width: auto;
        height: auto;
        max-width: 500px;
    }

    ${S.modal.ModalBody} {
        position: static;
        padding: 0;
    }
`
