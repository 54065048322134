import * as React from 'react'
import styled, { css } from 'styled-components'

import {
    Cart,
    CartItemList,
    EmptyCart,
    ReadyCart,
    useShippingRates,
    useCartItemCount
} from '@bob/cart'
import * as countries from '@bob/countries'
import * as ds from '@bob/design-system'
import { styles as S } from '@bob/design-system'
import { useGeolocationContext } from '@bob/geolocation'
import * as money from '@bob/money'
import * as shopify from '@bob/shopify-services'
import * as url from '@bob/url'

type CartPopupProps = {
    trigger: React.RefObject<HTMLElement>
    cart: Cart
    className?: string
    isOpen: boolean
    setAsOpen: (isOpen: boolean) => void
}

function CartPopupComponent({
    cart,
    trigger,
    className,
    isOpen = false,
    setAsOpen
}: CartPopupProps): React.ReactElement<CartPopupProps> {
    const itemCount = useCartItemCount()

    const popupFooter =
        cart.ready && !cart.empty ? (
            <>
                <FooterTotalSection>
                    <S.copy.TextArticle>Total panier</S.copy.TextArticle>
                    <S.heading.TitleCardArticle>
                        {money.format(cart.total())}
                    </S.heading.TitleCardArticle>
                </FooterTotalSection>
                <a href={url.cart().path}>
                    <ds.Button
                        autoSized
                        color="green"
                        onClick={() => {
                            setAsOpen(false)
                        }}
                    >
                        Voir mon panier
                    </ds.Button>
                </a>
            </>
        ) : undefined

    return (
        <ds.Popup
            className={className}
            trigger={trigger}
            description={
                cart.ready
                    ? cart.empty
                        ? ''
                        : getItemCountLabel(itemCount)
                    : 'Chargement…'
            }
            footer={popupFooter}
            onClose={() => {
                setAsOpen(false)
            }}
            open={isOpen}
            title={cart.ready && cart.empty ? 'Panier vide' : 'Mon Panier'}
        >
            <>
                {!cart.ready && <h3>Chargement…</h3>}
                {cart.ready && <PopupContent cart={cart} />}
            </>
        </ds.Popup>
    )
}

type PopupContentProps = {
    cart: EmptyCart | ReadyCart
}

function PopupContent({ cart }: PopupContentProps): React.ReactElement {
    const { geolocatedCountry } = useGeolocationContext()

    const cartItems = !cart.empty
        ? cart.data.vendors.flatMap(vendor => {
              return vendor.lineItems.map(item => ({
                  shopifyVariantId: shopify.base.shopifyNumericId(
                      item.variant.id,
                      'ProductVariant'
                  ),
                  quantity: item.quantity
              }))
          })
        : []

    const vendors = !cart.empty
        ? cart.data.vendors.map(vendor => vendor.brand.identifier)
        : []

    const { freeShippingThresholds } = useShippingRates(
        geolocatedCountry,
        cartItems,
        vendors
    )

    return (
        <CartItemList
            cart={cart}
            freeShippingThresholds={freeShippingThresholds}
            inPopup
            shippingCountry={
                geolocatedCountry && countries.frenchCountryList[geolocatedCountry]
            }
        />
    )
}

function getItemCountLabel(itemCount: number): string {
    if (itemCount === 0) {
        return 'Aucun article'
    } else if (itemCount === 1) {
        return '1 article'
    } else {
        return `${itemCount} articles`
    }
}

export const CartPopup = styled(CartPopupComponent)`
    height: calc(100vh - 110px);
`

const FooterTotalSection = styled.section`
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
`
