import * as React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { Logo } from '@bob/design-system'

import * as url from '@bob/url'

export type LogoProps = React.HTMLAttributes<HTMLDivElement>

function BrandComponent({ ...rest }: LogoProps): React.ReactElement {
    return (
        <Base {...rest}>
            <Link prefetch={false} href={url.homepage().path}>
                <a>
                    <Logo />
                </a>
            </Link>
        </Base>
    )
}

export const Brand = styled(BrandComponent)``

export const Base = styled.div`
    flex-shrink: 0;
`
