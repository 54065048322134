import * as React from 'react'
import styled from 'styled-components'

import { getDeliveryWarningMessage } from '@bob/utils/shipping'
import { CircularButton } from './CircularButton'
import { Picture } from './Picture'
import * as icons from '../svg/icons'
import * as S from '../styles'
import { useLinkWrapper } from '../hooks/useLinkWrapper'
import { Toast } from './Toast'
import * as color from '../constants/colors'

export type CartItemProps = {
    disabled?: boolean
    deliveryExtra: string | null
    className?: string
    href: string
    image: string
    onDecrease: () => Promise<void>
    onIncrease: () => Promise<void>
    onRemove: () => Promise<void>
    onSetAside: () => Promise<void>
    price: string
    productionDays: number | null
    discountedPrice?: string
    quantity: number
    stock: number
    subtitle: string
    title: string
}

function CartItemComponent({
    disabled = false,
    deliveryExtra,
    className,
    href,
    image,
    onDecrease,
    onIncrease,
    onRemove,
    onSetAside,
    price,
    productionDays,
    discountedPrice,
    quantity,
    stock,
    subtitle,
    title
}: CartItemProps): React.ReactElement {
    const LinkWrapper = useLinkWrapper()

    const deliveryWarningDeliveryMessage = getDeliveryWarningMessage(
        deliveryExtra,
        productionDays
    )

    return (
        <S.cartItem.Wrapper className={className}>
            <LinkWrapper href={href}>
                <a>
                    <SquarePicture src={image} alt={title} />
                </a>
            </LinkWrapper>
            <S.cartItem.Body>
                <LinkWrapper href={href}>
                    <a>
                        <S.cartItem.Title>{title}</S.cartItem.Title>
                        <S.cartItem.Subtitle>{subtitle}</S.cartItem.Subtitle>
                    </a>
                </LinkWrapper>
                {discountedPrice !== undefined ? (
                    <>
                        <S.cartItem.DiscountedPrice>{price}</S.cartItem.DiscountedPrice>
                        <S.cartItem.Price>{discountedPrice}</S.cartItem.Price>
                    </>
                ) : (
                    <S.cartItem.Price>{price}</S.cartItem.Price>
                )}
            </S.cartItem.Body>
            {deliveryWarningDeliveryMessage && (
                <Toast status="info" noShadow>
                    {deliveryWarningDeliveryMessage}
                </Toast>
            )}
            <S.cartItem.Footer>
                <S.cartItem.Controls>
                    <CircularButton
                        outlined
                        small
                        aria-label="déduire"
                        disabled={disabled}
                        onClick={onDecrease}
                    >
                        -
                    </CircularButton>
                    <S.cartItem.Quantity>{quantity}</S.cartItem.Quantity>
                    <CircularButton
                        outlined
                        small
                        aria-label="ajouter"
                        disabled={disabled || stock === quantity}
                        onClick={onIncrease}
                    >
                        +
                    </CircularButton>
                    <CircularButton
                        small
                        aria-label="supprimer"
                        disabled={disabled}
                        onClick={onRemove}
                    >
                        <icons.Trash size={32} />
                    </CircularButton>
                </S.cartItem.Controls>
                {/*<S.cartItem.SaveForLater>
                    <icons.Clock size={24} />
                    <Link prefetch={false} href="#" onClick={busyDecorate(onSetAside)}>
                        <S.copy.TextHyperlink>
                            Pas sûr ? Mettre de coté
                        </S.copy.TextHyperlink>
                    </Link>
                </S.cartItem.SaveForLater>*/}
            </S.cartItem.Footer>
        </S.cartItem.Wrapper>
    )
}

const SquarePicture = styled(Picture)`
    border-radius: 10px;
    height: 120px;
    min-width: 120px;
    width: 120px;
`

export const CartItem = styled(CartItemComponent)`
    ${Toast} {
        background-color: ${color.SAND_1};
        margin: 10px 0 10px;
        width: 100%;
    }
`

type CartItemSkeletonProps = {
    className?: string
}

function CartItemSkeletonComponent({ className }: CartItemSkeletonProps) {
    return (
        <S.cartItem.Wrapper className={className}>
            <S.skeleton.Rectangle height={120} />
            <S.cartItem.Body>
                <S.skeleton.Text size="medium" width={30} />
                <S.skeleton.Text size="small" width={10} />
                <S.skeleton.Text size="small" width={20} />
            </S.cartItem.Body>

            <S.cartItem.Footer>
                <S.cartItem.Controls>
                    <S.skeleton.Circle radius={34} />
                    <S.skeleton.Text width={20} />
                    <S.skeleton.Circle radius={34} />
                    <S.skeleton.Circle radius={34} />
                </S.cartItem.Controls>
            </S.cartItem.Footer>
        </S.cartItem.Wrapper>
    )
}

export const CartItemSkeleton = styled(CartItemSkeletonComponent)`
    ${S.skeleton.Rectangle} {
        width: 120px;
    }

    ${S.cartItem.Body} {
        flex-direction: column;
        ${S.skeleton.Text}:last-child {
            align-self: end;
        }
    }

    ${S.cartItem.Controls} {
        ${S.skeleton.Circle} {
            width: 34px;
        }
        ${S.skeleton.Text} {
            width: 20px;
            margin: 7px 7px;
        }
    }
`
