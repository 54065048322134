import styled from 'styled-components'
import { SizedSvg } from '../SizedSvg'

export type IllustrationProps = React.SVGAttributes<SVGElement> & {
    children: React.ReactNode
    className?: string
    name: string
    /** @default 125 */
    size?: number | string
    title?: string
    /** @default '0 0 125 125' */
    viewBox?: string
}

export type SpecificIllustrationProps = Omit<IllustrationProps, 'children' | 'name'> & {
    children?: React.ReactNode
}

/** Base SVG Illustration.stories.tsx Component */
function IllustrationComponent({
    children,
    className,
    color,
    clipRule = 'evenodd',
    fillRule = 'evenodd',
    name,
    size = 75,
    title,
    viewBox = '0 0 125 125'
}: IllustrationProps): React.ReactElement<IllustrationProps> {
    return (
        <SizedSvg
            aria-labelledby={title && 'title'}
            className={className}
            clipRule={clipRule}
            color={color}
            fillRule={fillRule}
            height={size}
            id={`illustration-${name}`}
            preserveAspectRatio="xMidYMid meet"
            viewBox={viewBox}
            width={size}
            $size={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            {title && <title id="title">{name}</title>}
            {children}
        </SizedSvg>
    )
}

export const Illustration = styled(IllustrationComponent)<IllustrationProps>``
