export const SECONDARY = 'sofia-pro'
export const PRIMARY = 'Recoleta Medium'

export const WEIGHT = {
    THIN: 300,
    BASE: 400,
    MEDIUM: 500,
    SEMI_BOLD: 600,
    BOLD: 700
}
