import styled, { css, keyframes } from 'styled-components'

import * as color from '../constants/colors'

type SkeletonProps = {
    tint?: 'dark' | 'light'
}

const sweepAnimation = keyframes`
    0% { background-position: -600px 0; }
    100% { background-position: 600px 0; }
`

export const skeletonAnimationRules = css<SkeletonProps>`
    animation: ${sweepAnimation} 1s infinite linear forwards;

    ${({ tint = 'dark' }) =>
        tint === 'light'
            ? css<SkeletonProps>`
                  background: ${color.SAND_2};
                  background-image: linear-gradient(
                      to right,
                      ${color.SAND_1},
                      ${color.SAND_2} 20%,
                      ${color.SAND_3} 40%,
                      ${color.SAND_1} 100%
                  );
              `
            : css<SkeletonProps>`
                  background: ${color.SAND_4};
                  background-image: linear-gradient(
                      to right,
                      ${color.SAND_4},
                      ${color.SAND_2} 20%,
                      ${color.SAND_3} 40%,
                      ${color.SAND_4} 100%
                  );
              `}

    background-repeat: no-repeat;
`

function getBackgroundColor(tint?: 'dark' | 'light'): string {
    return tint === 'light' ? color.SAND_2 : color.SAND_4
}

type CircleSkeletonProps = SkeletonProps & {
    radius?: number
}

export const Circle = styled.div<CircleSkeletonProps>`
    background-color: ${({ tint = 'dark' }) => getBackgroundColor(tint)};
    border-radius: 50%;

    height: ${({ radius = 32 }) => radius}px;
    width: ${({ radius = 32 }) => radius}px;

    ${skeletonAnimationRules}
`

type RectangleSkeletonProps = SkeletonProps & {
    height?: number
}

export const Rectangle = styled.div<RectangleSkeletonProps>`
    background-color: ${({ tint = 'dark' }) => getBackgroundColor(tint)};

    height: ${({ height = 32 }) => height}px;
    width: 100%;

    ${skeletonAnimationRules}
`

export const SquareCard = styled.div<SkeletonProps>`
    border-radius: 12px;
    background-color: ${({ tint = 'dark' }) => getBackgroundColor(tint)};
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;

    min-height: 270px;
    min-width: 270px;

    ${skeletonAnimationRules}
`

export const Tag = styled.div`
    background-color: ${({ tint = 'dark' }) => getBackgroundColor(tint)};
    border-radius: 12px;
    height: 20px;
    width: 32%;

    & + * {
        margin-top: 10px;
    }

    ${skeletonAnimationRules}
`

type TextSkeletonProps = SkeletonProps & {
    size?: 'small' | 'medium' | 'large'
    width?: number
}

export const Text = styled.div<TextSkeletonProps>`
    background-color: ${({ tint = 'dark' }) => getBackgroundColor(tint)};
    width: ${({ width = 100 }) => width}%;

    ${({ size = 'medium' }) => {
        if (size === 'large') {
            return css`
                height: 40px;
            `
        } else if (size === 'small') {
            return css`
                height: 10px;
            `
        }

        return css`
            height: 20px;
        `
    }}
    & + & {
        margin-top: 10px;
    }

    ${skeletonAnimationRules}
`
