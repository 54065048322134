import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-twitter-def'

FullTwitter.filename = SYMBOL_ID
export function FullTwitter(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="twitter">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M14.526 2.35a5.48 5.48 0 015.607 1.035 9.9 9.9 0 002.29-1.202 1 1 0 011.549 1.054 8.72 8.72 0 01-1.995 3.774c.015.162.023.324.023.486V7.5c0 6.1-2.937 10.575-7.155 12.82-4.194 2.233-9.554 2.208-14.33-.446a1 1 0 01.525-1.873 10.64 10.64 0 004.875-.968c-1.503-.927-2.585-2.029-3.338-3.216-1.003-1.585-1.382-3.264-1.45-4.771-.07-1.503.17-2.857.42-3.827a13.083 13.083 0 01.484-1.492c.015-.04.028-.07.038-.092l.011-.027.004-.009.001-.003c.001-.001.001-.002.915.404l-.914-.406a1 1 0 011.732-.168A9.66 9.66 0 0011 7.506a5.48 5.48 0 013.526-5.156zM3.405 6.044c-.184.801-.33 1.818-.28 2.91.056 1.243.364 2.565 1.142 3.792.773 1.222 2.05 2.412 4.14 3.34a1 1 0 01.155 1.742 12.64 12.64 0 01-3.602 1.688c3.17.808 6.347.422 8.945-.961C17.437 16.675 20 12.901 20 7.502c0-.216-.021-.432-.062-.644a1 1 0 01.28-.9c.223-.22.429-.454.618-.7-.227.083-.456.16-.688.23a1 1 0 01-1.043-.301A3.48 3.48 0 0013 7.517V8.53a1 1 0 01-.974 1 11.66 11.66 0 01-8.621-3.486z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const Twitter = styled(function Twitter(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="twitter">
            <use href={href} />
        </icon.Icon>
    )
})``
