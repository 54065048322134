// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
var SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_CLIENT_DSN || null;
var ENVIRONMENT = process.env.NODE_ENV === 'production' ? 'production' : 'development';

if (SENTRY_DSN) {
  Sentry.init({
    autoSessionTracking: false,
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    normalizeDepth: 10
  });
}