import * as React from 'react'
import styled from 'styled-components'

import * as ds from '@bob/design-system'

import { useNewsletterRegistration } from './useNewsletterRegistration'

const LOCAL_STORAGE_BOTTOM_KEY = 'bottomNewsletterStatus'

const NEXT_PUBLIC_KLAVIYO_NEWSLETTER_LIST_ID =
    process.env.NEXT_PUBLIC_KLAVIYO_NEWSLETTER_LIST_ID ?? ''

export type NewsletterFormProps = Omit<
    React.FormHTMLAttributes<HTMLFormElement>,
    'onSubmit'
> & {
    buttonOutlined?: boolean
    isWhiteButton?: boolean
}

function NewsletterFormComponent({
    children,
    buttonOutlined,
    isWhiteButton,
    ...formProps
}: NewsletterFormProps) {
    const [email, setEmail] = React.useState('')
    const [registrationState, register] = useNewsletterRegistration(
        NEXT_PUBLIC_KLAVIYO_NEWSLETTER_LIST_ID
    )
    const formRef = React.useRef<HTMLFormElement>(null)

    React.useEffect(() => {
        // firefox set the value of each text field on refresh, without change event.
        // this means that the `email` state is empty, so the button is disabled,
        // but there is a value inside the field.
        // To avoid this behaviour, we set state according to field value on mount
        if (formRef.current !== null) {
            formRef.current.querySelectorAll('input').forEach(input => {
                if (input.name === 'email') {
                    setEmail(input.value)
                }
            })
        }
    }, [])

    React.useEffect(() => {
        if (registrationState.status === 'success') {
            setEmail('')
        }
    }, [registrationState])

    return (
        <form
            {...formProps}
            ref={formRef}
            onSubmit={event => {
                register(email)

                const date = new Date()
                const localStorageExpirationTime = 7 * 24 * 60 * 60 * 1000 * 12 //12 weeks
                localStorage.setItem(
                    LOCAL_STORAGE_BOTTOM_KEY,
                    `${date.setTime(date.getTime() + localStorageExpirationTime)}`
                )

                event.preventDefault()
            }}
        >
            {children}
            <ds.Input
                small
                placeholder="Mon email"
                value={email}
                onChange={event => setEmail(event.target.value)}
            />
            <ds.Button
                small
                outlined={buttonOutlined}
                color={isWhiteButton ? 'white' : 'grey'}
                loading={registrationState.status === 'pending'}
                disabled={email.length === 0}
            >
                Je m&apos;inscris
            </ds.Button>
        </form>
    )
}

export const NewsletterForm = styled(NewsletterFormComponent)``
