import styled, { css } from 'styled-components'

import * as ds from '@bob/design-system'
import { styles as S } from '@bob/design-system'

import * as Category from '../components/Categories'
import { Overlay } from '../components/Overlay'

type CategoriesDrawerProps = {
    categories: React.ReactNode
    isOpen: boolean
    moments?: {
        title: string
        links: React.ReactNode
    }
}

export function CategoriesDrawer({
    categories,
    isOpen = false,
    moments
}: CategoriesDrawerProps): React.ReactElement<CategoriesDrawerProps> {
    ds.hooks.useScrollLock(isOpen)
    const { onTransitionEnd, step, unmounted } = ds.hooks.useTransition(isOpen)

    if (unmounted) return <></>

    return (
        <>
            <Overlay visible={step === 'during'} />
            <Body
                expanded={isOpen}
                id="categories-drawer" // ID used as an `aria-controls` target
                onTransitionEnd={onTransitionEnd}
                step={step}
            >
                <Container>
                    <Grid>
                        {categories && (
                            <Category.CategoriesWrapper>
                                <Category.CategoriesGrid>
                                    {categories}
                                </Category.CategoriesGrid>
                            </Category.CategoriesWrapper>
                        )}
                        {moments && (
                            <Moments>
                                {moments.title && (
                                    <MomentsTitle>{moments.title}</MomentsTitle>
                                )}
                                {moments.links}
                            </Moments>
                        )}
                    </Grid>
                </Container>
            </Body>
        </>
    )
}

export type BodyProps = React.HTMLAttributes<HTMLDivElement> & {
    expanded: boolean
}

export const Body = styled.div<BodyProps & { step: ds.hooks.UseTransition['step'] }>`
    background: ${ds.color.SAND_1};
    display: block;
    left: calc(-50vw + 50%);
    overflow-x: hidden;
    padding: 40px 0 100px;
    position: absolute;
    right: 0;
    top: ${ds.sizes.HEADER_DESKTOP_HEIGHT};
    transition-duration: 600ms;
    transition-property: transform, visibility;
    transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
    visibility: ${props => (props.expanded ? 'visible' : 'hidden')};
    width: 100vw;

    ${({ step }) => {
        if (step === 'before') {
            return css`
                transform: translate3d(0, -110%, 0);
            `
        }
        if (step === 'during') {
            return css`
                transform: translate3d(0);
            `
        }
        if (step === 'after') {
            return css`
                transform: translate3d(0, -110%, 0);
            `
        }
    }}
`

const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: ${ds.sizes.MAIN_DESKTOP_CONTAINER_WIDTH};
    min-width: 320px;
    width: 100%;
`

const Grid = styled.div<React.HTMLAttributes<HTMLDivElement>>`
    display: grid;
    grid-gap: 60px;
    grid-template-columns: 1fr 1.5fr;

    @media screen and (${ds.breakpoint.DESKTOP}) {
        grid-gap: calc(60px + ${100 / 12}%);
    }
`

const Moments = styled.div<React.HTMLAttributes<HTMLDivElement>>``

const MomentsTitle = styled(S.heading.Title3)`
    margin-bottom: -20px;
`
