import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
import * as React from 'react';
import * as cookie from '@bob/cookie-helper';
export function useHappening(key, startDate, endDate) {
  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isActive = _React$useState2[0],
      setIsActive = _React$useState2[1];

  React.useEffect(function () {
    if (checkEventHappening(startDate, endDate)) {
      var eventCookie = cookie.getCookie(key);

      if (!eventCookie) {
        setIsActive(true);
      }
    }
  }, [key, startDate, endDate]);
  return {
    isActive: isActive,
    markAsSeen: function markAsSeen() {
      cookie.setCookie(key, 'true', {
        expires: new Date(endDate).toUTCString()
      });
    }
  };
}

function checkEventHappening(startDate, endDate) {
  var clientDate = new Date();
  var start = new Date(startDate);
  var end = new Date(endDate);
  return clientDate >= start && clientDate <= end;
}