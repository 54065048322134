import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

import * as icons from '../svg/icons'

export type LinkButtonProps = S.linkButton.LinkButtonProps &
    React.AnchorHTMLAttributes<HTMLAnchorElement>

function LinkButtonComponent({
    children,
    disabled,
    ...rest
}: LinkButtonProps): React.ReactElement {
    return (
        <S.linkButton.LinkButton disabled={disabled} {...rest}>
            <S.linkButton.LinkText>{children}</S.linkButton.LinkText>
            <icons.ArrowRight />
        </S.linkButton.LinkButton>
    )
}

export const LinkButton = styled(LinkButtonComponent)``
