import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-shopping-bag-def'

FullShoppingBag.filename = SYMBOL_ID
export function FullShoppingBag(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="shopping-bag">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M18.1284 8.57227L11.9995 2.44336L5.87061 8.57227H0.794922L3.31278 22.0008H20.6869L23.2048 8.57227H18.1284ZM15.3 8.57227L11.9995 5.27179L8.69904 8.57227H15.3ZM4.97263 20.0008L3.20477 10.5723H20.7949L19.0271 20.0008H4.97263Z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const ShoppingBag = styled(function ShoppingBag(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="shopping-bag">
            <use href={href} />
        </icon.Icon>
    )
})``
