import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-danger-triangle-def'

FullDangerTriangle.filename = SYMBOL_ID
export function FullDangerTriangle(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="danger-triangle">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M12 1.897a3 3 0 00-2.565 1.445l.855.518-.858-.514-8.47 14.14-.008.014A3 3 0 003.519 22H20.48a2.999 2.999 0 002.565-4.5l-.008-.014-8.47-14.14-.003-.004A3 3 0 0012 1.897zM2.682 18.506l8.464-14.13a1 1 0 011.708 0v.003l8.463 14.127A1 1 0 0120.463 20H3.536a1 1 0 01-.854-1.494zm5.964-1.86L11.293 14l-2.647-2.646.708-.707L12 13.293l2.646-2.646.708.707L12.707 14l2.647 2.646-.707.708L12 14.707l-2.646 2.647-.708-.707z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const DangerTriangle = styled(function DangerTriangle(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="danger-triangle">
            <use href={href} />
        </icon.Icon>
    )
})``
