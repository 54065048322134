import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-clock-def'

FullClock.filename = SYMBOL_ID
export function FullClock(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="clock">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm9-11C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm1 5a1 1 0 10-2 0v6a1 1 0 00.553.894l4 2a1 1 0 10.894-1.788L13 11.382V6z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const Clock = styled(function Clock(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="clock">
            <use href={href} />
        </icon.Icon>
    )
})``
