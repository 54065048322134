import styled from 'styled-components'

import * as copy from '../styles/copy'

export const Banner = styled.div`
    background: #ffeba4; // TODO: FIXME: Add dedicated color
`

export const Text = styled(copy.Text4)`
    padding: 8px;
    text-align: center;

    a {
        font-size: inherit;
    }
`
