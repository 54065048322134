import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as color from '../constants/colors'
import * as font from '../constants/fonts'

import * as blobBackground from './blobBackground'
import * as illustrations from '../svg/illustrations'
import * as link from './link'

export const Blob = styled.div<React.HTMLAttributes<HTMLDivElement>>`
    position: absolute;
    top: -5px;
    left: 50%;
    display: inline-block;
    transform: translateX(-50%);

    @media screen and (${breakpoint.TABLET}) {
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
    }
`

export const Illustration = styled.div<React.HTMLAttributes<HTMLDivElement>>`
    position: relative;
    z-index: 1;
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto -6px;

    ${illustrations.Illustration} {
        height: auto;
        width: 54px;
    }

    @media screen and (${breakpoint.TABLET}) {
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
    }
`

export const Link = styled.div`
    position: relative;
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: ${font.PRIMARY};
    color: ${color.GRAY_3};

    ${blobBackground.Wrapper} {
        opacity: 1;
        height: 64px;
        left: calc(50% - 28px);
        position: absolute;
        top: -4px;
        width: 64px;
    }

    @media screen and (${breakpoint.TABLET}) {
        ${blobBackground.Wrapper} {
            height: 100px;
            left: calc(50% - 50px);
            opacity: 0;
            top: -20px;
            transform: scale(0.8);
            width: 100px;
        }

        &:focus,
        &:hover {
            ${blobBackground.Wrapper}, ${Illustration} {
                opacity: 1;
                transform: scale(1);
                transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
            }
        }
    }
`

export const Title = styled.span<React.HTMLAttributes<HTMLSpanElement>>`
    position: relative;
    z-index: 1;
    flex-grow: 1;
    font-size: 20px;
    @media not screen and (${breakpoint.TABLET}) {
        white-space: nowrap;
    }
`
