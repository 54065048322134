import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as font from '../constants/fonts'

export const Base = styled.div<React.HTMLAttributes<HTMLDivElement>>`
    font-family: ${font.PRIMARY};
    margin: 0;
    padding: 0;
`

export const Title1 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 45px;
    line-height: 1;
    @media screen and (${breakpoint.TABLET}) {
        font-size: 70px;
    }
    @media screen and (${breakpoint.LAPTOP}) {
        font-size: 95px;
    }
`
export const Title2 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 35px;
    line-height: 100%;
    @media screen and (${breakpoint.TABLET}) {
        font-size: 65px;
    }
`
export const Title3 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 28px;
    line-height: 38px;
    @media screen and (${breakpoint.TABLET}) {
        font-size: 35px;
        line-height: 47.5px;
    }
`
export const Title4 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 32px;
    line-height: 115%;

    @media screen and (${breakpoint.TABLET}) {
        font-size: 50px;
        line-height: 50px;
    }
`
export const TitleTab = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 18px;
    line-height: 23.5px;
    @media screen and (${breakpoint.TABLET}) {
        font-size: 24px;
        line-height: 31px;
    }
`
export const TitleCard = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 18px;
    line-height: 118%;
    @media screen and (${breakpoint.TABLET}) {
        font-size: 20px;
    }
`
export const TitleCardArticle = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-family: ${font.SECONDARY};
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
`

export const TitleCategoryProduct = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-family: ${font.SECONDARY};
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
`
