import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as blob from '../Blob'

const SYMBOL_ID = 'blob-1-def'

FullBlob1.filename = SYMBOL_ID
export function FullBlob1(props: blob.SpecificIconProps): React.ReactElement {
    return (
        <blob.Blob {...props} viewBox="0 0 110 101" name="blob-1">
            <defs>
                <path
                    id={SYMBOL_ID}
                    d="m47.706 11.583c26.56-5.3447 56.275 1.1995 60.231 20.859 3.956 19.659-17.806 52.635-44.366 57.979-26.56 5.3448-58.101-16.904-62.057-36.564-3.9561-19.659 19.632-36.93 46.192-42.275z"
                />
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </blob.Blob>
    )
}

export const Blob1 = styled(function Blob1(
    props: blob.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <blob.Blob {...props} viewBox="0 0 110 101" name="blob-1">
            <use href={href} />
        </blob.Blob>
    )
})``
