import styled from 'styled-components'

export type ImageProps = {
    sizing?: 'contain' | 'cover'
}

export const Image = styled.img<ImageProps>`
    object-fit: ${({ sizing = 'cover' }) => sizing};
    object-position: center;
    width: 100%;
    height: 100%;

    // HACK: IE: hide image, object-fit not supported
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        opacity: 0;
    }
`

export type PictureProps = {
    src?: string
    sizing?: 'contain' | 'cover'
}

export const BasePicture = styled.picture<PictureProps>`
    overflow: hidden;
    display: block;

    // for IE, add background-image, object-fit not supported
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-position: center;
        pointer-events: none;
        background-repeat: no-repeat;
    }
`

export const Picture = styled(BasePicture)<PictureProps>`
    // HACK: Safari messes up rendering of rasterised images (among other stuff) when scaled up
    // while having borders and hidden overflow. This forces size re-calculation, skipping the rendering glitch.
    -webkit-transform: translate3d(0, 0, 0);

    // HACK: IE: add background-image, object-fit not supported
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-size: ${({ sizing = 'cover' }) => sizing};
        background-image: url(${({ src }) => src});
    }
`
