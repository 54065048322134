import styled from 'styled-components'

import * as S from '../styles'

import * as icons from '../svg/icons'

export type ToastProps = {
    onClose?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
    closable?: boolean
    noShadow?: boolean
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> &
    S.toast.ToastProps

function selectIcon(status: S.toast.ToastProps['status']) {
    switch (status) {
        case 'alert':
            return <icons.AlertTriangle />
        case 'error':
            return <icons.DangerTriangle />
        case 'info':
            return <icons.InfoRound />
        case 'success':
            return <icons.Check />
    }
}

function ToastComponent({
    status = 'classic',
    closable = false,
    noShadow = false,
    onClose,
    ...baseToastProps
}: ToastProps): React.ReactElement {
    return (
        <S.toast.Toast status={status} {...baseToastProps} noShadow={noShadow}>
            {selectIcon(status)}
            <S.toast.Message>{baseToastProps.children}</S.toast.Message>
            {closable && <icons.Cross onClick={onClose} />}
        </S.toast.Toast>
    )
}

export const Toast = styled(ToastComponent)``
