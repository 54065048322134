import * as React from 'react'

export function useLoseFocus(
    ref: React.RefObject<HTMLElement>,
    onLoseFocus: () => void,
    deps?: React.DependencyList | undefined
): void {
    // ref to avoid blur running after mousedown
    const hasRunLock = React.useRef(false)
    // ref to detect if mousedown outside was done after mousedown inside
    const lastClickTarget = React.useRef<HTMLElement>()

    React.useEffect(() => {
        if (ref.current !== null) {
            ref.current.addEventListener('blur', handleLoseFocus, { capture: true })
        }
        window.addEventListener('mousedown', handleClickOutside)

        return () => {
            if (ref.current !== null) {
                ref.current.removeEventListener('blur', handleLoseFocus, {
                    capture: true
                })
            }
            window.removeEventListener('mousedown', handleClickOutside)
        }
    }, deps)

    function handleClickOutside(event: MouseEvent): void {
        if (ref.current === null) {
            return
        }
        if (
            !ref.current.contains(event.target as Node) &&
            ref.current.contains(lastClickTarget.current || null)
        ) {
            hasRunLock.current = true
            onLoseFocus()
        }
        lastClickTarget.current = event.target as HTMLElement
    }

    function handleLoseFocus(event: FocusEvent): void {
        if (ref.current === null) {
            return
        }
        if (!ref.current.contains(event.relatedTarget as Node)) {
            if (!hasRunLock.current) {
                onLoseFocus()
            }
            hasRunLock.current = false
        }
    }
}
