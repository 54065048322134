import * as entity from '@bob/entities'
import * as logger from '@bob/logger'
import * as utils from '@bob/utils'

export function matchBrand(
    identifier: string,
    brand: { identifier?: string | undefined | null; name: string }
): boolean {
    /* find matching brand with identifier or name as fallback since identifiers aren't all filled yet */
    return (
        utils.text.slugify(brand.identifier ?? brand.name, '-') ===
        utils.text.slugify(identifier, '-')
    )
}

export function getMatchingBrand(
    vendor: string,
    cold: { brands: entity.cold.SlimColdData['brands'] }
): entity.brand.SlimColdBrand {
    // first try exact match with `brand.identifier`
    const identifierMatch = Object.values(cold.brands).find(brand =>
        matchBrand(vendor, {
            identifier: brand.identifier,
            name: brand.name
        })
    )

    if (identifierMatch !== undefined) {
        // NOTE: To account for brands that match but have missing/wrong identifiers
        //  Contentful-side, we manually set it to the Shopify vendor.
        identifierMatch.identifier = vendor
        return identifierMatch
    }

    const vendorSlug = utils.text.slugify(vendor, '-')

    // no exact match found, fallback on approximate match with `brand.slug`
    const slugMatch = Object.values(cold.brands).find(brand => brand.slug === vendorSlug)

    if (slugMatch !== undefined) {
        return { ...slugMatch, identifier: vendor }
    }

    const errorMessage = `no brand matching given vendor ${vendorSlug} found`
    logger.err(`[CxS] ${utils.text.capitalize(errorMessage)}`)
    throw Error(errorMessage)
}
