import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type LoaderProps = S.loader.SvgProps & React.SVGAttributes<SVGElement>

function LoaderComponent({ size, color, ...rest }: LoaderProps): React.ReactElement {
    return (
        <S.loader.Svg
            size={size}
            color={color}
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <S.loader.Circle cx="50" cy="50" r="45" />
        </S.loader.Svg>
    )
}

export const Loader = styled(LoaderComponent)``
