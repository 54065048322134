/*
    NOTE: In an effort to avoid initializing the formatter multiple times
     for a single view, we force the currency as we only support one.
     This shall be a different story if implementing multi-currency support.
 */
const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
})

export function format(amount: number): string {
    return formatter.format(amount).replace(/([.,])00/g, '')
}
