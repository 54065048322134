import * as Sentry from '@sentry/nextjs'

type ExtraData = {
    [id: string]: any
}

const DEBUG_MODE = process.env.ENVIRONMENT !== 'production'

export function err(
    error: unknown,
    extra?: ExtraData,
    configureScope?: (scope: Sentry.Scope) => void
): void {
    if (typeof error === 'string') {
        captureMessage(error, Sentry.Severity.Error, extra, configureScope)
    } else {
        captureException(error, extra, configureScope)
    }
}

export function inform(
    message: string,
    extra?: ExtraData,
    configureScope?: (scope: Sentry.Scope) => void
): void {
    captureMessage(message, Sentry.Severity.Info, extra, configureScope)
}

export function log(
    message: string,
    extra?: ExtraData,
    configureScope?: (scope: Sentry.Scope) => void
): void {
    captureMessage(message, Sentry.Severity.Log, extra, configureScope)
}

export function warn(
    message: string,
    extra?: ExtraData,
    configureScope?: (scope: Sentry.Scope) => void
): void {
    captureMessage(message, Sentry.Severity.Warning, extra, configureScope)
}

function captureException(
    exception: any,
    extra?: ExtraData,
    configureScope?: (scope: Sentry.Scope) => void
): void {
    Sentry.withScope((scope: Sentry.Scope) => {
        if (extra) {
            scope.setExtras(extra)
        }
        if (configureScope !== undefined) {
            configureScope(scope)
        }

        Sentry.captureException(exception)
    })

    if (DEBUG_MODE) debugPrint(exception, Sentry.Severity.Error, extra)
}

function captureMessage(
    message: string,
    severity: Sentry.Severity,
    extra?: ExtraData,
    configureScope?: (scope: Sentry.Scope) => void
): void {
    Sentry.withScope((scope: Sentry.Scope) => {
        if (extra) {
            scope.setExtras(extra)
        }
        if (configureScope !== undefined) {
            configureScope(scope)
        }

        Sentry.captureMessage(message, severity)
    })

    if (DEBUG_MODE) debugPrint(message, severity, extra)
}

/* eslint-disable no-console */
function debugPrint(message: string, severity: Sentry.Severity, extra?: ExtraData): void {
    let printToConsole
    switch (severity) {
        case Sentry.Severity.Critical:
        case Sentry.Severity.Error:
        case Sentry.Severity.Fatal:
            printToConsole = console.error
            break
        case Sentry.Severity.Warning:
            printToConsole = console.warn
            break
        case Sentry.Severity.Info:
            printToConsole = console.info
            break
        case Sentry.Severity.Debug:
        case Sentry.Severity.Log:
        default:
            printToConsole = console.log
            break
    }

    function capitalize(s: string): string {
        if (!s) return ''
        return s[0].toUpperCase() + s.substring(1)
    }

    printToConsole(`${capitalize(severity)}: ${message}`, extra)
}
