import * as React from 'react'

import { Cart, cartContext } from './cartContext'

export function useCart(): Cart {
    const cart = React.useContext(cartContext)

    if (cart === undefined) {
        throw Error("can't useCart outside CartProvider")
    }

    return cart
}
