import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as blob from '../Blob'

const SYMBOL_ID = 'blob-2-def'

FullBlob2.filename = SYMBOL_ID
export function FullBlob2(props: blob.SpecificIconProps): React.ReactElement {
    return (
        <blob.Blob {...props} viewBox="0 0 110 110" name="blob-2">
            <defs>
                <path
                    id={SYMBOL_ID}
                    d="m97.142 46.923c8.2168 18.868 2.9928 36.154-5.2459 46.323-8.3376 10.377-19.88 13.508-39.783 8.0888-20.002-5.2125-48.601-19.272-51.046-39.706-2.4003-20.266 21.261-47.242 43.921-50.505 22.47-3.3913 43.938 16.931 52.155 35.799z"
                />
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </blob.Blob>
    )
}

export const Blob2 = styled(function Blob2(
    props: blob.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <blob.Blob {...props} viewBox="0 0 110 110" name="blob-2">
            <use href={href} />
        </blob.Blob>
    )
})``
