import * as React from 'react'

import { isMobile } from '../../utils/matchMediaOnBrowser'
import { reducer } from './reducer'
import { useDebounce } from '../../hooks/useDebounce'
import { useCommittedRef } from '../../hooks/useCommitedRef'
import { useNavigableList } from '../list'

import {
    AutocompleteItemApi,
    AutocompleteListApi,
    AutocompleteResult,
    Result
} from './types'

export function useAutocompleteList(
    query: string,
    onSelect: (result: Result) => void,
    onEnterKeyDown: (currentQuery: string) => void,
    fetch: (query: string) => Promise<Result[]>
): AutocompleteListApi {
    const [state, dispatch] = React.useReducer(reducer, {
        isLoading: false,
        isOpen: false,
        currentQuery: query,
        focused: undefined,
        results: []
    })
    // this ref will always point to the current state. Event handlers can close over
    // this ref and be able to access current state without deregister/reregister.
    const stateCommittedRef = useCommittedRef(state)

    const debouncedHandleQuery = useDebounce(handleQuery, false, 400)

    const navigable = useNavigableList(state.isOpen, state.focused, false, true)

    React.useEffect(() => {
        if (query !== state.currentQuery) {
            debouncedHandleQuery(query)
        }
    }, [query, state.currentQuery])

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    return {
        results: state.results,
        isOpen: state.isOpen,
        isLoading: state.isLoading,
        open: () => dispatch({ type: 'open' }),
        close: () => dispatch({ type: 'close' }),
        register
    }

    // register an item in the AutocompleteList. Usually not used directly, but passed
    // down the tree in a context, to allow child self-registration.
    function register(result: AutocompleteResult): AutocompleteItemApi {
        const { focus, isFocused } = navigable.register({
            identifier: result.value,
            label: result.label,
            disabled: false,
            handleFocus: () => {
                dispatch({ type: 'focus', focused: result.value })
            },
            filtered: false
        })

        return {
            select: () => {
                onSelect(result)
                dispatch({ type: 'close', result })
            },
            focus,
            isFocused,
            isFiltered: false
        }
    }

    function handleKeyDown(event: KeyboardEvent): void {
        const state = stateCommittedRef.current
        if (state.isOpen) {
            if (event.key === 'Escape' || event.key === 'Esc') {
                event.preventDefault()
                dispatch({ type: 'close' })
                return
            }
            if (event.key === 'Enter') {
                event.preventDefault()
                if (state.focused === undefined) {
                    if (state.results.length > 0 && state.currentQuery.length > 0) {
                        dispatch({ type: 'close' })
                        onEnterKeyDown(state.currentQuery)
                    }
                }
                const selected = state.results.find(
                    result => result.value === state.focused
                )
                if (selected !== undefined) {
                    onSelect(selected)
                    dispatch({ type: 'close', result: selected })
                }
            }
        }
    }

    function handleQuery(query: string): void {
        dispatch({ type: 'query', query })

        if (query.length === 0) {
            if (isMobile()) {
                dispatch({ type: 'result', for: '', results: [] })
            } else {
                dispatch({ type: 'close' })
            }
        } else {
            fetch(query).then(results => {
                dispatch({ type: 'result', for: query, results })
            })
        }
    }
}
