import styled, { css } from 'styled-components'

import * as breakpoints from '../../constants/breakpoints'
import * as color from '../../constants/colors'
import * as heading from '../heading'
import * as banner from '../banner'

export const Wrapper = styled.div`
    &:not(:last-child) {
        margin-bottom: 70px;
    }

    display: flex;
    flex-direction: column;
    width: 100%;

    ${banner.Banner} {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
`

export const Outer = styled.div<{ hasBanner: boolean }>`
    background-color: ${color.SAND_2};
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    @media screen and (${breakpoints.TABLET}) {
        padding: 20px;
    }

    ${({ hasBanner }) => {
        if (!hasBanner) {
            return css`
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            `
        }
    }}
`

export const Header = styled.header`
    width: 100%;
    padding: 0 18px 24px;
    display: flex;
    align-items: center;
`

export const Title = styled(heading.TitleTab)`
    margin-left: 18px;
`

export const Body = styled.div`
    display: flex;
    flex-direction: column;

    > * {
        margin-bottom: 32px;
    }
`

export const Footer = styled.footer`
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    padding-top: 20px;
`
