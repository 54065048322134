import * as api from '@bob/api-client'
import * as logger from '@bob/logger'
import * as validation from '@bob/utils/validation'

export async function register(
    email: string,
    list: string
): Promise<{ success: true } | { success: false; message: string }> {
    try {
        const isValid = validation.basicEmailValidation(email)

        if (!isValid) {
            return {
                success: false,
                message: "L'email entré est invalide"
            }
        }

        const response = await api.request(`/api/subscribe/${list}`, {
            method: 'POST',
            type: 'json',
            body: {
                email
            }
        })

        return response.caseOf<{ success: true } | { success: false; message: string }>({
            left: failure => {
                switch (failure.status) {
                    case 422:
                        return { success: false, message: "L'email entré est invalide" }
                    default:
                        return { success: false, message: 'Une erreur est survenue' }
                }
            },
            right: () => {
                return { success: true }
            }
        })
    } catch (exception) {
        logger.err(`[Newsletter] Subscription failed`, {
            exception,
            list
        })

        return { success: false, message: 'Une erreur est survenue' }
    }
}
