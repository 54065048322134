import styled, { css } from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as color from '../constants/colors'
import * as sizes from '../constants/sizes'
import { UseTransition } from '../hooks'
import { hexToRgb } from '../utils/colors'

import * as button from './button'
import * as cartCard from './card/cart'
import * as cartItem from './cartItem'
import * as copy from './copy'
import * as heading from './heading'
import * as modal from './modal'
import * as picture from './picture'

const FOOTER_HEIGHT = '165px'

export const Wrapper = styled.div<{ open: boolean; step: UseTransition['step'] }>`
    background: ${color.SAND_1};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-height: 650px;
    min-height: 500px;
    overflow: hidden;
    padding: 10px;
    position: absolute;
    right: 10px;
    top: calc(${sizes.HEADER_DESKTOP_INTERNAL_HEIGHT} + 10px);
    transition-duration: 300ms;
    transition-property: opacity, visibility;
    transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    width: 375px;

    @media screen and (${breakpoint.LAPTOP_L}) {
        right: -20px;
    }

    ${({ step }) => {
        if (step === 'before') {
            return css`
                opacity: 0;
            `
        }
        if (step === 'during') {
            return css`
                opacity: 1;
            `
        }
        if (step === 'after') {
            return css`
                opacity: 0;
            `
        }
    }}
`

export const Header = styled(heading.Title3)`
    display: flex;
    justify-content: center;
    padding: 26px 0 0;
`

export const Description = styled(copy.Text5)`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
`

export const Content = styled.div<{ popupHasFooter: boolean }>`
    margin: auto;
    overflow-y: auto;
    padding-bottom: ${({ popupHasFooter }) =>
        popupHasFooter ? `calc(${FOOTER_HEIGHT} + 20px);` : '20px'};

    ${cartCard.Header} {
        ${picture.Picture} {
            height: 50px;
            width: 50px;
        }
    }

    ${cartCard.Outer} {
        padding: 10px;
    }

    ${cartCard.Title} {
        font-size: 18px;
    }

    ${cartItem.Body} {
        padding: 0 0 0 15px;
    }
`

export const Footer = styled.div<{ showInsetShadow: boolean }>`
    background: ${color.SAND_1};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${FOOTER_HEIGHT};
    padding: 30px 20px 20px;
    display: flex;
    flex-wrap: wrap;

    > ${button.ButtonAsLink} {
        align-self: end;
        width: 100%;
    }

    &::before {
        background: linear-gradient(
            to bottom,
            transparent,
            ${hexToRgb(color.GRAY_1, 0.1)}
        );
        content: '';
        height: 25px;
        left: 0;
        opacity: ${({ showInsetShadow }) => (showInsetShadow ? '0.6' : '0')};
        position: absolute;
        top: -25px;
        transition: opacity, visibility;
        transition-duration: 100ms;
        transition-timing-function: ease-in-out;
        visibility: ${({ showInsetShadow }) => (showInsetShadow ? 'visible' : 'hidden')};
        width: 100%;
    }
`

export const Overlay = styled(modal.Overlay)<{ visible: boolean }>`
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    position: fixed;
    top: ${sizes.HEADER_MOBILE_INTERNAL_HEIGHT};
    transition-duration: 300ms;
    transition-property: opacity, visibility;
    transition-timing-function: ease-in-out;
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    width: 100%;
    z-index: -1;

    @media screen and (${breakpoint.TABLET}) {
        top: ${sizes.HEADER_DESKTOP_INTERNAL_HEIGHT};
    }
`
