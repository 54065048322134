import styled from 'styled-components'

import * as breakpoint from '../constants/breakpoints'

export const Logo = styled.svg<React.SVGProps<SVGSVGElement>>`
    width: 60px;
    height: 35px;
    @media screen and (${breakpoint.TABLET}) {
        width: 102px;
        height: 61px;
    }
`
