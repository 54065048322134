import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-alert-triangle-def'

FullAlertTriangle.filename = SYMBOL_ID
export function FullAlertTriangle(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="alert-triangle">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M10.528 2.283a3 3 0 014.037 1.059l.003.004 8.47 14.14.008.014A3.001 3.001 0 0120.48 22H3.519a3 3 0 01-2.565-4.5l.008-.014 8.47-14.14.858.514-.855-.518a3 3 0 011.093-1.059zm.618 2.094L2.682 18.506A1 1 0 003.536 20h16.927a1 1 0 00.854-1.494L12.855 4.38l-.001-.002a1 1 0 00-1.708 0zM12 12.586l2.293-2.293 1.414 1.414L13.414 14l2.293 2.293-1.414 1.414L12 15.414l-2.293 2.293-1.414-1.414L10.586 14l-2.293-2.293 1.414-1.414L12 12.586z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const AlertTriangle = styled(function AlertTriangle(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="alert-triangle">
            <use href={href} />
        </icon.Icon>
    )
})``
