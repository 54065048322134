import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-chevron-left-def'

FullChevronLeft.filename = SYMBOL_ID
export function FullChevronLeft(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="chevron-left">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M10.4144 12.0001L15.7073 6.70718L14.293 5.29297L7.58594 12.0001L14.293 18.7072L15.7073 17.293L10.4144 12.0001Z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const ChevronLeft = styled(function ChevronLeft(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="chevron-left">
            <use href={href} />
        </icon.Icon>
    )
})``
