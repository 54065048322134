import Link from 'next/link'
import styled from 'styled-components'

import { categories } from '@bob/cold-data/client'
import * as ds from '@bob/design-system'
import * as url from '@bob/url'
import { blob, resetBlob } from '@bob/utils/blob'

type CategoryLinksProps = {
    onSelect?: () => void
    vertical?: boolean
}

function CategoryLinksComponent({
    onSelect = () => {
        // no-op
    },
    vertical = false
}: CategoryLinksProps): React.ReactElement<CategoryLinksProps> {
    resetBlob()

    return (
        <>
            {Object.values(categories).map(category => {
                const illustration = categoryToIllustration(category.slug)
                if (!illustration) return null
                return (
                    <Link
                        href={`${url.products({ title: '' }).path}?category=${
                            category.slug
                        }`}
                        key={category.slug}
                        prefetch={false}
                    >
                        <a>
                            <ds.CategoryLink
                                blob={blob()}
                                illustration={illustration}
                                name={category.name}
                                onClick={onSelect}
                                vertical={vertical}
                            />
                        </a>
                    </Link>
                )
            })}
        </>
    )
}

export const CategoryLinks = styled(CategoryLinksComponent)``

export const CategoriesGrid = styled.div<React.HTMLAttributes<HTMLDivElement>>`
    display: grid;
    padding: 20px;
    gap: 10px;

    @media screen and (${ds.breakpoint.TABLET}) {
        padding: 0;
        gap: 30px;
        grid-template-columns: repeat(3, 1fr);
    }
`

export const CategoriesWrapper = styled.div<React.HTMLAttributes<HTMLDivElement>>``

function categoryToIllustration(slug: string): React.ReactElement | undefined {
    switch (slug) {
        case 'alimentation':
            return <ds.illustrations.categories.Food />
        case 'animaux':
            return <ds.illustrations.categories.Animals />
        case 'hygiene-beaute-et-bien-etre':
            return <ds.illustrations.categories.Hygiene />
        case 'jeux-jouets-et-loisirs':
            return <ds.illustrations.categories.Game />
        case 'livres-et-papeterie':
            return <ds.illustrations.categories.Stationery />
        case 'maison-et-jardin':
            return <ds.illustrations.categories.House />
        case 'mode-et-accessoires':
            return <ds.illustrations.categories.Fashion />
        case 'puericulture':
            return <ds.illustrations.categories.Childcare />
        case 'sport-et-plein-air':
            return <ds.illustrations.categories.Sports />
    }
}
