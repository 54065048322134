import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-instagram-def'

FullInstagram.filename = SYMBOL_ID
export function FullInstagram(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="instagram">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M7 3a4 4 0 00-4 4v10a4 4 0 004 4h10a4 4 0 004-4V7a4 4 0 00-4-4H7zM1 7a6 6 0 016-6h10a6 6 0 016 6v10a6 6 0 01-6 6H7a6 6 0 01-6-6V7zm11.483 1.99a3 3 0 10-.88 5.934 3 3 0 00.88-5.935zM9.74 7.518a5 5 0 114.606 8.876A5 5 0 019.74 7.52zM17.5 5.5a1 1 0 100 2h.01a1 1 0 100-2h-.01z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const Instagram = styled(function Instagram(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="instagram">
            <use href={href} />
        </icon.Icon>
    )
})``
