import * as React from 'react'
import styled, { css } from 'styled-components'

import * as entity from '@bob/entities'
import * as ds from '@bob/design-system'
import * as money from '@bob/money'
import * as url from '@bob/url'
import { styles as S } from '@bob/design-system'
import * as shopify from '@bob/shopify-services'

import {
    getFreeShippingThresholdMessage,
    getRemainingAmountBeforeFreeShipping
} from '../helpers/shipping'
import type { EmptyCart, ReadyCart } from '../hooks/cartContext'
import { useDiscount } from '../hooks/useDiscount'
import { Discount } from './Discount'

type ModalState =
    | { open: false }
    | { open: true; resolve: () => void; reject: () => void }

type CartItemListProps = {
    cart: ReadyCart | EmptyCart
    freeShippingThresholds?: entity.shipping.FreeShippingThresholds
    shippingCountry?: string
    inPopup?: boolean
}

export function CartItemList({
    cart,
    freeShippingThresholds,
    shippingCountry,
    inPopup
}: CartItemListProps): React.ReactElement {
    const [modalState, setModalState] = React.useState<ModalState>({
        open: false
    })

    const [isBusy, setIsBusy] = React.useState(false)
    const isMountedRef = React.useRef(false)
    React.useEffect(() => {
        isMountedRef.current = true
        return () => {
            isMountedRef.current = false
        }
    }, [])

    const discount = useDiscount()

    if (cart.empty) {
        return <EmptyCartComponent inPopup={inPopup} />
    }

    return (
        <>
            {discount.isApplicable && (
                <Discount
                    inPopup={inPopup}
                    name={discount.name}
                    src={discount.image}
                    isBusy={isBusy}
                    onAccept={busyDecorate(discount.accept)}
                    onReject={busyDecorate(discount.reject)}
                />
            )}
            {cart.data.vendors.map(({ brand, lineItems }) => {
                const total = cart.total(brand.identifier)
                return (
                    <ds.CartCard
                        key={brand.identifier}
                        logo={brand.logo}
                        title={brand.name}
                        total={money.format(total)}
                        banner={
                            freeShippingThresholds && (
                                <CartItemBanner
                                    brand={brand}
                                    shippingCountry={shippingCountry}
                                    freeShippingThreshold={
                                        freeShippingThresholds[brand.identifier]
                                    }
                                    vendorTotal={total}
                                />
                            )
                        }
                    >
                        {lineItems.map(item => {
                            const price = entity.checkout.getPrice(item)
                            const totalDiscount = entity.checkout.getTotalDiscount(item)
                            const discountedPrice = entity.checkout.getDiscountedPrice(
                                item,
                                price,
                                totalDiscount
                            )

                            return (
                                <ds.CartItem
                                    disabled={isBusy}
                                    deliveryExtra={
                                        item.variant.product.deliveryExtra?.value ?? null
                                    }
                                    key={item.id}
                                    href={
                                        url.product({
                                            id: shopify.base.shopifyNumericId(
                                                item.variant.product.id,
                                                'Product'
                                            ),
                                            handle: item.variant.product.handle,
                                            title: item.variant.product.title
                                        }).path
                                    }
                                    image={item.variant.image.src}
                                    price={money.format(price)}
                                    productionDays={
                                        item.variant.product.productionDays?.value ?? null
                                    }
                                    discountedPrice={formatDiscountedPrice(
                                        discountedPrice
                                    )}
                                    quantity={item.quantity}
                                    stock={item.variant.quantityAvailable}
                                    subtitle={
                                        item.variant.title !== 'Default Title'
                                            ? item.variant.title
                                            : ''
                                    }
                                    title={item.variant.product.title}
                                    onIncrease={busyDecorate(async () => {
                                        await cart.update([
                                            {
                                                ...item,
                                                quantity: item.quantity + 1
                                            }
                                        ])
                                    })}
                                    onDecrease={busyDecorate(async () => {
                                        if (item.totalVariantQuantity === 1) {
                                            /* WARNING: This pattern is bad, and should not be used unless you
                                            have not other choice.

                                            It is considered an anti-pattern to have internal promise function
                                            (`resolve` and `reject`) outside the scope of the promise. This is an
                                            anti-pattern because it means internals of the promise can be passed
                                            everywhere in the code, leading to spaghetti code ("uh ho, i changed
                                            this one line there, and the building behind me collapsed") or tightly
                                            coupled code  (a method located elsewhere in the code can change
                                            internal state of this promise).

                                            In this case we need this pattern, because we need to be able to
                                            resolve/reject the promise outside its scope. The `onDecrease`
                                            method will disable all buttons on the `ds.CartItem` during the
                                            operation, so we need to have a promise that resolves only when the
                                            "decrementing" operation is done.
                                            If there is only one item, we want to display a modal for the user
                                            to confirm that the item should be deleted (and only delete the item
                                            after the user confirmation). This means that before trying to
                                            decrement, we have to await for the user to confirm.
                                            In order to do so, we need to bind a "resolve" method to a button in
                                            the modal. But the modal reside outside the scope of the `onDecrease`.
                                            So we need to use the "deferred" anti-pattern to "smuggle out" internal
                                            methods `resolve`/`reject` through a React state for them to be used
                                            inside the modal.
                                            */
                                            await new Promise<void>((resolve, reject) => {
                                                setModalState({
                                                    open: true,
                                                    resolve,
                                                    reject
                                                })
                                            })
                                            await cart.remove([item])
                                        } else {
                                            await cart.update([
                                                { ...item, quantity: item.quantity - 1 }
                                            ])
                                        }
                                    })}
                                    onRemove={busyDecorate(async () => {
                                        /** WARNING: This pattern is bad, and should not be used unless you
                                             have not other choice. See the other warning for a complete explanation
                                            */
                                        await new Promise<void>((resolve, reject) => {
                                            setModalState({
                                                open: true,
                                                resolve,
                                                reject
                                            })
                                        })
                                        if (item.totalVariantQuantity === item.quantity) {
                                            await cart.remove([item])
                                        } else {
                                            await cart.update([{ ...item, quantity: 0 }])
                                        }
                                    })}
                                    onSetAside={async () => {
                                        // TODO: set aside
                                    }}
                                />
                            )
                        })}
                    </ds.CartCard>
                )
            })}
            <ds.ConfirmationModal
                open={modalState.open}
                closable
                onClose={() => {
                    if (modalState.open) {
                        modalState.reject()
                    }
                    setModalState({ open: false })
                }}
                title="Confirmation"
                primary={{
                    label: 'Oui, retirer',
                    handler: () => {
                        if (modalState.open) {
                            modalState.resolve()
                        }
                        setModalState({ open: false })
                    }
                }}
                secondary={{
                    label: 'Non, conserver',
                    handler: () => {
                        if (modalState.open) {
                            modalState.reject()
                        }
                        setModalState({ open: false })
                    }
                }}
            >
                <S.copy.Text4>
                    Voulez-vous vraiment retirer cet article de votre panier ?
                </S.copy.Text4>
            </ds.ConfirmationModal>
        </>
    )

    function busyDecorate(fn: () => Promise<void>): () => Promise<void> {
        return async () => {
            if (isBusy) {
                return
            }

            setIsBusy(true)

            try {
                await fn()
            } catch (e) {
                // do nothing, errors means "action cancelation"
            } finally {
                // if fn is remove or decrement, the component might be unmounted. If so, we do not update the state
                isMountedRef.current && setIsBusy(false)
            }
        }
    }
}

type EmptyCartComponentProps = {
    inPopup?: boolean
}

function EmptyCartComponent({ inPopup }: EmptyCartComponentProps) {
    return (
        <EmptyCartWrapper inPopup={inPopup}>
            {!inPopup && <S.heading.Title4>Panier vide</S.heading.Title4>}
            <ds.illustrations.others.ShoppingCart />
            <S.copy.Text5>
                Votre panier est vide, explorez notre magasin pour le remplir de bons
                produits.
            </S.copy.Text5>
            <ds.ButtonAsLink href={url.products({}).path} small autoSized>
                Découvrir nos produits
            </ds.ButtonAsLink>
        </EmptyCartWrapper>
    )
}

const EmptyCartWrapper = styled.div<{ inPopup?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${ds.ButtonAsLink} {
        align-items: center;
    }

    ${S.copy.Text5} {
        margin: 14px 0 50px;
        text-align: center;
    }

    ${ds.illustrations.others.ShoppingCart} {
        max-width: 279px;
        max-height: 192px;
        width: 100%;
        height: 100%;
        margin: 50px;
    }

    ${({ inPopup = false }) => {
        return (
            !inPopup &&
            css`
                background-color: ${ds.color.SAND_2};
                border-radius: 10px;
                padding: 20px;

                @media screen and (${ds.breakpoint.TABLET}) {
                    display: block;
                    padding: 30px 319px 30px 30px;
                    position: relative;

                    ${S.copy.Text5} {
                        margin: 14px 0 24px;
                        text-align: left;
                    }

                    ${ds.illustrations.others.ShoppingCart} {
                        margin: 0;
                        position: absolute;
                        top: 30px;
                        right: 30px;
                    }
                }

                @media screen and (${ds.breakpoint.LAPTOP}) {
                    padding: 100px 379px 100px 100px;

                    ${ds.illustrations.others.ShoppingCart} {
                        top: 100px;
                        right: 100px;
                    }
                }
            `
        )
    }}
`
type CartItemBannerProps = {
    brand: entity.brand.ColdBrand
    freeShippingThreshold: number | null
    shippingCountry?: string
    vendorTotal: number
}

function CartItemBanner({
    brand,
    freeShippingThreshold,
    shippingCountry,
    vendorTotal
}: CartItemBannerProps) {
    const remainingAmountBeforeFreeShipping = getRemainingAmountBeforeFreeShipping(
        freeShippingThreshold,
        vendorTotal
    )

    if (
        freeShippingThreshold === null ||
        remainingAmountBeforeFreeShipping === Infinity
    ) {
        return <></>
    }

    const message = getFreeShippingThresholdMessage({
        remainingAmountBeforeFreeShipping,
        shippingCountry
    })

    return (
        <ds.Banner
            href={
                url.brand({
                    slug: brand.slug,
                    name: brand.name
                }).path
            }
            message={message}
            vendor={brand.name}
        />
    )
}

function formatDiscountedPrice(discountedPrice: number | undefined): string | undefined {
    if (discountedPrice === undefined) {
        return discountedPrice
    }
    if (discountedPrice === 0) {
        return 'Gratuit'
    }
    return money.format(discountedPrice)
}
