function getVercelUrl() {
    const VERCEL_URL = process.env.VERCEL_URL
    if (VERCEL_URL === undefined) {
        return undefined
    }
    if (!VERCEL_URL.match(/https?:\/\//)) {
        return `https://${VERCEL_URL}`
    }
    return VERCEL_URL
}

export const VERCEL_URL = getVercelUrl()
