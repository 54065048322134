import * as api from '@bob/api-client'

type Geolocation = {
    city: string
    country: { code: string; name: string }
    ip: string
    latitude: number
    longitude: number
    region: { code: string; name: string }
    timezone: string
    zipCode: string
}

type GeolocationResponse = {
    city: string
    country_code: string
    country_name: string
    ip: string
    lattitude: number
    longitude: number
    metro_code: number
    region_code: string
    region_name: string
    time_zone: string
    zip_code: string
}

export async function geolocation(): Promise<api.ApiResponse<Geolocation>> {
    const response = await api.request<GeolocationResponse>('https://geo.ulule.com/json/')

    return response.next(({ body }) => {
        return {
            country: {
                code: body.country_code,
                name: body.country_name
            },
            city: body.city,
            ip: body.ip,
            latitude: body.lattitude,
            longitude: body.longitude,
            region: { code: body.region_code, name: body.region_name },
            timezone: body.time_zone,
            zipCode: body.zip_code
        }
    })
}
