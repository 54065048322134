import { LinkedItem, Item } from './types'
import { getItemIndex, getItem, normalize, includesQuery } from './utils'

export type State<IDENTIFIER> = {
    registered: LinkedItem<IDENTIFIER>[]
    tail?: LinkedItem<IDENTIFIER>
    head?: LinkedItem<IDENTIFIER>
    typeAhead: { timestamp: number; query: string }
}

type RegisterAction<IDENTIFIER> = { type: 'register'; item: Item<IDENTIFIER> }
type DeregisterAction<IDENTIFIER> = { type: 'deregister'; identifier: IDENTIFIER }
type DisableAction<IDENTIFIER> = {
    type: 'disable'
    identifier: IDENTIFIER
    disabled: boolean
}
type TypeAheadAction = { type: 'type-ahead'; key: string }
type FilterAction = { type: 'filter'; filter: string }

type Action<IDENTIFIER> =
    | RegisterAction<IDENTIFIER>
    | DeregisterAction<IDENTIFIER>
    | DisableAction<IDENTIFIER>
    | TypeAheadAction
    | FilterAction

export type Reducer<IDENTIFIER> = (
    state: State<IDENTIFIER>,
    action: Action<IDENTIFIER>
) => State<IDENTIFIER>

export function reducer<IDENTIFIER>(
    state: State<IDENTIFIER>,
    action: Action<IDENTIFIER>
): State<IDENTIFIER> {
    switch (action.type) {
        case 'register': {
            const linkedItem = getItem(state.registered, action.item.identifier)
            if (linkedItem !== undefined) {
                return state
            }

            const newLinkedItem: LinkedItem<IDENTIFIER> = {
                ...action.item,
                next: undefined,
                previous: undefined
            }

            const nextState = {
                ...state,
                registered: [...state.registered, newLinkedItem]
            }
            if (nextState.head === undefined) {
                nextState.head = newLinkedItem
            }

            if (nextState.tail === undefined) {
                nextState.tail = newLinkedItem
            } else {
                nextState.tail.next = newLinkedItem
                newLinkedItem.previous = nextState.tail
                nextState.tail = newLinkedItem
            }

            return nextState
        }
        case 'deregister': {
            const linkedItemIndex = getItemIndex(state.registered, action.identifier)
            if (linkedItemIndex === -1) {
                return state
            }

            const linkedItem = state.registered[linkedItemIndex]
            let head = state.head
            let tail = state.tail
            if (linkedItem.previous) {
                linkedItem.previous.next = linkedItem.next
            }
            if (linkedItem.next) {
                linkedItem.next.previous = linkedItem.previous
            }
            if (head && linkedItem.identifier === head.identifier) {
                head = linkedItem.next
            }
            if (tail && linkedItem.identifier === tail.identifier) {
                tail = linkedItem.previous
            }

            return {
                ...state,
                head,
                tail,
                registered: [
                    ...state.registered.slice(0, linkedItemIndex),
                    ...state.registered.slice(linkedItemIndex + 1)
                ]
            }
        }
        case 'disable': {
            const linkedItemIndex = getItemIndex(state.registered, action.identifier)
            const linkedItem = state.registered[linkedItemIndex]

            if (linkedItem === undefined || linkedItem.disabled === action.disabled) {
                return state
            }

            const nextRegistered = [
                ...state.registered.slice(0, linkedItemIndex),
                { ...linkedItem, disabled: action.disabled },
                ...state.registered.slice(linkedItemIndex + 1)
            ]
            for (let i = 0; i < nextRegistered.length; i += 1) {
                const previous =
                    i === 0
                        ? nextRegistered[nextRegistered.length - 1]
                        : nextRegistered[i - 1]
                const next =
                    i === nextRegistered.length - 1
                        ? nextRegistered[0]
                        : nextRegistered[i + 1]
                nextRegistered[i].next = next
                nextRegistered[i].previous = previous
            }

            return {
                ...state,
                tail: nextRegistered[nextRegistered.length - 1],
                head: nextRegistered[0],
                registered: nextRegistered
            }
        }
        case 'type-ahead': {
            const now = Date.now()
            let query = state.typeAhead.query
            if (now - state.typeAhead.timestamp < 300) {
                query += action.key
            } else {
                query = action.key
            }
            return {
                ...state,
                typeAhead: { timestamp: now, query }
            }
        }
        case 'filter': {
            const filter = normalize(action.filter)
            const nextRegistered = state.registered.map(linkedItem => {
                if (includesQuery(linkedItem, filter)) {
                    return { ...linkedItem, filtered: false }
                }
                return { ...linkedItem, filtered: true }
            })

            for (let i = 0; i < nextRegistered.length; i += 1) {
                const previous =
                    i === 0
                        ? nextRegistered[nextRegistered.length - 1]
                        : nextRegistered[i - 1]
                const next =
                    i === nextRegistered.length - 1
                        ? nextRegistered[0]
                        : nextRegistered[i + 1]
                nextRegistered[i].next = next
                nextRegistered[i].previous = previous
            }

            return {
                ...state,
                tail: nextRegistered[nextRegistered.length - 1],
                head: nextRegistered[0],
                registered: nextRegistered
            }
        }
        default: {
            return state
        }
    }
}
