import * as React from 'react'

import { geolocationContext } from './geolocationContext'
import { geolocation } from './geolocation'

type GeolocationProviderProps = {
    children: React.ReactNode
}

export function GeolocationProvider({
    children
}: GeolocationProviderProps): React.ReactElement<GeolocationProviderProps> {
    const [geolocatedCountry, setGeolocatedCountry] = React.useState<string | undefined>()

    React.useEffect(() => {
        geolocation().then(response => {
            response.next(success => {
                setGeolocatedCountry(success.country.code)
            })
        })
    }, [])

    return (
        <geolocationContext.Provider value={{ geolocatedCountry }}>
            {children}
        </geolocationContext.Provider>
    )
}
