import styled from 'styled-components'

import { SizedSvg } from '../SizedSvg'

export type IconProps = React.SVGAttributes<SVGElement> & {
    children: React.ReactNode
    className?: string
    name: string
    onClick?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
    /** @default 24 */
    size?: number | string
    title?: string
    /** @default '0 0 24 24' */
    viewBox?: string
}

export type SpecificIconProps = Omit<IconProps, 'children' | 'name'> & {
    children?: React.ReactNode
}

/** Base SVG Icon Component */
function IconComponent({
    children,
    className,
    name,
    onClick,
    size = 24,
    title,
    viewBox = '0 0 24 24'
}: IconProps): React.ReactElement<IconProps> {
    return (
        <SizedSvg
            aria-labelledby={title && 'title'}
            className={className}
            clipRule="evenodd"
            fillRule="evenodd"
            height={size}
            id={`icon-${name}`}
            onClick={onClick}
            preserveAspectRatio="xMidYMid meet"
            $size={size}
            viewBox={viewBox}
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            {title && <title id="title">{name}</title>}
            {children}
        </SizedSvg>
    )
}

export const Icon = styled(IconComponent)<IconProps>``
