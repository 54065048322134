import * as React from 'react'

type AnyEvent = MouseEvent | TouchEvent

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    refs: React.RefObject<T>[],
    onClickOutside: (event: AnyEvent) => void
): void {
    React.useEffect(() => {
        document.addEventListener(`mousedown`, handler)
        document.addEventListener(`touchstart`, handler)

        return () => {
            document.removeEventListener(`mousedown`, handler)
            document.removeEventListener(`touchstart`, handler)
        }

        // not having `handler` as a dependency is safe, only `onClickOutside` might change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClickOutside])

    function handler(event: AnyEvent) {
        const isEventOutside = refs.every(ref => {
            const el = ref?.current

            if (!el || el.contains(event.target as Node)) {
                return false
            }

            return true
        })

        if (isEventOutside) {
            onClickOutside(event)
        }
    }
}
