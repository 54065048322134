import * as React from 'react'

import * as entity from '@bob/entities'

export type ReadyCart = {
    data: entity.checkout.Checkout
    empty: false
    ready: true
    touched: boolean
    add: (lineItems: entity.checkout.PartialLineItem[]) => Promise<{
        error?: entity.checkout.CheckoutError[]
        data?: entity.checkout.Checkout
    }>
    update: (
        lineItems: entity.checkout.LineItem[]
    ) => Promise<entity.checkout.CheckoutError[]>
    remove: (lineItems: { id: string }[]) => Promise<entity.checkout.CheckoutError[]>
    navigateToCheckout: () => Promise<void>
    total: (vendor?: string | null) => number
    updateAttributes: (
        attributes: { key: string; value: string }[]
    ) => Promise<entity.checkout.CheckoutError[]>
}

export type EmptyCart = {
    empty: true
    data?: entity.checkout.Checkout
    ready: true
    touched: boolean
    add: (lineItems: entity.checkout.PartialLineItem[]) => Promise<{
        error?: entity.checkout.CheckoutError[]
        data?: entity.checkout.Checkout
    }>
    total: (vendor?: string | null) => number
    updateAttributes: (
        attributes: { key: string; value: string }[]
    ) => Promise<entity.checkout.CheckoutError[]>
}

export type Cart = { ready: false; touched: false } | EmptyCart | ReadyCart

export const cartContext = React.createContext<undefined | Cart>(undefined)
