import * as money from '@bob/money'

export function getFreeShippingThresholdMessage({
    remainingAmountBeforeFreeShipping,
    shippingCountry,
    brandName = ''
}: {
    remainingAmountBeforeFreeShipping: number
    shippingCountry?: string
    brandName?: string
}): string {
    let freeShippingObtained = `Vous bénéficiez de la livraison standard gratuite chez`
    freeShippingObtained += brandName ? ` ${brandName}` : ``

    let freeShippingYetToBeObtained = `Plus que ${money.format(
        remainingAmountBeforeFreeShipping
    )} pour bénéficier de la livraison standard gratuite `
    freeShippingYetToBeObtained += shippingCountry ? `(${shippingCountry}) chez` : `chez`
    freeShippingYetToBeObtained += brandName ? ` ${brandName}` : ``

    return remainingAmountBeforeFreeShipping > 0
        ? freeShippingYetToBeObtained
        : freeShippingObtained
}

export function getRemainingAmountBeforeFreeShipping(
    freeShippingThreshold: number | null | undefined,
    amount: number
): number {
    if (freeShippingThreshold === null || freeShippingThreshold === undefined) {
        return Infinity
    }

    return Math.max(0, freeShippingThreshold - amount)
}
