import * as React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'

import { useCart, useCartItemCount } from '@bob/cart'
import * as ds from '@bob/design-system'
import { styles as S } from '@bob/design-system'
import * as url from '@bob/url'

import { Autocomplete } from '../Autocomplete'
import { CartPopup } from './CartPopup'

function ShortcutsComponent(): React.ReactElement {
    const cart = useCart()
    const itemCount = useCartItemCount()
    const router = useRouter()
    const [cartPopupOpen, setCartPopupAsOpen] = React.useState(false)

    // Open up cart popup when a product is added (except on mobile devices and cart page)
    const previousCount = usePreviousCount(cart.ready && !cart.empty ? itemCount : 0)

    React.useEffect(() => {
        const cartTouched = cart.ready && !cart.empty && cart.touched
        const isNotCartView = router.asPath !== url.cart().path
        const itemsAdded = cart.ready && !cart.empty && itemCount > previousCount
        if (
            cartTouched &&
            !ds.utils.browser.isMobile() &&
            isNotCartView &&
            itemsAdded &&
            !cartPopupOpen
        ) {
            setCartPopupAsOpen(true)
        }
    }, [cart, itemCount, cartPopupOpen, previousCount, router.asPath])

    const $trigger = React.useRef<HTMLElement>(null)

    return (
        <Wrapper>
            <ShortcutList>
                <Shortcut hideOnMobile>
                    <Autocomplete />
                </Shortcut>
                <Shortcut hideOnMobile>
                    <a href={url.login().path}>
                        <ds.icons.User size={30} />
                    </a>
                </Shortcut>
                <Shortcut>
                    <CartShortcut
                        ref={$trigger}
                        onClick={() => {
                            if (ds.utils.browser.isMobile()) {
                                window.location.href = url.cart().path
                            } else {
                                setCartPopupAsOpen(!cartPopupOpen)
                            }
                        }}
                    >
                        <ds.icons.ShoppingBag size={30} />
                        {itemCount !== 0 && (
                            <Badge>
                                <S.copy.Text3 as="span">{itemCount}</S.copy.Text3>
                            </Badge>
                        )}
                    </CartShortcut>

                    <CartPopup
                        trigger={$trigger}
                        cart={cart}
                        isOpen={cartPopupOpen}
                        setAsOpen={setCartPopupAsOpen}
                    />
                </Shortcut>
            </ShortcutList>
        </Wrapper>
    )
}

function usePreviousCount(value: number) {
    const ref = React.useRef<number>(value)
    React.useEffect(() => {
        ref.current = value
    })
    return ref.current
}

const Badge = styled.span`
    background: ${ds.color.ORANGE};
    border-radius: 50%;
    color: ${ds.color.WHITE};
    height: 18px;
    margin-left: -14px;
    position: absolute;
    text-align: center;
    top: 40px;
    user-select: none;
    width: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: baseline;

    ${S.copy.Text3} {
        line-height: 1;
    }

    @media screen and (${ds.breakpoint.TABLET}) {
        top: 46px;
    }
`

const CartShortcut = styled.span`
    cursor: pointer;
`

type ShortcutProps = React.HTMLAttributes<HTMLLIElement> & {
    hideOnMobile?: boolean
}

const Shortcut = styled.li<ShortcutProps>`
    margin: 5px 13px;
    padding: 5px;

    :first-of-type {
        margin-right: 0;
        margin-left: 0;
        padding-left: 0;

        @media not screen and (${ds.breakpoint.TABLET}) {
            display: none;
        }
    }

    :last-of-type {
        margin-right: 0;
        padding-right: 0;

        @media not screen and (${ds.breakpoint.TABLET}) {
            margin-top: 0;
        }
    }

    ${({ hideOnMobile = false }) => {
        if (hideOnMobile) {
            return css`
                display: none;

                @media screen and (${ds.breakpoint.TABLET}) {
                    display: initial;
                }
            `
        }
    }}
`

const ShortcutList = styled.ul`
    display: flex;
    align-items: center;
`

const Wrapper = styled.div`
    align-items: baseline;
    display: flex;
    justify-content: flex-end;

    @media screen and (${ds.breakpoint.TABLET}) {
        position: fixed;
        top: 0;
    }
`

export const Shortcuts = styled(ShortcutsComponent)``
