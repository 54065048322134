import * as graphql from 'graphql-request'
import * as logger from '@bob/logger'

export { GraphQLClient, ClientError } from 'graphql-request'

export type Gql = {
    content: string
    fragments: Set<Fragment>
}

function isGql(value: unknown): value is Gql {
    return (
        typeof value === 'object' &&
        value !== null &&
        'content' in value &&
        'fragments' in value &&
        typeof (value as any).content === 'string' &&
        (value as any).fragments instanceof Set
    )
}

type Fragment = {
    name: string
    gql: Gql
}

function isFragment(value: unknown): value is Fragment {
    return (
        typeof value === 'object' &&
        value !== null &&
        'name' in value &&
        'gql' in value &&
        typeof (value as any).name === 'string' &&
        isGql((value as any).gql)
    )
}

export function gql(chunks: TemplateStringsArray, ...rest: any[]): Gql {
    const fragments = new Set<Fragment>()
    const interpolations = rest.map(interpolation => {
        if (isFragment(interpolation)) {
            fragments.add(interpolation)
            return interpolation.name
        }
        return interpolation
    })

    return {
        content: graphql.gql(chunks, ...interpolations),
        fragments
    }
}

export function fragment(chunks: TemplateStringsArray, ...rest: any[]): Fragment {
    const fragmentGql = gql(chunks, ...rest)
    const match = fragmentGql.content.match(/\sfragment\s+(.*?)\s/)
    if (match === null) {
        throw Error(`${fragmentGql.content} is not a fragment`)
    }
    return {
        name: match[1],
        gql: fragmentGql
    }
}

function resolveFragments(gql: Gql): Set<Fragment> {
    const fragments = new Set<Fragment>()
    const queue = [gql.fragments]
    let currentSet: Set<Fragment> | undefined
    while ((currentSet = queue.pop()) !== undefined) {
        for (const fragment of currentSet) {
            queue.push(fragment.gql.fragments)
            fragments.add(fragment)
        }
    }
    return fragments
}

export type Query<RESULT = any, VARIABLES = Record<string, any>> = string & {
    __result: RESULT
    __variables: VARIABLES
}

export function gqlToQuery<RESULT = any, VARIABLES = Record<string, any>>(
    queryGql: Gql
): Query<RESULT, VARIABLES> {
    const fragments = resolveFragments(queryGql)
    const fullQueryGql = [queryGql.content]
    for (const fragment of fragments) {
        fullQueryGql.push(fragment.gql.content)
    }
    return fullQueryGql.join('\n') as any
}

export function query<RESULT = any, VARIABLES = Record<string, any>>(
    chunks: TemplateStringsArray,
    ...rest: any[]
): Query<RESULT, VARIABLES> {
    const queryGql = gql(chunks, ...rest)
    return gqlToQuery(queryGql)
}

export type Request = <RESULT = any, VARIABLES = Record<string, any>>(
    query: Query<RESULT, VARIABLES>,
    variables: VARIABLES,
    allowRetry?: boolean
) => Promise<RESULT>

export function rawRequester(client: graphql.GraphQLClient): Request {
    return request

    async function request<RESULT = any, VARIABLES = Record<string, any>>(
        query: Query<RESULT, VARIABLES>,
        variables: VARIABLES
    ): Promise<RESULT> {
        let data
        try {
            // Ca me déprime.
            // Je ne comprends pas pourquoi, mais lors du cold-fetch, donc une
            // phase ou on fait beaucoup de requetes d'un coup, il arrive qu'on
            // reçoive une reponse qui n'as rien à voir avec la requete effectuée.
            // Ajouter ce timeout semble regler le problème.
            // Je comprends pas pourquoi et ça me déprime.
            await new Promise(res => setTimeout(res, 1))
            data = await client.request(query, variables)
        } catch (payload) {
            if (!payload) {
                logger.err(`[GraphQL] Unknown request error`, {
                    data,
                    request: { query, variables }
                })
            }
            throw payload
        }
        return data
    }
}
