import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-cross-def'

FullCross.filename = SYMBOL_ID
export function FullCross(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="close">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M10.5859 12.0001L5.29297 17.293L6.70718 18.7072L12.0001 13.4143L17.293 18.7072L18.7072 17.293L13.4143 12.0001L18.7072 6.70718L17.293 5.29297L12.0001 10.5859L6.70718 5.29297L5.29297 6.70718L10.5859 12.0001Z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const Cross = styled(function Cross(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="close">
            <use href={href} />
        </icon.Icon>
    )
})``
