import * as React from 'react'
import styled from 'styled-components'

import * as autocomplete from '../../../behaviours/autocomplete'
import { useLoseFocus, useScrollLock } from '../../../hooks'

import * as S from '../../../styles'
import * as breakpoint from '../../../constants/breakpoints'
import * as icons from '../../../svg/icons'

import { scrollToVisibility } from '../../../utils/scrollToVisibility'

import { SearchInput, SearchInputProps } from './SearchInput'

import { SearchDrawer } from './SearchDrawer'
import { isMobile } from '../../../utils/matchMediaOnBrowser'

export type AutocompleteProps = Omit<SearchInputProps, 'isOpen'> & {
    className?: string
    /** the function returning results for a given query */
    fetch(query: string): Promise<autocomplete.Result[]>
    /** the initial query. Makes the component uncontrolled (incompatible with `query`)
     * @default '' */
    initialQuery?: string

    /** called on Enter key down
     * @default () => {} */
    onEnterKeyDown?: (query: string) => void
    /** called on focus loss
     * @default () => {} */
    onLoseFocus?: () => void
    /** handler called on each query change, with the new query
     * @default () => {} */
    onQueryChange?: (query: string) => void
    /** handler called on each selection change, with the selected result
     * @default () => {} */
    onResultSelect?: (result: autocomplete.Result) => void
    /**
     * the current query. Makes the component controlled (incompatible with `initialQuery`)
     * @default '' */
    query?: string
    /** Component to render results with. Won't be rendered if no results where fetched
     * @default ({ result }) => <>{result.label}</> */
    renderResult?: React.ComponentType<{ result: autocomplete.Result }>
    /** Extra component to display under results. Won't be rendered if no results where fetched
     * @default () => {} */
    renderResultExtra?: React.ComponentType<{ close: () => void; query: string }>
    /** Component to render if no results where fetched.
     * @default () => {} */
    renderNoResult?: React.ComponentType<{ close: () => void }>
    height?: number
}

const AutocompleteComponent = React.forwardRef<HTMLInputElement, AutocompleteProps>(
    function AutocompleteComponent(props, ref) {
        // eslint-disable-next-line no-undef
        if (process.env.NODE_ENV !== 'production') {
            React.useEffect(() => {
                if ('query' in props && 'initialQuery' in props) {
                    console.error(
                        new Error(
                            '<Autocomplete> is both controlled and uncontrolled, use either query or initialQuery'
                        )
                    )
                }
            }, [])
        }

        if ('query' in props) {
            return <ControlledAutocomplete {...props} ref={ref} />
        } else {
            return <UncontrolledAutocomplete {...props} ref={ref} />
        }
    }
)

const UncontrolledAutocomplete = React.forwardRef<HTMLInputElement, AutocompleteProps>(
    function UncontrolledAutocomplete(
        {
            className,
            renderResult,
            fetch,
            onResultSelect = () => {
                // do nothing.
            },
            onEnterKeyDown = () => {
                // do nothing.
            },
            initialQuery = '',
            onLoseFocus = () => {
                // no-op
            },
            onQueryChange = () => {
                // do nothing.
            },
            renderNoResult,
            height,
            renderResultExtra,
            ...inputProps
        }: AutocompleteProps,
        inputRef
    ) {
        const [query, setQuery] = React.useState(initialQuery)

        function handleChange(query: string): void {
            onQueryChange && onQueryChange(query)
            setQuery(query)
        }

        function handleResultSelect(result: autocomplete.Result): void {
            onResultSelect && onResultSelect(result)
            setQuery(result.label)
        }

        return (
            <ControlledAutocomplete
                ref={inputRef}
                className={className}
                fetch={fetch}
                height={height}
                onEnterKeyDown={onEnterKeyDown}
                onLoseFocus={onLoseFocus}
                onResultSelect={handleResultSelect}
                onQueryChange={handleChange}
                query={query}
                renderNoResult={renderNoResult}
                renderResult={renderResult}
                renderResultExtra={renderResultExtra}
                {...inputProps}
            />
        )
    }
)

let currentId = 0
const ids = new WeakMap<any, number>()

const ControlledAutocomplete = React.forwardRef<HTMLInputElement, AutocompleteProps>(
    function ControlledAutocomplete(
        {
            className,
            renderResult: Result = ({ result }) => <>{result.label}</>,
            fetch,
            onResultSelect = () => {
                // do nothing
            },
            onEnterKeyDown = () => {
                // do nothing
            },
            query = '',
            onLoseFocus = () => {
                // no-op
            },
            onQueryChange = () => {
                // do nothing
            },
            height,
            renderNoResult: NoResult = () => <></>,
            renderResultExtra: ResultExtra = () => <></>,
            ...inputProps
        }: AutocompleteProps,
        inputRef
    ) {
        const { isOpen, open, close, results, register } =
            autocomplete.useAutocompleteList(query, onResultSelect, onEnterKeyDown, fetch)

        const autocompleteBoxRef = React.useRef<HTMLDivElement>(null)
        const listBoxRef = React.useRef<HTMLUListElement>(null)

        useLoseFocus(
            autocompleteBoxRef,
            () => {
                isOpen && close()
                if (typeof onLoseFocus === 'function') {
                    onLoseFocus()
                }
            },
            [isOpen]
        )

        useScrollLock(isOpen && isMobile())

        function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
            onQueryChange(event.target.value)
        }

        return (
            <S.form.autocomplete.Wrapper isOpen={isOpen} className={className}>
                <PhantomSearchInput />
                <S.form.autocomplete.Layout ref={autocompleteBoxRef} tabIndex={-1}>
                    <S.form.autocomplete.Close onClick={() => close()}>
                        <icons.ChevronLeft />
                    </S.form.autocomplete.Close>
                    <SearchInput
                        {...inputProps}
                        onChange={handleChange}
                        onClick={() => isMobile() && open()}
                        ref={inputRef}
                        value={query}
                    />
                    <autocomplete.AutocompleteContextProvider value={register}>
                        <S.form.autocomplete.SearchDisplay>
                            <SearchDrawer
                                close={close}
                                height={height}
                                id={String(Math.random())}
                                isOpen={isOpen}
                                register={register}
                                scrollRef={listBoxRef}
                            >
                                {query && results.length === 0 ? (
                                    <NoResult close={close} />
                                ) : (
                                    <>
                                        {results.map((result, i) => {
                                            if (!ids.has(result)) {
                                                ids.set(result, currentId++)
                                                if (
                                                    currentId === Number.MAX_SAFE_INTEGER
                                                ) {
                                                    currentId = 0
                                                }
                                            }
                                            const id = ids.get(result)
                                            return (
                                                <AutocompleteResult
                                                    scrollRef={listBoxRef}
                                                    result={result}
                                                    key={id + ':' + i}
                                                >
                                                    <Result result={result} />
                                                </AutocompleteResult>
                                            )
                                        })}
                                    </>
                                )}
                                {results.length > 0 && (
                                    <ResultExtra close={close} query={query} />
                                )}
                            </SearchDrawer>
                        </S.form.autocomplete.SearchDisplay>
                    </autocomplete.AutocompleteContextProvider>
                </S.form.autocomplete.Layout>
            </S.form.autocomplete.Wrapper>
        )
    }
)

type AutocompleteResultProps = {
    result: autocomplete.Result
    children: React.ReactNode
    scrollRef: React.RefObject<HTMLElement>
}

function AutocompleteResult({
    result,
    scrollRef,
    children
}: AutocompleteResultProps): React.ReactElement<AutocompleteResultProps> {
    const ref = React.useRef<HTMLLIElement>(null)
    const { select, focus, isFocused } = autocomplete.useAutocompleteItem({
        ...result,
        handleFocus: () => {
            scrollToVisibility(scrollRef?.current ?? null, ref.current)
        }
    })

    return (
        <S.form.autocomplete.ResultWrapper
            isFocused={isFocused}
            onFocus={() => focus(result.value)}
            onMouseDown={select}
            onMouseMove={() => focus(result.value)}
            ref={ref}
        >
            {children}
        </S.form.autocomplete.ResultWrapper>
    )
}

export const Autocomplete = styled(AutocompleteComponent)<AutocompleteProps>`
    &::before {
        content: '';
        display: block;
        clear: both;
    }
`

const PhantomSearchInput = styled(SearchInput)`
    visibility: hidden;
    width: 0;
    float: left;

    @media screen and (${breakpoint.TABLET}) {
        display: none;
    }
`
