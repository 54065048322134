import { useRouter } from 'next/router'
import * as React from 'react'
import styled from 'styled-components'

import * as ds from '@bob/design-system'
import { styles as S } from '@bob/design-system'
import * as url from '@bob/url'

import * as algolia from '@bob/algolia-services'

export function Autocomplete({
    onSelect
}: {
    onSelect?: () => void
}): React.ReactElement {
    const router = useRouter()
    const [searchedQuery, setSearchedQuery] = React.useState('')

    return (
        <AnimatedAutocomplete
            fetch={async (query: string) => {
                const data = await algolia.clients.liteClient
                    .initIndex('popular')
                    .search<{ name: string; id: string; handle: string }>(query, {
                        attributesToHighlight: [],
                        attributesToRetrieve: ['name', 'id', 'handle'],
                        distinct: true,
                        filters: 'availableForSale:true AND NOT tags:hidden',
                        hitsPerPage: 6
                    })

                const results = data?.hits.map(hit => ({
                    label: hit.name ?? '',
                    value: { handle: hit.handle, id: hit.id }
                }))

                return results ?? []
            }}
            id="algolia-autocomplete"
            placeholder="Ex : sac à dos, chaussures, déodorant..."
            onEnterKeyDown={query => {
                if (query.length > 1) {
                    if (onSelect) {
                        onSelect()
                    }
                    router.push({
                        pathname: url.products({ title: 'Recherche' }).path,
                        query: {
                            q: query
                        }
                    })
                }
            }}
            onLoseFocus={() => {
                setSearchedQuery('')
            }}
            onQueryChange={query => {
                setSearchedQuery(query)
            }}
            onResultSelect={({ value }) => {
                if (onSelect) {
                    onSelect()
                }
                router.push({
                    pathname: url.product({
                        title: '',
                        handle: value.handle,
                        id: value.id
                    }).path
                })
            }}
            query={searchedQuery}
            renderNoResult={ds.NoResults}
            renderResult={({ result: { label } }) => (
                <ds.SearchResult
                    result={{
                        label,
                        value: <span dangerouslySetInnerHTML={{ __html: label ?? '' }} />
                    }}
                />
            )}
            renderResultExtra={({ query, close }) => (
                <ds.MoreResults
                    query={query}
                    onClick={() => {
                        if (onSelect) {
                            onSelect()
                        }
                        close()
                        router.push({
                            pathname: url.products({ title: 'Recherche' }).path,
                            query: {
                                q: query
                            }
                        })
                    }}
                />
            )}
        />
    )
}

const AnimatedAutocomplete = styled(ds.Autocomplete)`
    @media screen and (${ds.breakpoint.TABLET}) {
        display: flex;
        justify-content: flex-end;

        transition: border 0.2s ease-in-out, width 0.2s ease-in-out;
        width: 56px;
        min-height: 56px;
        height: auto;

        &:active,
        &:focus-within,
        &:hover {
            width: 270px;

            ${S.form.input.TextInput} {
                width: 100%;
                border-color: initial;

                &::placeholder {
                    color: initial;
                }
            }

            ${S.form.input.Button} {
                border-color: initial;
            }
        }

        &:not(:focus-within) {
            /* NOTE: Avoid content flashing if search bar is unfocused before query finishes dispatching.
            (in which case, you would see the result list appearing for an instant) */

            ${S.form.autocomplete.SearchDisplay} {
                visibility: hidden;
            }
        }

        ${S.form.input.BaseInputStyle} {
            display: flex;
            justify-content: flex-end;
        }

        ${S.form.input.InputWrapper} {
            width: 100%;
        }

        ${S.form.input.TextInput} {
            border-color: transparent;
            transition: border 0.2s ease-in-out;

            &::placeholder {
                color: transparent;
                transition: 0.2s ease-in-out;
            }
        }

        ${S.form.input.Button} {
            border-color: transparent;
            transition: border 0.2s ease-in-out;
            height: 60px;
            width: 60px;
        }

        ${ds.icons.Search} {
            height: 30px;
            width: 30px;
        }
    }
`
