import { useRouter } from 'next/router'
import * as utils from '@bob/utils'

const TRACKED_UTM = ['utm_campaign', 'utm_medium', 'utm_source', 'gclid'] as const

const LOCAL_STORAGE_UTM_KEY = 'bob_marketplace_utms'

type UTMDict = {
    [k in typeof TRACKED_UTM[number]]?: string
}

type StoredUTM = {
    timestamp: number
    dict: UTMDict
}

export function useUTMCatcher(): void {
    const router = useRouter()

    if (!utils.browser.IS_IN_BROWSER) {
        return
    }

    const utmDict: UTMDict = {}

    for (const utm of TRACKED_UTM) {
        const utmValue = router.query[utm]
        if (typeof utmValue === 'string') {
            utmDict[utm] = utmValue
        }
    }

    if (Object.keys(utmDict).length !== 0) {
        const storedUtm: StoredUTM = { timestamp: Date.now(), dict: utmDict }
        window.localStorage.setItem(LOCAL_STORAGE_UTM_KEY, JSON.stringify(storedUtm))
    }
}

export function getStoredUTM(): UTMDict {
    const storedUtmString = window.localStorage.getItem(LOCAL_STORAGE_UTM_KEY)
    if (storedUtmString === null) {
        return {}
    }

    const storedUtm: StoredUTM = JSON.parse(storedUtmString)

    if (storedUtm.timestamp + 1000 * 60 * 60 * 24 * 30 /* a month */ < Date.now()) {
        window.localStorage.removeItem(LOCAL_STORAGE_UTM_KEY)
        return {}
    }

    return storedUtm.dict
}
