import styled, { keyframes } from 'styled-components'

export type SvgProps = {
    size?: number
    color?: string
}

const LoaderRotate = keyframes`
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
`

export const Svg = styled.svg<SvgProps>`
    width: 1em;
    height: 1em;
    font-size: ${props => (props.size ? `${props.size}px` : '1em')};
    color: ${props => (props.color ? props.color : 'inherit')};
    animation: 2s linear infinite ${LoaderRotate};
`

const CircleRotate = keyframes`
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }

    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }

    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
`

export const Circle = styled.circle`
    display: block;
    fill: transparent;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
    animation: 1.4s ease-in-out infinite both ${CircleRotate};
`
