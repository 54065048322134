import { ApiResponse } from '@bob/api-client'
import * as api from '@bob/api-client/latest'

import * as entity from '@bob/entities'

export async function getShippingRates(
    country: string,
    items: {
        quantity: number
        shopifyVariantId: number
    }[],
    vendors: string[]
): Promise<ApiResponse<entity.shipping.ShippingRateData>> {
    const response = await api.api.post.shippingRates({
        body: {
            country,
            items: items.map(item => ({
                quantity: item.quantity,
                shopify_variant_id: item.shopifyVariantId
            }))
        }
    })

    return response.next(response => {
        return entity.shipping.shippingRateData(response, vendors)
    })
}
