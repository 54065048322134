import { Result } from './types'

type State = {
    isOpen: boolean
    isLoading: boolean
    currentQuery: string
    focused?: any
    results: Result[]
}

type FocusAction = { type: 'focus'; focused: any }
type QueryAction = { type: 'query'; query: string }
type ResultAction = { type: 'result'; for: string; results: Result[] }
type CloseAction = { type: 'close'; result?: Result }
type OpenAction = { type: 'open' }
type Action = ResultAction | FocusAction | CloseAction | QueryAction | OpenAction

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'focus': {
            return { ...state, focused: action.focused }
        }
        case 'result': {
            if (action.for !== state.currentQuery || !state.isOpen) {
                return state
            }
            return {
                ...state,
                results: action.results,
                isLoading: false,
                focused: undefined
            }
        }
        case 'query': {
            if (action.query === state.currentQuery) {
                return state
            }
            return {
                ...state,
                currentQuery: action.query,
                focused: undefined,
                isLoading: true,
                isOpen: true
            }
        }
        case 'close': {
            return {
                ...state,
                focused: undefined,
                isOpen: false,
                currentQuery: action.result?.label ?? state.currentQuery,
                results: []
            }
        }
        case 'open': {
            return {
                ...state,
                focused: undefined,
                isOpen: true
            }
        }
        default: {
            return state
        }
    }
}
