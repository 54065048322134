import styled from 'styled-components'
import * as S from '../../../styles'
import * as icons from '../../../svg/icons'

import * as breakpoint from '../../../constants/breakpoints'

import * as autocomplete from '../../../behaviours/autocomplete'

import { LinkButton, LinkButtonProps } from '../../LinkButton'

export function NoResults(): React.ReactElement {
    return <S.form.autocomplete.NoResult>Pas de résultats</S.form.autocomplete.NoResult>
}

type Props = {
    result: autocomplete.Result
}
export type SearchHistoryProps = Props
export type SearchResultProps = Props

export function SearchHistory({ result }: SearchHistoryProps): React.ReactElement {
    return (
        <S.form.autocomplete.History>
            <icons.Clock size="20" />
            <S.form.autocomplete.HistoryText>
                {result.label}
            </S.form.autocomplete.HistoryText>
            <icons.Trash size="20" />
        </S.form.autocomplete.History>
    )
}

export function SearchResult({ result }: SearchResultProps): React.ReactElement {
    return <S.form.autocomplete.Result>{result.value}</S.form.autocomplete.Result>
}

const StyledLinkButton = styled.li`
    margin-top: 20px;
    padding: 15px;
    width: 100%;

    @media screen and (${breakpoint.TABLET}) {
        margin-top: 0;
    }

    ${LinkButton} {
        cursor: pointer;
    }
`

export function MoreResults({
    query,
    ...rest
}: { query: string } & LinkButtonProps): React.ReactElement {
    return (
        <StyledLinkButton>
            <LinkButton {...rest}>Plus de résultats pour {query}</LinkButton>
        </StyledLinkButton>
    )
}
