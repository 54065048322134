import * as React from 'react'
import * as size from '../constants/sizes'
import { isMobile } from '../utils/matchMediaOnBrowser'

type Options = { className?: string }

export function useScrollLock(
    lock: boolean,
    { className = 'lock-scroll' }: Options = {}
): void {
    React.useEffect(() => {
        const html = document.body.parentElement!

        const scrollTop = html.scrollTop

        document.body.classList.toggle(className, lock)

        // If we lock, the body will change to `position: fixed`, to effectively
        // remove all scrolling possibility to the user while keeping an empty
        // scroll bar
        // To keep the page scrolled to the same point, we use `scrollTop` (minus
        // the height of the header) as a `top`
        if (lock) {
            if (!isMobile()) {
                document.body.style.top = `calc(-${scrollTop}px + ${size.HEADER_DESKTOP_HEIGHT})`
            }
        }

        return () => {
            if (lock) {
                // when we unlock, we restore the scroll. But since we have a
                // `scroll-behaviour: smooth`, we must override this before
                // setting the scroll position (to have it set immediatly, without
                // smooth animation)
                // We remove the `scroll-behaviour` override juste after having
                // set the `scrollTop`
                document.body.classList.remove(className)
                document.body.removeAttribute('style')
                html.style.scrollBehavior = 'initial'
                html.scrollTop = scrollTop
                html.removeAttribute('style')
            }
        }
    }, [lock])
}
