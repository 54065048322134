import styled, { css } from 'styled-components'

import { hexToRgb } from '../../utils/colors'
import * as button from '../button'
import * as color from '../../constants/colors'
import * as copy from '../copy'

export const TextInput = styled.input`
    font-size: 16px;
`

export const Button = styled(button.UnstyledButton)`
    border-radius: 0 12px 12px 0;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-top: 1px solid;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: ${hexToRgb(color.GRAY_3, 0.05)};
        transition: background 0.3s ease-in-out;
    }
`

export const BaseInputStyle = styled.div`
    display: flex;

    ${TextInput} {
        -webkit-appearance: none;
        display: block;
        padding: 15px;
        width: 100%;
        background-color: ${color.SAND_1};
        border: 1px solid ${color.GRAY_3};
        border-radius: 12px;
        color: ${color.GRAY_3};
        transition: border-color 0.3s ease-in-out, background 0.3s ease-in-out;

        &:focus {
            outline: none;
        }

        &::placeholder {
            font-style: italic;
            font-weight: 300;
            color: ${color.GRAY_3};
        }
    }
`

export type Status = 'error' | 'success' | 'default'
export type InputStyleProps = {
    $disabled?: boolean
    status?: Status
    haveButton?: boolean
}

const smallStyles = css`
    ${TextInput} {
        padding: 15px;
        min-height: 50px;
    }

    ${Button} {
        padding: 15px;
        min-height: 50px;
    }
`

const bigStyles = css`
    ${TextInput} {
        padding: 20px 15px;
        min-height: 60px;
    }

    ${Button} {
        padding: 20px 15px;
        min-height: 60px;
    }
`

export const InputStyle = styled(BaseInputStyle)<InputStyleProps>`
    ${({ haveButton }) => {
        return (
            haveButton &&
            css`
                ${TextInput} {
                    border-radius: 12px 0 0 12px;
                    border-right: none;
                }
            `
        )
    }}

    ${({ $disabled }) => {
        return (
            $disabled &&
            css`
                opacity: 0.5;
            `
        )
    }}

    ${({ status }) => {
        return (
            status === 'error' &&
            css`
                ${TextInput} {
                    border-color: ${color.ERROR};
                }

                ${Button} {
                    border-color: ${color.ERROR};
                    pointer-events: none;
                }
            `
        )
    }}
`

export type InputWrapperProps = {
    small?: boolean
}

export const InputWrapper = styled.div<InputWrapperProps>`
    ${({ small }) => (small ? smallStyles : bigStyles)};

    ${copy.TextInfo} {
        color: ${color.ERROR};
        margin-top: 2px;
        text-align: right;
    }
`

export const Count = styled(copy.TextTag)<{ isError: boolean }>`
    ${({ isError }) => {
        return (
            isError &&
            css`
                color: ${color.ERROR};
            `
        )
    }}
`
