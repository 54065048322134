import { fragment } from '@bob/utils/graphql'

export type Image = {
    altText: string | null
    height: number | null
    id: string
    originalSrc: string
    width: number | null
}

export const IMAGE_FRAGMENT = fragment`
    fragment BaseImage on Image {
        altText
        height
        id
        originalSrc
        width
    }
`

export type Money = {
    amount: string
    currencyCode: string
}

export const MONEY_FRAGMENT = fragment`
    fragment BaseMoney on MoneyV2 {
        amount
        currencyCode
    }
`

export type LineItem = {
    quantity: number
    variantId: string
    customAttributes?: { key: string; value: string }[]
}

export type MailingAddressInput = {
    city?: string
    zip?: string
    countryCode?: string
    country?: string
}

export type ShippingRate = {
    handle: string
    title: string
    priceV2: Money
}

export const SHIPPING_RATE_FRAGMENT = fragment`
    fragment BaseShippingRate on ShippingRate {
        handle
        title
        priceV2 {
            ...${MONEY_FRAGMENT}
        }
    }
`

export function shopifyGID(id: string): string {
    return Buffer.from(`gid://shopify/Product/${id}`).toString('base64')
}

export function shopifyNumericId(id: string, type: 'Product' | 'ProductVariant'): number {
    return Number(
        Buffer.from(id, 'base64').toString().replace(`gid://shopify/${type}/`, '')
    )
}
