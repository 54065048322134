import { requester, RequestFailure } from './src/requester'
import { Either } from '@bob/monad'
import { VERCEL_URL } from '@bob/utils'

export const request = requester({
    cookieNames: {
        accessToken: process.env.NEXT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME ?? '',
        refreshToken: process.env.NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_NAME ?? ''
    },
    endpoints: {
        refreshToken:
            VERCEL_URL && process.env.NEXT_PUBLIC_REFRESH_TOKEN_ENDPOINT
                ? VERCEL_URL + process.env.NEXT_PUBLIC_REFRESH_TOKEN_ENDPOINT
                : ''
    }
})

export * from './src/requester'

export type ApiResponse<TYPE> = Either<RequestFailure, TYPE>
