import Link from 'next/link'
import styled from 'styled-components'

import { tags, taxonomies } from '@bob/cold-data/client'
import * as contentful from '@bob/contentful-services'
import * as ds from '@bob/design-system'
import * as url from '@bob/url'
import { blob, resetBlob } from '@bob/utils/blob'

type MomentLinksProps = {
    onSelect?: () => void
}

function MomentLinksComponent({
    onSelect = () => {
        // no-op
    }
}: MomentLinksProps): React.ReactElement<MomentLinksProps> {
    resetBlob()

    const moments = taxonomies['moments']

    const momentList = Object.values(tags).reduce((momentAccumulator: Tag[], tag) => {
        if (
            moments.children.tags.includes(tag.slug) &&
            tag.extra !== null &&
            tag.extra.type === 'moment'
        ) {
            momentAccumulator.push({
                extra: tag.extra,
                name: tag.name,
                slug: tag.slug
            })
        }
        return momentAccumulator
    }, [])

    const sortedMomentList = momentList.sort((tagA, tagB) => {
        if (tagA.extra.order === null && tagB.extra.order === null) {
            return 0
        }

        if (tagA.extra.order === null) {
            return 1
        }

        if (tagB.extra.order === null) {
            return -1
        }

        return Number(tagA.extra.order) - Number(tagB.extra.order)
    })

    return (
        <ds.MomentGrid>
            {sortedMomentList.map(moment => {
                const illustration = momentToIllustration(moment.extra.icon)

                return (
                    <Link
                        key={moment.slug}
                        prefetch={false}
                        href={`${url.products({ title: '' }).path}?moments=${
                            moment.slug
                        }`}
                    >
                        <a>
                            <ds.MomentLink
                                blob={blob()}
                                illustration={illustration}
                                onClick={onSelect}
                                title={moment.name}
                            />
                        </a>
                    </Link>
                )
            })}
        </ds.MomentGrid>
    )
}

type Tag = {
    name: string
    slug: string
    extra: {
        icon: contentful.base.MomentIcon
        order: number | null
        timeStart?: string | null
        timeEnd?: string | null
    }
}

function momentToIllustration(iconName: string): React.ReactElement | undefined {
    switch (iconName) {
        case 'cooking': {
            return <ds.illustrations.moments.Cooking />
        }
        case 'decorating': {
            return <ds.illustrations.moments.Decorating />
        }
        case 'doing-housework': {
            return <ds.illustrations.moments.DoingHousework />
        }
        case 'doing-sport': {
            return <ds.illustrations.moments.DoingSport />
        }
        case 'dressing': {
            return <ds.illustrations.moments.Dressing />
        }
        case 'eating': {
            return <ds.illustrations.moments.Eating />
        }
        case 'educating-onself': {
            return <ds.illustrations.moments.EducatingOneself />
        }
        case 'entertaining-oneself': {
            return <ds.illustrations.moments.EntertainingOneself />
        }
        case 'going-on-holiday': {
            return <ds.illustrations.moments.GoingOnHoliday />
        }
        case 'going-to-bed': {
            return <ds.illustrations.moments.GoingToBed />
        }
        case 'relaxing': {
            return <ds.illustrations.moments.Relaxing />
        }
        case 'snacking': {
            return <ds.illustrations.moments.Snacking />
        }
        case 'taking-an-aperitif': {
            return <ds.illustrations.moments.TakingAnAperitif />
        }
        case 'taking-breakfast': {
            return <ds.illustrations.moments.TakingBreakfast />
        }
        case 'taking-care-of-children': {
            return <ds.illustrations.moments.TakingCareOfChildren />
        }
        case 'taking-care-of-oneself': {
            return <ds.illustrations.moments.TakingCareOfOneself />
        }
        case 'taking-care-of-pets': {
            return <ds.illustrations.moments.TakingCareOfPets />
        }
        case 'travelling': {
            return <ds.illustrations.moments.Travelling />
        }
        case 'washing': {
            return <ds.illustrations.moments.Washing />
        }
        case 'working': {
            return <ds.illustrations.moments.Working />
        }
    }
}

export const Moments = styled(MomentLinksComponent)``
