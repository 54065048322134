import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-arrow-right-def'

FullArrowRight.filename = SYMBOL_ID
export function FullArrowRight(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="arrow-right">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M16.586 11l-5.293-5.293 1.414-1.414L20.414 12l-7.707 7.707-1.414-1.414L16.586 13H5v-2h11.586z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const ArrowRight = styled(function ArrowRight(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="arrow-right">
            <use href={href} />
        </icon.Icon>
    )
})``
