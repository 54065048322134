import styled from 'styled-components'
import * as React from 'react'
import * as blob from '../svg/blob'
import * as S from '../styles'
import * as color from '../constants/colors'

export const BLOB_COLORS = {
    yellow: color.YELLOW,
    pink: color.PINK,
    orange: color.ORANGE,
    blue: color.BLUE,
    'light-green': color.LIGHT_GREEN,
    'pine-green': color.PINE_GREEN_2
}

export const BLOB_VARIANTS = {
    1: blob.full.FullBlob1,
    2: blob.full.FullBlob2,
    3: blob.full.FullBlob3,
    4: blob.full.FullBlob4,
    5: blob.full.FullBlob5,
    6: blob.full.FullBlob6
}

export type BaseBlobBackgroundProps = {
    color?: keyof typeof BLOB_COLORS
    variant?: keyof typeof BLOB_VARIANTS
    marginLeft?: S.blobBackground.WrapperProps['$marginLeft']
    marginRight?: S.blobBackground.WrapperProps['$marginRight']
    marginTop?: S.blobBackground.WrapperProps['$marginTop']
    marginBottom?: S.blobBackground.WrapperProps['$marginBottom']
}

export type BlobBackgroundProps = BaseBlobBackgroundProps &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>

function BlobBackgroundComponent({
    variant = 1,
    color = 'yellow',
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    ...blobProps
}: BlobBackgroundProps) {
    return (
        <S.blobBackground.Wrapper
            $blob={BLOB_VARIANTS[variant]}
            $color={BLOB_COLORS[color]}
            $marginLeft={marginLeft}
            $marginRight={marginRight}
            $marginTop={marginTop}
            $marginBottom={marginBottom}
            {...blobProps}
        />
    )
}

export const BlobBackground = styled(BlobBackgroundComponent)``
