import { useLocalStorageContext } from './localStorageContext'

type useLocalStorage<VALUE> =
    | { ready: false; value: null }
    | {
          ready: true
          value: VALUE | null
          deleteValue: () => void
          setValue: (value: VALUE) => void
      }

export function useLocalStorage<VALUE>(key: string): useLocalStorage<VALUE> {
    const { storage, setStorage } = useLocalStorageContext()

    if (storage === undefined) {
        return { ready: false, value: null }
    }

    const value = getValue()

    return { ready: true, value, setValue, deleteValue }

    function setValue(value: VALUE) {
        setStorage(storage => {
            if (storage === undefined) {
                console.error("can't set value before local storage is ready")
                return undefined
            }

            try {
                const stringValue = JSON.stringify(value)
                window.localStorage.setItem(key, stringValue)
                return { ...storage, [key]: stringValue }
            } catch (error) {
                console.log(error)
                return storage
            }
        })
    }

    function deleteValue() {
        setStorage(storage => {
            if (storage === undefined) {
                console.error("can't delete value before local storage is ready")
                return undefined
            }

            try {
                const { [key]: _toRemove, ...nextStorage } = storage
                window.localStorage.removeItem(key)
                return nextStorage
            } catch (error) {
                console.error(error)
                return storage
            }
        })
    }

    function getValue(): VALUE | null {
        if (storage === undefined) {
            console.error("can't get value before local storage is ready")
            return null
        }

        try {
            const item = storage[key]

            return typeof item === 'string' ? JSON.parse(item) : null
        } catch (error) {
            console.error(error)
            return null
        }
    }
}
