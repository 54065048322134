import styled from 'styled-components'

import { InlineSvg } from './InlineSvg'

export const SizedSvg = styled(InlineSvg)<{
    $size: number | string
}>`
    height: ${({ $size }) => (Number.isInteger($size) ? `${$size}px` : $size)};
    vertical-align: middle;
    width: ${({ $size }) => (Number.isInteger($size) ? `${$size}px` : $size)};
`
