import * as React from 'react'
import styled from 'styled-components'

import * as transition from '../behaviours/transition'
import { useTransition } from '../hooks'
import { Overlay } from './Overlay'

import * as icons from '../svg/icons'
import * as S from '../styles'

export type ModalProps = {
    children: React.ReactNode
    open: boolean
    title?: string
} & (
    | {
          closable: true
          onClose: () => void
      }
    | {
          closable?: false
          onClose?: undefined
      }
) &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'>

function ModalComponent({
    children,
    closable,
    onClose,
    open,
    title,
    ...divProps
}: ModalProps): React.ReactElement<ModalProps> | null {
    const modalRef = React.useRef<HTMLDivElement>(null)
    const { step, unmounted, onTransitionEnd } = useTransition(open)

    function handleOverlayClick(event: React.MouseEvent<HTMLDivElement>): void {
        if (modalRef.current === null) {
            return
        }
        if (closable && !modalRef.current.contains(event.target as Node)) {
            onClose && onClose()
        }
    }

    if (unmounted) {
        return null
    }

    return (
        <Overlay onClick={handleOverlayClick} {...divProps}>
            <Wrapper onTransitionEnd={onTransitionEnd} step={step} ref={modalRef}>
                {(closable || title) && (
                    <S.modal.ModalHeader>
                        {title && <S.copy.Text2>{title}</S.copy.Text2>}
                        {closable && (
                            <icons.Cross
                                onClick={
                                    onClose
                                        ? onClose
                                        : () => {
                                              return
                                          }
                                }
                            />
                        )}
                    </S.modal.ModalHeader>
                )}
                <S.modal.ModalBody>
                    <S.modal.ModalScrollableContainer>
                        {children}
                    </S.modal.ModalScrollableContainer>
                </S.modal.ModalBody>
            </Wrapper>
        </Overlay>
    )
}

export const Modal = styled(ModalComponent)``

const Wrapper = transition.fromBottom(S.modal.ModalWrapper)
