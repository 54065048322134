import * as entity from '@bob/entities'

export function getEstimatedTimeOfArrivalLabel(
    type: entity.shipping.ShippingType
): string | undefined {
    if (type === 'Normal') {
        return '3 à 8 jours ouvrés.'
    } else if (type === 'Express') {
        return '1 à 2 jours ouvrés.'
    } else if (type === 'Premium') {
        return '1 à 2 jours ouvrés avec signature.'
    }
}

export function getDeliveryWarningMessage(
    deliveryExtra: string | null,
    productionDays: number | null
): string | null {
    if (deliveryExtra) return deliveryExtra

    if (productionDays)
        return `Ce produit nécessite un délai de préparation de ${productionDays} ${
            productionDays > 1 ? 'jours' : 'jour'
        }.`

    return null
}
