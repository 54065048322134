import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as blob from '../Blob'

const SYMBOL_ID = 'blob-5-def'

FullBlob5.filename = SYMBOL_ID
export function FullBlob5(props: blob.SpecificIconProps): React.ReactElement {
    return (
        <blob.Blob {...props} viewBox="0 0 92 99" name="blob-5">
            <defs>
                <path
                    id={SYMBOL_ID}
                    d="m71.869 23.172c10.83 11.741 21.148 24.266 20.05 36.79-1.1709 12.459-13.757 24.918-29.783 32.289-16.026 7.3057-35.418 9.524-46.541 2.4135-11.123-7.0449-13.83-23.548-15.074-37.572-1.1708-14.09-0.80495-25.766 5.7078-36.659 6.5859-10.828 19.246-20.874 31.32-20.417 12.074 0.45661 23.417 11.415 34.32 23.157z"
                />
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </blob.Blob>
    )
}

export const Blob5 = styled(function Blob5(
    props: blob.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <blob.Blob {...props} viewBox="0 0 92 99" name="blob-5">
            <use href={href} />
        </blob.Blob>
    )
})``
