import * as logger from '@bob/logger'
import * as shopify from '@bob/shopify-services'
import { getMatchingBrand } from '@bob/contentful-shopify-matcher'

import * as base from '../base'
import * as brand from '../brand'
import * as cold from '../cold'

export type LineItem = {
    discount: base.Price[]
    customAttributes: { key: string; value: string }[]
    id: string
    quantity: number
    totalVariantQuantity: number
    variant: Variant
}

export type PartialLineItem = {
    quantity: number
    variantId: Variant['id']
    customAttributes?: { key: string; value: string }[]
}

export type CustomAttribute = {
    key: string
    value: string
}

export type Vendor = {
    brand: brand.ColdBrand
    lineItems: LineItem[]
}

export type Checkout = {
    completedAt: string
    customAttributes: { key: string; value: string }[]
    discountCodes: (
        | { kind: 'automatic'; title: string }
        | { kind: 'code' }
        | { kind: 'manual' }
        | { kind: 'script' }
    )[]
    id: string
    webUrl: string
    vendors: Vendor[]
}

export type Variant = {
    id: string
    compareAtPrice: base.Price | null
    price: base.Price
    image: base.Image
    parent?: {
        id: number
        title: string
        image: base.Image
    }
    product: {
        deliveryExtra: {
            value: string | null
        } | null
        id: string
        handle: string
        title: string
        vendor: string
        offline: boolean
        productionDays: {
            value: number | null
        } | null
    }
    quantityAvailable: number
    title: string
}

export type CheckoutError = {
    code: string
    field: string
    message: string
}

export function checkout(
    checkout: shopify.checkout.Checkout,
    cold: { brands: cold.SlimColdData['brands'] }
): Checkout {
    const groups: { [s: string]: Vendor } = {}
    for (const { node } of checkout.lineItems.edges) {
        const vendor = node.variant.product.vendor
        const brand = getMatchingBrand(vendor, cold)
        groups[vendor] = groups[vendor] || { brand, lineItems: [] }

        groups[vendor].lineItems.push({
            discount: node.discountAllocations.map(discount => ({
                amount: Number(discount.allocatedAmount.amount),
                currencyCode: discount.allocatedAmount.currencyCode
            })),
            customAttributes: node.customAttributes,
            id: node.id,
            quantity: node.quantity,
            totalVariantQuantity: 0,
            variant: {
                ...node.variant,
                image: {
                    origin: node.variant.image.originalSrc,
                    src:
                        node.variant.image.transformedSrc1x ??
                        node.variant.image.originalSrc,
                    srcSet: `${node.variant.image.transformedSrc1x} 1x, ${node.variant.image.transformedSrc2x} 2x, ${node.variant.image.transformedSrc3x} 3x`,
                    credit: null,
                    alt: node.variant.image.altText,
                    height: node.variant.image.height,
                    width: node.variant.image.width
                },
                price: {
                    amount: Number(node.variant.priceV2.amount),
                    currencyCode: node.variant.priceV2.currencyCode
                },
                compareAtPrice: getCompareAtPrice(node.variant),
                product: {
                    ...node.variant.product,
                    offline:
                        node.variant.product.tags.includes('offline') ||
                        !node.variant.product.tags.includes('online')
                }
            }
        })
    }

    const vendors = Array.from(Object.values(groups))
    for (const vendor of vendors) {
        const quantities: { [s: string]: number } = {}
        for (const lineItem of vendor.lineItems) {
            quantities[lineItem.variant.id] =
                (quantities[lineItem.variant.id] || 0) + lineItem.quantity
        }

        for (const lineItem of vendor.lineItems) {
            lineItem.totalVariantQuantity = quantities[lineItem.variant.id]
        }
    }

    const groupedCheckout: Checkout = {
        completedAt: checkout.completedAt,
        id: checkout.id,
        webUrl: checkout.webUrl,
        customAttributes: checkout.customAttributes,
        discountCodes: checkout.discountApplications.edges.map(({ node }) => {
            switch (node.__typename) {
                case 'AutomaticDiscountApplication': {
                    return { kind: 'automatic', title: node.title }
                }
                case 'DiscountCodeApplication': {
                    return { kind: 'code' }
                }
                case 'ManualDiscountApplication': {
                    return { kind: 'manual' }
                }
                case 'ScriptDiscountApplication': {
                    return { kind: 'script' }
                }
            }
        }),
        vendors
    }

    return groupedCheckout
}

function getCompareAtPrice(
    variant: shopify.checkout.CheckoutLineItem['variant']
): base.Price | null {
    if (!variant.compareAtPriceV2) return null

    const amount = parseFloat(variant.priceV2.amount)
    const compareAtPriceAmount = parseFloat(variant.compareAtPriceV2.amount)
    if (amount >= compareAtPriceAmount) {
        // if (amount !== compareAtPriceAmount) {
        //     logger.warn(
        //         `[Shopify] - Checkout: 'price' is higher than its 'compareAtPrice' for variant ${variant.id}`,
        //         {
        //             variant,
        //             values: {
        //                 price: amount,
        //                 compareAtPrice: compareAtPriceAmount
        //             }
        //         }
        //     )
        // }
        return null
    }

    return {
        amount: compareAtPriceAmount,
        currencyCode: variant.compareAtPriceV2.currencyCode
    }
}

export function getTotalDiscount(item: LineItem): number {
    return item.discount.reduce((total, discount) => discount.amount + total, 0)
}

export function getPrice(item: LineItem): number {
    const quantity = item.quantity
    const variantPrice = item.variant.price.amount
    const variantCompareAtPrice = item.variant.compareAtPrice?.amount

    return variantCompareAtPrice
        ? variantCompareAtPrice * quantity
        : variantPrice * quantity
}

export function getDiscountedPrice(
    item: LineItem,
    price: number,
    totalDiscount: number
): number | undefined {
    const quantity = item.quantity
    const variantPrice = item.variant.price.amount
    const variantCompareAtPrice = item.variant.compareAtPrice?.amount

    return variantCompareAtPrice
        ? totalDiscount === 0
            ? variantPrice * quantity
            : variantPrice * quantity - totalDiscount
        : totalDiscount === 0
        ? undefined
        : price - totalDiscount
}
