/* eslint-disable react/display-name */

import * as React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import * as richtext from '@contentful/rich-text-types'
import { styles as S } from '@bob/design-system'
import * as contentful from '@bob/contentful-services'

import { Hyperlink as BaseHyperlink } from './Hyperlink'

/*type Asset = {
    sys: { id: string }
    url: string
    width: number
    height: number
    title: string
    description: string
}*/

/*type Illustration = {
    __typename: 'Illustration'
    sys: { id: string }
    image: {
        url: string
        width: number
        height: number
        title: string
        description: string
    }
    align: 'none' | 'left' | 'right'
}

type Entry = Illustration*/

export type RichTextProps<ENTRY extends { sys: { id: string } }> = {
    document: richtext.Document
    links?: {
        assets?: {
            block: contentful.base.Asset[]
        }
        entries?: {
            block: ENTRY[]
        }
    }
    renderEntry?: (entry: ENTRY) => React.ReactNode
    renderMark?: {
        Bold?: React.ComponentType
        Italic?: React.ComponentType
        Underline?: React.ComponentType
    }
    renderNode?: {
        Paragraph?: React.ComponentType
        Title?: React.ComponentType
        UnorderedList?: React.ComponentType
        OrderedList?: React.ComponentType
        ListItem?: React.ComponentType
        BlockQuote?: React.ComponentType
        Hyperlink?: (props: {
            uri: string
            children: React.ReactNode
        }) => React.ReactElement
        EmbeddedAsset?: (props: { asset: contentful.base.Asset }) => React.ReactElement
    }
}

export function RichText<ENTRY extends { sys: { id: string } }>({
    document,
    links,
    renderEntry,
    renderMark: {
        Bold = S.wysiwyg.Bold,
        Italic = S.wysiwyg.Italic,
        Underline = S.wysiwyg.Underline
    } = {},
    renderNode: {
        Paragraph = S.wysiwyg.Paragraph,
        Title = S.wysiwyg.Title,
        UnorderedList = S.wysiwyg.UnorderedList,
        OrderedList = S.wysiwyg.OrderedList,
        ListItem = S.wysiwyg.ListItem,
        BlockQuote = S.wysiwyg.BlockQuote,
        Hyperlink = BaseHyperlink,
        EmbeddedAsset
    } = {}
}: RichTextProps<ENTRY>): React.ReactElement<RichTextProps<ENTRY>> {
    const blockAssetMap = new Map<string, contentful.base.Asset>()
    const blockEntryMap = new Map<string, ENTRY>()

    for (const asset of links?.assets?.block || []) {
        blockAssetMap.set(asset.sys.id, asset)
    }
    for (const entry of links?.entries?.block || []) {
        blockEntryMap.set(entry.sys.id, entry)
    }

    return (
        <>
            {documentToReactComponents(document, {
                renderMark: {
                    [richtext.MARKS.BOLD]: text => {
                        return <Bold>{text}</Bold>
                    },
                    [richtext.MARKS.ITALIC]: text => {
                        return <Italic>{text}</Italic>
                    },
                    [richtext.MARKS.UNDERLINE]: text => {
                        return <Underline>{text}</Underline>
                    }
                },
                renderNode: {
                    [richtext.BLOCKS.PARAGRAPH]: (node, children) => {
                        return <Paragraph>{children}</Paragraph>
                    },
                    [richtext.BLOCKS.HEADING_1]: (node, children) => {
                        return <Title>{children}</Title>
                    },
                    [richtext.BLOCKS.UL_LIST]: (node, children) => {
                        return <UnorderedList>{children}</UnorderedList>
                    },
                    [richtext.BLOCKS.OL_LIST]: (node, children) => {
                        return <OrderedList>{children}</OrderedList>
                    },
                    [richtext.BLOCKS.LIST_ITEM]: (node, children) => {
                        return <ListItem>{children}</ListItem>
                    },
                    [richtext.BLOCKS.QUOTE]: (node, children) => {
                        return <BlockQuote>{children}</BlockQuote>
                    },
                    [richtext.INLINES.HYPERLINK]: (node, children) => {
                        return <Hyperlink uri={node.data.uri}>{children}</Hyperlink>
                    },
                    [richtext.BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                        const asset = blockAssetMap.get(node.data.target.sys.id)
                        if (asset === undefined) {
                            return <></>
                        }
                        if (EmbeddedAsset) {
                            return <EmbeddedAsset asset={asset} />
                        }
                        return (
                            <S.wysiwyg.Figure>
                                <S.wysiwyg.Image src={asset.url} alt={asset.title} />
                                {asset.description && (
                                    <S.wysiwyg.Caption
                                        dangerouslySetInnerHTML={{
                                            __html: asset.description
                                        }}
                                    />
                                )}
                            </S.wysiwyg.Figure>
                        )
                    },
                    [richtext.BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
                        const entry = blockEntryMap.get(node.data.target.sys.id)
                        if (entry === undefined) {
                            return <></>
                        }
                        if (renderEntry === undefined) {
                            return <></>
                        }
                        return renderEntry(entry)
                    }
                }
            })}
        </>
    )
}
