import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as blob from '../Blob'

const SYMBOL_ID = 'blob-3-def'

FullBlob3.filename = SYMBOL_ID
export function FullBlob3(props: blob.SpecificIconProps): React.ReactElement {
    return (
        <blob.Blob {...props} viewBox="0 0 105 84" name="blob-3">
            <defs>
                <path
                    id={SYMBOL_ID}
                    d="m92.918 7.1012c10.805 8.9702 14.746 28.222 10.261 44.506-4.417 16.284-17.396 29.533-30.512 31.948-13.183 2.4841-26.502-5.9341-40.025-12.972-13.455-6.9692-27.114-12.627-31.259-22.771-4.0773-10.212 1.2911-24.91 10.465-33.466 9.1059-8.6252 22.085-11.04 37.375-12.972 15.222-1.8631 32.89-3.2431 43.695 5.7272z"
                />
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </blob.Blob>
    )
}

export const Blob3 = styled(function Blob3(
    props: blob.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <blob.Blob {...props} viewBox="0 0 105 84" name="blob-3">
            <use href={href} />
        </blob.Blob>
    )
})``
