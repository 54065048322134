import styled from 'styled-components'

import { useSvgHref } from '../../../svgHrefContext'

import * as illustration from '../../Illustration'

const SYMBOL_ID = 'illustration-moments-travelling-def'

FullTravelling.filename = SYMBOL_ID
export function FullTravelling(
    props: illustration.SpecificIllustrationProps
): React.ReactElement {
    return (
        <illustration.Illustration {...props} name="travelling" viewBox="0 0 80 80">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M55.36 11.35c-2.19.64-4.38 1.17-6.58 1.7l-3.34.36c-1.11.13-2.23.24-3.32.38a24.14 24.14 0 0 0-6.27 1.48c-.949.396-1.835.928-2.63 1.58a9.641 9.641 0 0 0-2 2.3 21.498 21.498 0 0 0-2.39 5.94c-.28 1.07-.55 2.16-.83 3.24l-.83 3.26-1.66 6.51-1.25 3.14c-.089.21-.146.433-.17.66a1 1 0 0 0 0 .29 1.8 1.8 0 0 0 .17.34c.275.487.611.938 1 1.34a5.9 5.9 0 0 1-1.41-1 2.561 2.561 0 0 1-.31-.34 1.65 1.65 0 0 1-.24-.5 2.64 2.64 0 0 1 0-1c.12-1.16.26-2.26.39-3.38l1.66-6.52.82-3.26c.28-1.08.55-2.17.85-3.27a23.253 23.253 0 0 1 2.61-6.47 11.68 11.68 0 0 1 5.51-4.6 25.679 25.679 0 0 1 6.77-1.62l6.69-.76c2.23.02 4.49.05 6.76.2z" />
                    <path d="M25.24 41.32a32.41 32.41 0 0 1 1.47 6.88 35.375 35.375 0 0 1-.79 7 34.596 34.596 0 0 1-2.16 6.8c-.25.54-.48 1.08-.75 1.6l-.39.79a4.331 4.331 0 0 0-.26.66c-.09.25-.16.52-.24.78-.08.26-.13.53-.16.73-.03.242-.046.486-.05.73v.83l-.44-.78h-.08a.5.5 0 0 0 .18 0c.209-.06.41-.144.6-.25a6 6 0 0 0 1.24-.91 10.826 10.826 0 0 0 2-2.56c.57-1 1.09-2 1.57-3.06s.92-2.12 1.32-3.2.7-2.29 1.05-3.36c.35-1.07.55-2.25.87-3.44A13.312 13.312 0 0 1 32 47.25c.39-.491.836-.934 1.33-1.32a5.83 5.83 0 0 1 1.67-.86 5.732 5.732 0 0 0-1.22 1.24c-.337.458-.62.955-.84 1.48a13.18 13.18 0 0 0-.87 3.21c-.31 1.09-.56 2.26-.88 3.42-.32 1.16-.69 2.32-1.1 3.45-.41 1.13-.87 2.25-1.38 3.34a34.168 34.168 0 0 1-1.66 3.24 12.997 12.997 0 0 1-2.34 3 8.38 8.38 0 0 1-1.62 1.17 4.686 4.686 0 0 1-1 .43c-.225.059-.457.09-.69.09a1.84 1.84 0 0 1-.92-.27l-.48-.22v-1.42a5.781 5.781 0 0 1 .28-1.93c.08-.29.16-.58.26-.88.1-.323.228-.638.38-.94l.37-.75c.26-.5.48-1 .71-1.52a32.628 32.628 0 0 0 2.05-6.37c.471-2.19.719-4.42.74-6.66a55.27 55.27 0 0 0 .45-6.86zM36.94 37.66c.87.498 1.668 1.114 2.37 1.83.46.85.89 1.69 1.29 2.54l1.19 2.52c.4.89.72 1.76 1.06 2.61A22.45 22.45 0 0 0 44 49.58c.363.722.859 1.37 1.46 1.91a4.701 4.701 0 0 0 2 1.1c.323.228.691.381 1.08.45a2.6 2.6 0 0 0 1.29-.04c-.12.232-.28.442-.47.62a2.69 2.69 0 0 1-.67.47 3.94 3.94 0 0 1-1.68.42A6.772 6.772 0 0 1 44.18 53a8.623 8.623 0 0 1-1.9-2.46 23.455 23.455 0 0 1-1.2-2.62c-.35-.87-.66-1.73-1-2.53l-1.17-2.49a25.782 25.782 0 0 0-1.24-2.4l-.32-1.37c-.06-.24-.12-.47-.19-.7-.07-.23-.16-.52-.22-.77zM43.93 25.51a27.78 27.78 0 0 1 4.16-.16c1.37 0 2.73.13 4.08.28 1.35.15 2.71.33 4.06.58 1.356.238 2.692.572 4 1a20.39 20.39 0 0 1-4.16.46 31.696 31.696 0 0 1-4.07-.15 32.936 32.936 0 0 1-4.07-.69 20.543 20.543 0 0 1-4-1.32z" />
                    <path d="M41.17 21a8.25 8.25 0 0 1 3 3.27c.079.17.13.353.15.54l.14.54c.08.36.15.72.21 1.08.12.72.19 1.43.25 2.14.11 1.41.14 2.81.18 4.19 0 .69 0 1.39.09 2 .09.61.11 1.32.19 2a37.42 37.42 0 0 0 2.17 7.78c.49 1.27 1 2.54 1.55 3.82.26 1.38.54 2.78.69 4.2-.86-1.14-1.65-2.3-2.45-3.47-.53-1.28-1.07-2.56-1.57-3.87a38.928 38.928 0 0 1-2.27-8.19c-.1-.71-.18-1.42-.22-2.14-.04-.72-.06-1.41-.08-2.1 0-1.39-.07-2.76-.18-4.1-.068-1.31-.3-2.607-.69-3.86A10.2 10.2 0 0 0 41.17 21zM30.85 50.39H32s0 .07.06.11l.15.21.29.42c.055.086.118.167.19.24h-.09c.05.011.1.011.15 0l.25-.17c.167-.036.338-.056.51-.06h.1c-.02 0 0 .07 0 .11a.485.485 0 0 0 0 .17c.065.23.201.433.39.58.07.26.049.535-.06.78-.036.068-.08.132-.13.19 0 0-.1.14-.16.12a.351.351 0 0 0-.15 0l-.24.07a2.407 2.407 0 0 1-.85.08 1.638 1.638 0 0 1-.55-.16 1.998 1.998 0 0 1-.45-.3 3.762 3.762 0 0 1-.45-.55l-.29-.42-.15-.21s0-.08-.08-.1c-.08-.02 0 0 0 0v-.11l.19-.44c.025-.08.058-.157.1-.23l.12-.33z" />
                    <path d="m31.11 49.87 1.24.59 1.19.66 1.17.74 1.13.78c.18.14.37.27.54.43.167.153.324.317.47.49l.94 1 .91 1 .89 1c2.35 2.73 4.52 5.57 6.83 8.23l-1-.27c.311-.127.581-.338.78-.61a1.78 1.78 0 0 0 .3-.91 3.71 3.71 0 0 0-1-2.21c-1.2-1.67-2.45-3.3-3.73-4.93-1.28-1.63-2.55-3.33-3.7-5.14a2.849 2.849 0 0 1-.36-.72c-.1-.25-.2-.5-.29-.76-.19-.51-.34-1-.52-1.44a3.47 3.47 0 0 0-.73-1.21 2.08 2.08 0 0 0-1.29-.67 2.29 2.29 0 0 1 1.63.19c.511.276.963.65 1.33 1.1.37.43.67.88 1 1.28l.47.6c.167.19.31.397.43.62.5.87 1.1 1.69 1.69 2.52.59.83 1.23 1.64 1.87 2.45 1.27 1.63 2.55 3.3 3.77 5 .346.464.648.96.9 1.48a4 4 0 0 1 .4 2A3.75 3.75 0 0 1 46 66.32l-.61.23-.44-.5c-2.37-2.73-4.53-5.56-6.83-8.23l-.87-1-.89-1-.91-.95c-.16-.15-.33-.29-.47-.45-.14-.16-.27-.35-.41-.52a47.148 47.148 0 0 0-3.46-4.03zM37.86 40.29v1.04c-.13.059-.258.126-.38.2l-.77.38c-.26.134-.52.254-.78.36l-.4.17-.37-.34-.18-.18-.19-.21v-1l.37-.16c.24-.1.48-.21.72-.33l.7-.35c.118-.055.231-.118.34-.19l.4.3.2.15.34.16z" />
                    <path d="M36.75 41a.799.799 0 0 1 .18-.27.998.998 0 0 1 .29-.16c.223-.069.458-.093.69-.07.116.127.22.264.31.41.08.13.13.24.18.34.044.122.101.24.17.35a.77.77 0 0 0 .18.19c.234.176.485.327.75.45l.22.1a.87.87 0 0 0 0 .45c.03.072.07.14.12.2a.79.79 0 0 0 .22.18.65.65 0 0 1 0 .31 1 1 0 0 1-.14.28c-.135.188-.309.345-.51.46a2.391 2.391 0 0 1-.79-.29 5.69 5.69 0 0 1-1.1-.67 3.001 3.001 0 0 1-.55-.61 3.474 3.474 0 0 1-.31-.61l-.08-.14a.91.91 0 0 0 .24-.38.48.48 0 0 0 0-.24.46.46 0 0 0-.07-.28zM45.55 40.85c.067-.38.17-.751.31-1.11.22-.05.44-.14.66-.2.22-.06.4-.14.73-.22a3.2 3.2 0 0 1 .94-.08 3.27 3.27 0 0 1 1.74.68c.226.183.433.387.62.61a2.5 2.5 0 0 1-.36 1.16 5.322 5.322 0 0 0-1.1.06c-.1-.117-.21-.224-.33-.32a1.35 1.35 0 0 0-.71-.29 2.65 2.65 0 0 0-.93.2c-.21.07-.42.13-.61.21a8.207 8.207 0 0 1-.96-.7z" />
                    <path d="M46.77 43.93v-1.08c.147-.055.29-.119.43-.19.28-.15.56-.31.82-.48.264-.172.518-.359.76-.56l.36-.31.49.17.25.07.28.1.1.28.06.26c0 .18.07.35.11.53l-.44.37c-.591.49-1.235.912-1.92 1.26-.16.09-.34.16-.51.26-.15-.1-.3-.2-.44-.31l-.12-.16-.23-.21z" />
                    <path d="M46 44.11a.51.51 0 0 1 .13-.29c.066-.074.14-.141.22-.2.155-.116.319-.22.49-.31.2.11.37.19.53.29.298.184.585.384.86.6.27.2.52.42.78.64l.38.35-.13.52c0 .08 0 .17-.08.25a1.383 1.383 0 0 1-.14.27c-.096.013-.19.036-.28.07l-.23.08-.47.18-.31-.29-.75-.56a8.68 8.68 0 0 0-.69-.48 1.848 1.848 0 0 0-.31-.16v-.46c.01-.08.01-.16 0-.24a.44.44 0 0 0 0-.26z" />
                    <path d="M47.17 47.19v-.53a3.685 3.685 0 0 1 0-.47c.1 0 .19-.07.3-.09l.31-.08h.19l.12-.11.18-.17.41.22.21.12.24.14.15.23.11.21.22.42-.21.21-.28.25a1.8 1.8 0 0 1-.52.27l-.34.09-.21.06a.94.94 0 0 0-.22.07l-.26-.38-.14-.19-.26-.27zM38.16 49.53a2.37 2.37 0 0 1 .64-1l.7.08 1.21.22c.35.064.705.107 1.06.13h.33l.12.19a.786.786 0 0 0 .14.18c.06.065.127.122.2.17a.995.995 0 0 0 .28.12c.068.205.068.425 0 .63a1.945 1.945 0 0 1-.13.3c0 .09-.11.18-.17.27a3.158 3.158 0 0 1-.84.06 8.632 8.632 0 0 1-1.33-.16c-.41-.07-.8-.15-1.16-.2a2.2 2.2 0 0 0-.5 0 3.654 3.654 0 0 0-.55-.99zM35.66 46.64c.236-.336.5-.65.79-.94h2.17c.46.017.922 0 1.38-.05.203-.034.403-.08.6-.14.313.241.648.452 1 .63v.32l-.09.31a4.12 4.12 0 0 1-.27.56c-.276.083-.556.15-.84.2-.536.082-1.077.125-1.62.13h-2.29a8.468 8.468 0 0 1-.83-1.02z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </illustration.Illustration>
    )
}

export const Travelling = styled(function Travelling(
    props: illustration.SpecificIllustrationProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <illustration.Illustration {...props} name="travelling" viewBox="0 0 80 80">
            <use href={href} />
        </illustration.Illustration>
    )
})``
