import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-linkedin-def'

FullLinkedIn.filename = SYMBOL_ID
export function FullLinkedIn(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="linkedin" viewBox="0 0 22 21">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M15 6a6.9999 6.9999 0 00-7 7v7c0 .5523.4477 1 1 1h4c.5523 0 1-.4477 1-1v-7a1 1 0 012 0v7c0 .5523.4477 1 1 1h4c.5523 0 1-.4477 1-1v-7a6.9997 6.9997 0 00-7-7zm0 2c1.326 0 2.5978.5268 3.5355 1.4645l.182.1919A4.9998 4.9998 0 0120 13v6h-2v-6a3 3 0 00-5.1213-2.1213l-.1355.1447A3 3 0 0012 13v6h-2v-6a4.9997 4.9997 0 015-5zM5 7H1c-.5523 0-1 .4477-1 1v12c0 .5523.4477 1 1 1h4c.5523 0 1-.4477 1-1V8c0-.5523-.4477-1-1-1zM4 9v10H2V9h2zM3 0C1.3431 0 0 1.3431 0 3s1.3431 3 3 3 3-1.3431 3-3-1.3431-3-3-3zm0 2c.5523 0 1 .4477 1 1s-.4477 1-1 1-1-.4477-1-1 .4477-1 1-1z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const LinkedIn = styled(function LinkedIn(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="linkedin" viewBox="0 0 22 21">
            <use href={href} />
        </icon.Icon>
    )
})``
