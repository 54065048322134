import * as React from 'react'
import { localStorageContext } from './localStorageContext'

type LocalStorageProviderProps = {
    children: React.ReactNode
}

export function LocalStorageProvider({
    children
}: LocalStorageProviderProps): React.ReactElement {
    const [storage, setStorage] = React.useState<Record<string, string> | undefined>(
        undefined
    )

    React.useEffect(() => {
        syncStorage()
        window.addEventListener('focus', syncStorage)

        return () => {
            window.removeEventListener('focus', syncStorage)
        }

        function syncStorage() {
            const storage: Record<string, string> = {}
            for (let i = 0, l = window.localStorage.length; i < l; i++) {
                const key = window.localStorage.key(i)
                if (key === null) {
                    continue
                }

                const value = window.localStorage.getItem(key)
                if (value === null) {
                    continue
                }

                storage[key] = value
            }
            setStorage(storage)
        }
    }, [])

    return (
        <localStorageContext.Provider value={{ storage, setStorage }}>
            {children}
        </localStorageContext.Provider>
    )
}
