import { RequestSuccess } from '@bob/api-client'
import * as api from '@bob/api-client/latest'

export type FreeShippingThresholds = { [vendor: string]: number | null }

export type Rate = { name: ShippingType; price: number; friendlyName: string }

export type ShippingType = 'Normal' | 'Express' | 'Premium'

export type SupportedCountries = string[]

enum friendlyShippingMethodsNames {
    Normal = 'Livraison standard',
    Express = 'Livraison rapide',
    Premium = 'Livraison premium'
}

export type ShippingRateData = {
    freeShippingThresholds: FreeShippingThresholds
    rates: Rate[]
    supportedCountries: string[]
}

export function shippingRateData(
    rateList: RequestSuccess<api.ShippingRateList>,
    vendors: string[]
): ShippingRateData {
    const vendorUnion = vendors.sort().join('$')

    const allRates: {
        [s: string]: {
            name: ShippingType
            friendlyName: string
            price: number
            vendors: string[]
        }
    } = {}

    const freeShippingThresholds: FreeShippingThresholds = {}
    const supportedCountries = rateList.body.supported_countries

    if (!rateList.body.rates) {
        return {
            freeShippingThresholds,
            rates: [],
            supportedCountries
        }
    }

    for (const rate of rateList.body.rates) {
        const friendlyName =
            friendlyShippingMethodsNames[
                rate.name as keyof typeof friendlyShippingMethodsNames
            ]
        if (!friendlyName) {
            continue
        }
        allRates[rate.name] = allRates[rate.name] || {
            name: rate.name,
            price: 0,
            vendors: []
        }

        allRates[rate.name].friendlyName = friendlyName

        const vendor = rate.project.shopify_vendor || ''

        if (!allRates[rate.name].vendors.includes(vendor)) {
            allRates[rate.name].price += rate.price
            allRates[rate.name].vendors.push(vendor)
        }

        if (rate.name === 'Normal' && !freeShippingThresholds[vendor]) {
            freeShippingThresholds[vendor] = rate.free_shipping_threshold
        }
    }

    const rates: Rate[] = []

    for (const rate of Object.values(allRates)) {
        if (rate.vendors.sort().join('$') === vendorUnion) {
            rates.push({
                name: rate.name,
                price: rate.price,
                friendlyName: rate.friendlyName
            })
        }
    }

    return { freeShippingThresholds, rates, supportedCountries }
}
