import * as React from 'react'

import { reducer, Reducer } from './reducer'
import { Item, NavigableListApi, NavigableItemApi } from './types'
import { getItem, getFocusedItem, normalize, matchQuery } from './utils'
import { useCommittedRef } from '../../hooks/useCommitedRef'

export function useNavigableList<IDENTIFIER>(
    active: boolean,
    focused?: IDENTIFIER,
    typeahead = true,
    disableInitFocus = false,
    filter?: string
): NavigableListApi<IDENTIFIER> {
    const [state, dispatch] = React.useReducer<Reducer<IDENTIFIER>>(reducer, {
        registered: [],
        typeAhead: { timestamp: 0, query: '' }
    })
    const stateCommittedRef = useCommittedRef(state)
    const activeCommittedRef = useCommittedRef(active)

    const focusedItem = getFocusedItem(state, focused, disableInitFocus)
    const focusedItemCommittedRef = useCommittedRef(focusedItem)

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    React.useEffect(() => {
        if (focusedItem !== undefined && active) {
            focusedItem.handleFocus()
        }
    }, [focusedItem, active])

    React.useEffect(() => {
        if (filter !== undefined) {
            dispatch({ type: 'filter', filter })
        }
    }, [filter])

    React.useEffect(() => {
        const focusedItem = focusedItemCommittedRef.current
        const state = stateCommittedRef.current

        if (typeahead) {
            const query = normalize(state.typeAhead.query)
            if (
                focusedItem !== undefined &&
                !focusedItem.disabled &&
                !focusedItem.filtered &&
                matchQuery(focusedItem, query)
            ) {
                if (
                    focusedItem.next !== undefined &&
                    !focusedItem.next.disabled &&
                    !focusedItem.next.filtered &&
                    matchQuery(focusedItem.next, query)
                ) {
                    focusedItem.next.handleFocus()
                } else {
                    const match = state.registered.find(item => matchQuery(item, query))
                    if (match !== undefined) {
                        match.handleFocus()
                    }
                }
            } else {
                const match = state.registered.find(item => matchQuery(item, query))
                if (match !== undefined) {
                    match.handleFocus()
                }
            }
        }
    }, [state.typeAhead])

    return {
        register: React.useCallback(register, [])
    }

    function handleKeyDown(event: KeyboardEvent): void {
        const active = activeCommittedRef.current
        const focusedItem = focusedItemCommittedRef.current
        if (!active) {
            return
        }

        if (event.key === 'ArrowUp' || event.key === 'Up') {
            event.preventDefault()
            let toFocus = focusedItem
                ? focusedItem.previous
                : stateCommittedRef.current.tail
            while (toFocus !== undefined && (toFocus.disabled || toFocus.filtered)) {
                toFocus = toFocus.previous
            }

            if (toFocus) {
                toFocus.handleFocus()
            }
            return
        }
        if (event.key === 'ArrowDown' || event.key === 'Down') {
            event.preventDefault()
            let toFocus = focusedItem ? focusedItem.next : stateCommittedRef.current.head
            while (toFocus !== undefined && (toFocus.disabled || toFocus.filtered)) {
                toFocus = toFocus.next
            }

            if (toFocus) {
                toFocus.handleFocus()
            }
            return
        }

        if (event.key && event.key.length === 1) {
            // only printable chars
            dispatch({ type: 'type-ahead', key: event.key })
        }
    }

    // register an item in the NavigableList. Usually not used directly, but passed
    // down the tree in a context, to allow child self-registration.
    // see /src/behaviours/README.md
    function register(item: Item<IDENTIFIER>): NavigableItemApi<IDENTIFIER> {
        React.useEffect(() => {
            dispatch({ type: 'register', item })
            return () => {
                dispatch({ type: 'deregister', identifier: item.identifier })
            }
        }, [])

        React.useEffect(() => {
            if (isDisabled(item.identifier) !== item.disabled) {
                dispatch({
                    type: 'disable',
                    identifier: item.identifier,
                    disabled: item.disabled
                })
            }
        }, [item.identifier, item.disabled])

        return {
            focus: React.useCallback(focus, []),
            isFocused: isFocused(item.identifier),
            isFiltered: isFiltered(item.identifier)
        }
    }

    function focus(identifier: IDENTIFIER): void {
        const state = stateCommittedRef.current
        const item = getItem(state.registered, identifier)
        if (
            item !== undefined &&
            !item.disabled &&
            !item.filtered &&
            !isFocused(identifier)
        ) {
            item.handleFocus()
        }
    }

    function isFocused(identifier: IDENTIFIER): boolean {
        const focusedItem = focusedItemCommittedRef.current

        if (focusedItem === undefined) {
            return false
        }
        return focusedItem.identifier === identifier
    }

    function isFiltered(identifier: IDENTIFIER): boolean {
        const state = stateCommittedRef.current
        const item = getItem(state.registered, identifier)
        if (item === undefined) {
            return false
        }
        return item.filtered
    }

    function isDisabled(identifier: IDENTIFIER): boolean | undefined {
        const state = stateCommittedRef.current
        const item = getItem(state.registered, identifier)
        if (item === undefined) {
            return undefined
        }
        return item.disabled
    }
}
