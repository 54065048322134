import 'url-search-params-polyfill'
import fetch from 'cross-fetch'
import { getCookie, setCookie, removeCookie } from '@bob/cookie-helper'
import { Either } from '@bob/monad'
import * as logger from '@bob/logger'

const FormData = typeof window === 'undefined' ? require('form-data') : window.FormData

type RequesterConfig = {
    cookieNames: {
        accessToken: string
        refreshToken: string
    }
    endpoints: {
        refreshToken: string
    }
}

export type ExtraConfig = {
    getCookie?: typeof getCookie
    setCookie?: typeof setCookie
    removeCookie?: typeof removeCookie
    refreshAccessToken?: typeof refreshAccessToken
}

type BaseRequestOptions = RequestInit & {
    token?: string
    withToken?: boolean
} & ExtraConfig

type TransfromRequestOptions = Omit<BaseRequestOptions, 'body'> & {
    type: 'json' | 'formdata' | 'urlencoded'
    body: any
}

export type RequestOptions = TransfromRequestOptions | BaseRequestOptions

type FetchOptions = Omit<RequestInit, 'headers'> & {
    headers?: Record<string, string>
}

export type RequestFailure = {
    status: number
    url: string
    body: any
    cause?: any
    doNotRedirectToSignIn?: boolean
}

export type RequestSuccess<BODY> = {
    headers: Record<string, string>
    body: BODY
    url: string
    status: number
    ok: boolean
}
export type HttpResult<VALUE = unknown> = Either<RequestFailure, VALUE>
export type HttpResponse<BODY = unknown> = HttpResult<RequestSuccess<BODY>>
export type HttpRequest = <BODY = unknown>(
    url: string,
    requestOptions?: RequestOptions
) => Promise<HttpResponse<BODY>>

export function requester({ cookieNames, endpoints }: RequesterConfig): HttpRequest {
    return loggedRequest

    async function loggedRequest<BODY = unknown>(
        url: string,
        requestOptions: RequestOptions = {}
    ): Promise<HttpResponse<BODY>> {
        const result = await pureRequest<BODY>(url, requestOptions)

        result.caseOf({
            left: failure => {
                if (!isExpectedError(failure)) {
                    const classificationString = getErrorClassification(failure.body)
                    const classification = classificationString
                        ? ` - ${classificationString}`
                        : ''

                    if (failure.status === 422) {
                        logger.inform(
                            `HTTP request error - Code ${failure.status}${classification}`,
                            {
                                request: { url, options: requestOptions },
                                response: failure
                            }
                        )
                    } else {
                        logger.err(
                            `HTTP request error - Code ${failure.status}${classification}`,
                            {
                                request: { url, options: requestOptions },
                                response: failure
                            },
                            scope => {
                                scope.setFingerprint(['{{ default }}', failure.url])
                            }
                        )
                    }
                }
            },
            right: success => {
                if (!success.ok && !isExpectedError(success)) {
                    const classificationString = getErrorClassification(success.body)
                    const classification = classificationString
                        ? ` - ${classificationString}`
                        : ''

                    logger.err(
                        `HTTP request error - Code ${success.status}${classification}`,
                        {
                            request: { url, options: requestOptions },
                            response: success
                        },
                        scope => {
                            scope.setFingerprint(['{{ default }}', success.url])
                        }
                    )
                }
            }
        })

        return result
    }

    async function pureRequest<BODY = unknown>(
        url: string,
        requestOptions: RequestOptions = {}
    ): Promise<HttpResponse<BODY>> {
        if (!requestOptions.withToken) {
            return baseRequest(url, requestOptions)
        }

        const accessToken =
            requestOptions.token ??
            (requestOptions.getCookie ?? getCookie)(cookieNames.accessToken)
        const refreshToken = (requestOptions.getCookie ?? getCookie)(
            cookieNames.refreshToken
        )

        if (!accessToken) {
            if (!refreshToken) {
                // no accessToken and no refreshToken, the request fails
                return Either.left({
                    status: 401,
                    url,
                    body: {
                        expected: true,
                        message: 'no accessToken or refreshToken found'
                    }
                })
            }

            // no accessToken, refresh the accessToken and run the request
            return refreshThenRequest(refreshToken)
        }

        // some accessToken (maybe expired), run the request and on error 401, try to refresh and retry.
        const eitherResponse = await baseRequest<BODY>(url, {
            ...requestOptions,
            token: accessToken
        })

        return eitherResponse.exceptAsync(async error => {
            if (error.status === 401 && refreshToken) {
                return refreshThenRequest(refreshToken)
            }
            return Either.left(error)
        })

        async function refreshThenRequest(
            refreshToken: string
        ): Promise<HttpResponse<BODY>> {
            const eitherAccessToken = await (
                requestOptions?.refreshAccessToken ?? refreshAccessToken
            )(refreshToken, endpoints.refreshToken)
            return eitherAccessToken.caseOf<Promise<HttpResponse<BODY>>>({
                left: async error =>
                    Either.left({
                        status: 401,
                        url,
                        body: { message: 'access token refresh failed' },
                        cause: error
                    }),
                right: async accessToken => {
                    ;(requestOptions.setCookie ?? setCookie)(
                        cookieNames.accessToken,
                        accessToken
                    )
                    return await baseRequest<BODY>(url, {
                        ...requestOptions,
                        token: accessToken
                    })
                }
            })
        }
    }
}

type RefreshResponsBody = { access_token: string }

async function refreshAccessToken(
    refreshToken: string,
    endpoint: string
): Promise<Either<RequestFailure, string>> {
    const response = await baseRequest<RefreshResponsBody>(endpoint, {
        type: 'json',
        method: 'POST',
        body: {
            grant_type: 'refresh_token',
            refresh_token: refreshToken
        }
    })

    return response.next(response => {
        return response.body.access_token
    })
}

async function baseRequest<BODY = unknown>(
    url: string,
    requestOptions: RequestOptions = {}
): Promise<Either<RequestFailure, RequestSuccess<BODY>>> {
    const fetchOptions = makeFetchOptions(requestOptions)

    // See https://github.com/jerrybendy/url-search-params-polyfill#known-issues
    // we use this pollyfill, and pollyfilled navigator don't know they have to set this header,
    // so we set it even if spec says it should be set by the browser.
    if (fetchOptions.body instanceof URLSearchParams) {
        fetchOptions.headers = fetchOptions.headers || {}
        fetchOptions.headers['Content-Type'] =
            'application/x-www-form-urlencoded; charset=UTF-8'
    }

    const response = await fetch(url, fetchOptions)
    let body

    try {
        body = await getResponseBody(response)
    } catch (error) {
        // bypass SyntaxError for Firefox since it doesn't handle well Content-Length header
        // when a response with Content-Type: application/json is returned without any content
        // it generates an error
        if (error instanceof SyntaxError) {
            return Either.right(requestSuccess(response, body))
        }

        return Either.left({
            status: response.status,
            url,
            body
        })
    }

    if (!response.ok) {
        return Either.left({
            status: response.status,
            url,
            body
        })
    }

    return Either.right(requestSuccess(response, body))
}

function requestSuccess<BODY>(response: Response, body: BODY): RequestSuccess<BODY> {
    // dont use {...response } or Object.assign, response won't be copied. We have to
    // manually pick an set the properties on response.
    return {
        url: response.url,
        status: response.status,
        ok: response.ok,
        headers: normalizeHeaders(response.headers),
        body
    }
}

/**
 * Returns an error classification based on various API formats. The following are supported:
 *
 * {body: [{ classification: string, fieldNames: Array, message: string }]},
 * {body: { errors: [{ classification: string, fieldNames: Array, message: string}], type: string }},
 * {body: { classication: string, message: string, type: string }},
 *
 * (Yup, that 'classication' is intended. It's a typo API-side.)
 *
 * @param body Failure body to extract classification from
 * @returns The extracted classification, or `undefined` if none found
 */
function getErrorClassification(body: any): string | undefined {
    if (body === null || body === undefined) {
        return undefined
    }

    // Have a generic error for very old browsers
    if (!Array.isArray) return undefined

    if (Array.isArray(body)) {
        const [error] = body
        return error && error.classification
    }

    const { errors } = body
    if (errors && Array.isArray(errors)) {
        const [error] = errors
        return error && error.classification
    }

    if (body.classication) return body.classication
    if (body.classification) return body.classification

    return undefined
}

/* Some errors ar buisness errors (like 401 when user is not registered). Those
errors should not be logged, because they are expected in this situation. We log
only errors that should not happen */
export function isExpectedError(
    request: RequestFailure | RequestSuccess<unknown>
): boolean {
    if ('expected' in request.body) {
        return request.body.expected
    }

    return false
}

async function getResponseBody(response: Response): Promise<any> {
    if (response.headers.get('Content-Length') === '0') {
        return undefined
    }

    try {
        return response.json()
    } catch (error: any) {
        throw { response, ...error }
    }
}

function makeFetchOptions(options: RequestOptions): FetchOptions {
    const headers = normalizeHeaders(options.headers || {})
    if (options.token) {
        headers['Authorization'] = `Bearer ${options.token}`
    }

    const fetchOptions: FetchOptions = {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin',
        ...options,
        headers
    }

    if ('type' in options && options.type === 'json') {
        return jsonTransform(fetchOptions, options.body)
    }

    if ('type' in options && options.type === 'formdata') {
        return formDataTransform(fetchOptions, options.body)
    }

    if ('type' in options && options.type === 'urlencoded') {
        return urlSearchParamsTransform(fetchOptions, options.body)
    }

    if (!validateBody(options.body)) {
        console.warn('trying to fetch with an invalid body', options.body)
    }

    return fetchOptions
}

function jsonTransform(fetchOptions: FetchOptions, body: any): FetchOptions {
    return {
        ...fetchOptions,
        body: JSON.stringify(body),
        headers: {
            ...fetchOptions.headers,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }
}

function formDataTransform(fetchOptions: FetchOptions, body: any): FetchOptions {
    const formData = new FormData()
    for (const [key, value] of Object.entries(body)) {
        if (typeof value !== 'string' && !(value instanceof window.Blob)) {
            formData.append(key, String(value))
        }
        formData.append(key, value as string)
    }
    return {
        ...fetchOptions,
        body: formData
    }
}

function urlSearchParamsTransform(fetchOptions: FetchOptions, body: any): FetchOptions {
    const urlSearchParams = new URLSearchParams(body)
    return {
        ...fetchOptions,
        body: urlSearchParams
    }
}

export function normalizeHeaders(headers: HeadersInit): Record<string, string> {
    if (headers instanceof Headers) {
        headers = Array.from(headers.entries())
    }
    if (Array.isArray(headers)) {
        const objectHeaders: Record<string, string> = {}
        for (const [name, value] of headers) {
            objectHeaders[name] = value
        }
        return objectHeaders
    }
    return { ...headers }
}

function validateBody(body: any): body is RequestInit['body'] {
    return (
        body === undefined ||
        body === null ||
        (window?.Blob && body instanceof window?.Blob) ||
        (window?.ArrayBuffer && body instanceof window?.ArrayBuffer) ||
        (window?.FormData && body instanceof window?.FormData) ||
        (window?.URLSearchParams && body instanceof window?.URLSearchParams) ||
        (window?.ReadableStream && body instanceof window?.ReadableStream) ||
        typeof body === 'string'
    )
}
