import * as React from 'react'

type LinkWrapper = (props: any) => React.ReactElement

const linkWrapperContext = React.createContext<LinkWrapper>(({ children }) => (
    <>{children}</>
))

type LinkProviderProps = {
    children?: React.ReactNode
    value: LinkWrapper
}

export function LinkWrapperProvider({ children, value }: LinkProviderProps) {
    return (
        <linkWrapperContext.Provider value={value}>
            {children}
        </linkWrapperContext.Provider>
    )
}

export function useLinkWrapper(): LinkWrapper {
    return React.useContext(linkWrapperContext)
}
