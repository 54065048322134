import * as ds from '@bob/design-system'

const BLOB_COLORS: (keyof typeof ds.BLOB_COLORS)[] = [
    'blue',
    'pink',
    'light-green',
    'orange',
    'yellow'
]

const BLOB_VARIANTS: (keyof typeof ds.BLOB_VARIANTS)[] = [1, 5, 2, 4, 6, 3]

let i = 0

export function resetBlob(): void {
    i = 0
}

export function blob(): {
    color: keyof typeof ds.BLOB_COLORS
    variant: keyof typeof ds.BLOB_VARIANTS
} {
    const index = i++

    const color = BLOB_COLORS[index % BLOB_COLORS.length]
    const variant =
        BLOB_VARIANTS[
            (index + Math.floor(index / BLOB_COLORS.length)) % BLOB_COLORS.length
        ]

    return { color, variant }
}
