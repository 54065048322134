import React from "react";
var __jsx = React.createElement;
import PlausibleProvider from 'next-plausible';
import { DefaultSeo } from 'next-seo';
import { Layout } from '@bob/layout';
import { useUTMCatcher } from '@bob/utm';
import { PageViewProvider, getPageViewManager, usePageView } from '@bob/shadow';
import { FACEBOOK_APP_ID, SITE_NAME, SITE_TWITTER_HANDLE } from '../constants';
import { SvgDirProvider } from '../../../design-system/src/svg/svgHrefContext';
import { SVG_DIR } from '../constants';
var defaultSeo = {
  defaultTitle: SITE_NAME,
  openGraph: {
    locale: 'fr_FR',
    site_name: SITE_NAME
  },
  facebook: {
    appId: FACEBOOK_APP_ID
  },
  twitter: {
    site: SITE_TWITTER_HANDLE,
    cardType: 'summary_large_image'
  },
  additionalLinkTags: [{
    rel: 'icon',
    type: 'image/png',
    href: '/assets/bob_favicon_fondcreme.png'
  }, {
    rel: 'apple-touch-icon',
    href: '/assets/bob_apple-touch-icon_114.png',
    sizes: '114x114'
  }, {
    rel: 'apple-touch-icon-precomposed',
    href: '/assets/bob_apple-touch-icon_114.png',
    sizes: '114x114'
  }]
};

function MyApp(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  useUTMCatcher();
  usePageView();

  if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_SITE_DOMAIN) {
    return __jsx(SvgDirProvider, {
      value: SVG_DIR
    }, __jsx(PlausibleProvider, {
      manualPageviews: true,
      domain: process.env.NEXT_PUBLIC_SITE_DOMAIN
    }, __jsx(Layout, null, __jsx(DefaultSeo, defaultSeo), __jsx(Component, pageProps))));
  }

  return __jsx(SvgDirProvider, {
    value: SVG_DIR
  }, __jsx(Layout, null, __jsx(DefaultSeo, defaultSeo), __jsx(Component, pageProps)));
}

var pageViewManager = getPageViewManager();

function MyAppWrapper(props) {
  return __jsx(PageViewProvider, {
    manager: pageViewManager
  }, __jsx(MyApp, props));
}

export default MyAppWrapper;