import Link from 'next/link'
import styled from 'styled-components'

import { styles as S } from '@bob/design-system'
import * as ds from '@bob/design-system'
import * as url from '@bob/url'

import { Autocomplete } from '../../Autocomplete'
import { CategoriesGrid, CategoryLinks } from '../components/Categories'
import { Moments } from '../components/Moments'

type MobileDrawerProps = {
    onSelect: () => void
}

export function MobileDrawer({
    onSelect = () => {
        // no-op
    }
}: MobileDrawerProps): React.ReactElement<MobileDrawerProps> {
    return (
        <>
            <Section>
                <S.heading.Title3>Rechercher</S.heading.Title3>
                <Autocomplete onSelect={onSelect} />
            </Section>
            <a href={url.login().path}>
                <ContrastedSection onClick={onSelect}>
                    <S.heading.Title3>Connectez-vous</S.heading.Title3>
                    <ds.icons.ChevronRight />
                </ContrastedSection>
            </a>
            <CategoriesGrid>
                <CategoryLinks onSelect={onSelect} vertical />
            </CategoriesGrid>
            <Section>
                <S.heading.Title3>Ma journée Bien ou Bien</S.heading.Title3>
                <Moments onSelect={onSelect} />
            </Section>
            <Section>
                <ds.LinkButton href={url.giftIdeas().path} onClick={onSelect}>
                    Idées cadeaux
                </ds.LinkButton>
                <ds.LinkButton href={url.articles().path} onClick={onSelect}>
                    Guide et actus
                </ds.LinkButton>
            </Section>
            <SillyFiller />
        </>
    )
}

const ContrastedSection = styled.section`
    background: ${ds.color.SAND_2};
    padding: 20px 36px;
    position: relative;

    ${ds.icons.ChevronRight} {
        position: absolute;
        right: 30px;
        top: 35%;
    }
`

const Section = styled.section`
    padding: 20px;

    ${S.heading.Title3} {
        margin-bottom: 20px;
    }

    ${ds.LinkButton} {
        display: flex;

        + ${ds.LinkButton} {
            margin-top: 54px;
        }
    }
`

/**
 * HACK: This is needed because the drawer's height is based on `vh`. Problem:
 *   `vh` on mobile browsers take UI elements into account (address bar and stuff)*.
 *   As a side effect, the last elements of the drawer are either hidden
 *   when those UI elements are on the bottom of the display, or need extra scrolling
 *   when the UI elements are on top.
 *
 *   This filler is here to balance that, and allow display of all elements for most
 *   mobile browsers.
 *
 *   *https://bugs.webkit.org/show_bug.cgi?id=141832#c5
 */
const SillyFiller = styled.section`
    background-color: ${ds.color.SAND_1};
    height: 128px;
    width: 100%;
`
