import * as React from 'react'
import styled, { css } from 'styled-components'
import Link from 'next/link'

import * as ds from '@bob/design-system'
import { aboutPages } from '@bob/cold-data/client'
import * as url from '@bob/url'
import { NewsletterForm } from '@bob/newsletter'

import { NavLink, LinkStyled } from './NavLink'

const sortedAboutPages = aboutPages.sort((a, b) => a.order - b.order)

function AppFooterComponent({ ...rest }): React.ReactElement {
    return (
        <Footer {...rest}>
            <ds.Container>
                <Grid>
                    <Col1>
                        <Link prefetch={false} href={url.homepage().path} passHref>
                            <BrandLink>
                                <ds.Logo themeLight width={180} height={109} />
                            </BrandLink>
                        </Link>
                        <SocialList>
                            <li>
                                <SocialLink
                                    href="https://twitter.com/bienoubien_com"
                                    rel="noopener external"
                                    target="_blank"
                                    title="Notre page Twitter"
                                >
                                    <ds.icons.Twitter />
                                </SocialLink>
                            </li>
                            <li>
                                <SocialLink
                                    href="https://www.instagram.com/bienoubien_com"
                                    rel="noopener external"
                                    target="_blank"
                                    title="Notre page Instagram"
                                >
                                    <ds.icons.Instagram />
                                </SocialLink>
                            </li>
                            <li>
                                <SocialLink
                                    href="https://www.facebook.com/bienoubiencom"
                                    rel="noopener external"
                                    target="_blank"
                                    title="Notre page Facebook"
                                >
                                    <ds.icons.Facebook />
                                </SocialLink>
                            </li>
                            <li>
                                <SocialLink
                                    href="https://www.linkedin.com/company/bienoubien/"
                                    rel="noopener external"
                                    target="_blank"
                                    title="Notre page LinkedIn"
                                >
                                    <ds.icons.LinkedIn />
                                </SocialLink>
                            </li>
                        </SocialList>
                    </Col1>
                    <Col2>
                        <Section>
                            <Title>Contactez nous</Title>
                            <ul>
                                <li>
                                    <a href="mailto:contact@bienoubien.com">
                                        contact@bienoubien.com
                                    </a>
                                </li>
                                {/* <li>01 23 45 67 89</li> */}
                            </ul>
                        </Section>
                        {/* TODO:  Create component for Link + icon */}
                        <Section>
                            <Link prefetch={false} href="https://bit.ly/bob-formulaire-vendeurs " passHref>
                                <VendorLink>Je veux vendre sur Bien ou Bien</VendorLink>
                            </Link>
                        </Section>
                        <Section>
                            <Title>Restez au courant&nbsp;!</Title>
                            <p>
                                Bien ou Bien, ça ne fait que commencer&nbsp;! Pour
                                recevoir les nouveautés dans votre boîte mail
                            </p>
                            <NewsletterForm buttonOutlined isWhiteButton />
                        </Section>
                    </Col2>
                    <Col3>
                        <Nav>
                            <NavLink href={url.faq().path}>FAQ</NavLink>
                            <NavLink href={url.newsletter().path}>Newsletter</NavLink>
                            {sortedAboutPages.map(aboutPage =>
                                aboutPage.slug.startsWith('http') ? (
                                    <LinkStyled
                                        href={aboutPage.slug}
                                        key={aboutPage.slug}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {aboutPage.title}
                                    </LinkStyled>
                                ) : (
                                    <NavLink
                                        key={aboutPage.slug}
                                        href={
                                            url.aboutPage({
                                                slug: aboutPage.slug,
                                                title: aboutPage.title
                                            }).path
                                        }
                                    >
                                        {aboutPage.title}
                                    </NavLink>
                                )
                            )}
                        </Nav>
                    </Col3>
                </Grid>
            </ds.Container>
        </Footer>
    )
}

export const AppFooter = styled(AppFooterComponent)``

export const Footer = styled.footer`
    z-index: ${ds.zindex.FOOTER};
    flex-shrink: 0;
    padding: 50px 0;
    background-color: ${ds.color.GRAY_3};
    color: ${ds.color.WHITE};
    font-size: 14px;
`

export const Grid = styled.div`
    display: grid;
    @media not screen and (${ds.breakpoint.TABLET}) {
        row-gap: 50px;
        grid-template-areas: 'col-1' 'col-2' 'col-3';
    }
    @media screen and (${ds.breakpoint.TABLET}) {
        align-items: center;
        row-gap: 50px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 'col-1 col-1' 'col-2 col-3';
    }
    @media screen and (${ds.breakpoint.LAPTOP}) {
        align-items: center;
        grid-template-areas: 'col-1 col-2 col-3';
        grid-template-columns: repeat(3, 1fr);
    }
`

const spacingX = css`
    @media screen and (${ds.breakpoint.TABLET}) {
        padding-left: calc(10% + 20px);
        padding-right: calc(10% + 20px);
    }
`

export const Col1 = styled.div`
    text-align: center;
    grid-area: col-1;
    ${spacingX};
`

export const Col2 = styled.div`
    grid-area: col-2;
    ${spacingX};
    @media not screen and (${ds.breakpoint.TABLET}) {
        border-bottom: 1px solid ${ds.color.WHITE};
        padding-bottom: 50px;
    }
    @media screen and (${ds.breakpoint.TABLET}) {
        border-right: 1px solid ${ds.color.WHITE};
    }
`

export const Col3 = styled.div`
    grid-area: col-3;
    ${spacingX};
    @media not screen and (${ds.breakpoint.TABLET}) {
        text-align: center;
    }
`

export const BrandLink = styled.a`
    display: inline-block;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 0.75;
    }

    ${ds.Logo} {
        width: initial;
        height: initial;
    }
`

export const SocialList = styled.ul`
    display: flex;
    justify-content: center;
    margin-top: 10px;

    > * + * {
        margin-left: 10px;
    }
`

export const SocialLink = styled.a`
    display: block;
    padding: 10px;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 0.5;
    }
`

export const Section = styled.div`
    &:not(:last-child) {
        margin-bottom: 20px;
    }

    ${NewsletterForm} {
        display: grid;
        gap: 20px;
        margin-top: 20px;
    }
`

export const Title = styled.div`
    font-size: 22px;
    font-weight: ${ds.font.WEIGHT.SEMI_BOLD};

    &:not(:last-child) {
        margin-bottom: 4px;
    }
`

export const Nav = styled.ul`
    display: grid;
    gap: 15px;
`

const VendorLink = styled(ds.LinkButton)`
    color: ${ds.color.WHITE};
    margin-bottom: 10px;
`
