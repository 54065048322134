import * as React from 'react'

export function useInterval(callback: () => void, delay: null | number): void {
    const ref = React.useRef<() => void | null>()

    React.useEffect(() => {
        ref.current = callback
    }, [callback])

    React.useEffect(() => {
        const tick = () => ref.current && ref.current()

        if (delay !== null) {
            const id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}
