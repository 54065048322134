import { request } from '../index'
import { Endpoint, client, BaseMethod, ClientMethod, OAuth2 } from '../src/client'
import { DistributiveOmit, PartialSome } from './utils'

// BASE

export type ApiError = {
    fieldNames: string[]
    classification: string
    message: string
}[]

export type Paginable<RESPONSE> = RESPONSE & {
    meta: {
        limit: number
        next: string | null
        previous: string | null
        total_count: number

        offset?: number /** not documented, bob specific */
    }
}

// COMMON

export type Avatar = {
    id: number
    name: string
    value: string
    versions: Record<string, ImageVersion>
}

export const LANGS = ['ca', 'de', 'en', 'es', 'fr', 'it', 'nl', 'pt'] as const
export type Lang = typeof LANGS[number]

export type I18n<TYPE = string> = Partial<Record<Lang, TYPE>>

export type ImageResource = {
    color?: string
    id: number
    lang: string
    name?: string
    type: string
    url?: string
    value?: string
    versions?: Record<string, ImageVersion>
}

export type Manager = PublicUser

export enum PaymentStatus {
    Waiting = 'waiting',
    Processing = 'processing',
    Ready = 'ready',
    Error = 'error',
    PaymentProcessing = 'payment-processing',
    Completed = 'completed'
}

export type Invoice = {
    url: string
}

export type ImageVersion = {
    height: number | undefined
    url: string
    width: number | undefined
}

export type LocationResource = {
    city: string
    country: string
}

export type TagResource = {
    absolute_url: string
    id: number
    name: I18n
    position: number
    slug: string
}

export type VideoResource = {
    author_name: string
    author_url: string
    height: number
    html: string
    id: number
    language: string
    provider_name: string
    provider_url: string
    thumbnail_height: number
    thumbnail_width: number
    title: string
    type: string
    url: string
    version: string
    width: number
}

// USER

export type PublicUser = {
    absolute_url: string
    avatar: Avatar
    country: string
    date_joined: string
    description: I18n
    first_name: string
    has_avatar: boolean
    id: number
    is_completed: boolean /** DEPRECATED */
    is_staff: boolean
    lang: string
    last_login: string
    last_name: string
    location: string
    name: string
    presentation: I18n
    resource_uri: string
    role: Role
    screenname: string
    timezone: string
    username: string
}

export type AuthenticatedUser<EXTRA_FIELDS extends string = ''> = PublicUser & {
    birthday: string
    email: string
    nationality?: string
    personal_id_number?: string

    contact_lang?: EXTRA_FIELDS extends 'contact_lang' ? string | undefined : undefined
    orders?: EXTRA_FIELDS extends 'orders' ? Order[] | undefined : undefined
    projects?: EXTRA_FIELDS extends 'projects' ? Project[] | undefined : undefined
    stats?: EXTRA_FIELDS extends 'stats' ? Stats | undefined : undefined
}

export enum Label {
    Captain = 'captain',
    Connector = 'connector',
    Expert = 'expert',
    Official = 'official',
    Team = 'team'
}

export type Supporter<EXTRA_FIELDS extends string = ''> = PublicUser & {
    is_anonymous: boolean

    latest_project_comment?: EXTRA_FIELDS extends 'latest_project_comment'
        ? Comment | undefined
        : undefined
    latest_project_order?: EXTRA_FIELDS extends 'latest_project_order'
        ? Order | undefined
        : undefined
    labels?: EXTRA_FIELDS extends 'labels' ? Label[] | undefined : undefined
}

export type SupporterList<EXTRA_FIELDS extends string = ''> = {
    supporters: Supporter<EXTRA_FIELDS>[]
}

export type Fan<EXTRA_FIELDS extends string = ''> = PublicUser & {
    labels?: EXTRA_FIELDS extends 'labels' ? Label[] | undefined : undefined
}

export type FanList<EXTRA_FIELDS extends string = ''> = {
    fans: Fan<EXTRA_FIELDS>[] | null
}

type Stats = {
    created_projects_online_count: number
    followed_projects_count: number
    projects_created_count: number
    projects_currently_funding_count: number
    projects_failed_count: number
    projects_orders_count: number
    projects_succeeded_count: number
    proposals_created_count: number
    proposals_refused_count: number
    proposals_validated_count: number
    supported_projects_count: number
}

export enum Role {
    Editor = 'editor',
    Fan = 'fan',
    Moderator = 'moderator',
    Owner = 'owner',
    Supporter = 'supporter'
}

// all fields of AuthenticatedUser except "id",
// and make all of them optionals (so each field could be undefined)
export type UpdateUser = Partial<Omit<AuthenticatedUser, 'id'>>

export type PublicUserList = {
    users: PublicUser[]
}

// PROJECT

export type Project<EXTRA_FIELDS extends string = ''> = {
    absolute_url: string
    amount_raised: number
    analytics_count: number
    background: ImageResource | undefined
    comments_count: number
    comments_enabled: CommentsPermission
    committed: number
    country: string
    currency: string
    date_end: string | undefined
    date_end_extra_time: string | undefined
    date_start: string | undefined
    description: I18n | null
    description_funding: I18n | null
    description_yourself: I18n | null
    discussions_thread_id: number | undefined
    fans_count: number
    finished: boolean
    goal_raised: boolean
    goal: number
    id: number
    is_cancelled: boolean
    is_in_extra_time: boolean
    is_online: boolean
    lang: string
    location: LocationResource | undefined
    lowest_contribution_amount: number
    main_image: I18n<ImageResource> | undefined
    main_tag: TagResource | undefined
    maximum_return_days?: number /** not documented, bob specific */
    name: I18n
    nb_days: number | undefined
    nb_products_sold: number
    news_count: number
    owner: PublicUser
    orders_count: number
    payment_methods: Array<string> | undefined
    permissions: ProjectPermissions
    resource_uri: string
    slug: string
    shopify_vendor?: string /** not documented, bob specific */
    sponsorships_count: number
    status: ProjectStatus
    subtitle: I18n
    supporters_count: number
    timezone: string
    type: string
    urls: any // TODO: specify
    video: I18n<VideoResource> | undefined
    visible: boolean
    required_personal_id_number?: boolean /** not documented */
    sharing_urls: I18n | undefined /** not documented */

    analytics: EXTRA_FIELDS extends 'analytics' ? AnalyticsTag[] : undefined
    billing: EXTRA_FIELDS extends 'billing' ? Billing | undefined : undefined
    delivery: EXTRA_FIELDS extends 'delivery' ? Delivery | undefined : undefined
    discussion_disabled: EXTRA_FIELDS extends 'manager' ? boolean : undefined
    fields_needed: EXTRA_FIELDS extends 'fields_needed' ? Array<string> : undefined
    latest_news: EXTRA_FIELDS extends 'latest_news' ? News | undefined : undefined
    latest_supporter: EXTRA_FIELDS extends 'latest_supporter'
        ? PublicUser | undefined
        : undefined
    links: EXTRA_FIELDS extends 'links' ? Link[] : undefined
    manager: EXTRA_FIELDS extends 'manager' ? Manager | undefined : undefined
    partnerships: EXTRA_FIELDS extends 'partnerships' ? Partnership[] : undefined
    post_campaign_link: EXTRA_FIELDS extends 'links' ? Link : undefined
    rewards: EXTRA_FIELDS extends 'rewards' ? Reward[] | undefined : undefined
    sponsorships: EXTRA_FIELDS extends 'sponsorships'
        ? Sponsorship[] | undefined
        : undefined
    tags: EXTRA_FIELDS extends 'tags' ? TagResource[] : undefined
    thread: EXTRA_FIELDS extends 'thread' ? Thread : undefined
    user_orders: EXTRA_FIELDS extends 'user_orders' ? Order[] : undefined
    unread_count: EXTRA_FIELDS extends 'unread_count' ? number : undefined
    user_role: EXTRA_FIELDS extends 'user_role' ? Role : undefined

    answer_code: EXTRA_FIELDS extends 'answer_code'
        ? AnswerCode
        : undefined /** not documented */
    default_manager: EXTRA_FIELDS extends 'default_manager'
        ? PublicUser | undefined
        : undefined /** not documented */
    examples: EXTRA_FIELDS extends 'examples'
        ? [Project<''>, Project<''>, Project<''>]
        : undefined /** not documented */
    onboarding_steps: EXTRA_FIELDS extends 'onboarding_steps'
        ? any | undefined
        : undefined /** not documented */
}

export type ProjectList<EXTRA_FIELDS extends string> = {
    projects: Project<EXTRA_FIELDS>[]
}

export enum SelfProjectPermission {
    UPDATE = 'update',
    READ = 'read'
}

export enum NewsProjectPermission {
    CREATE = 'create',
    READ = 'read'
}

export type ProjectPermissions = {
    self: [SelfProjectPermission] | [SelfProjectPermission, SelfProjectPermission] | null
    news: [NewsProjectPermission] | [NewsProjectPermission, NewsProjectPermission] | null
}

export enum AnswerCode {
    ACCEPTED = 'accepted',
    NEED_MODERATION = 'need-moderation'
}

export enum CommentsPermission {
    EVERYONE = 'everyone',
    SUPPORTERS = 'supporters',
    NOONE = 'disabled'
}

export type Billing = {
    invoice: Invoice | undefined
    payment_status: PaymentStatus
}

export enum ProjectStatus {
    ONLINE = 'online',
    NEW = 'new',
    PENDING = 'pending',
    PENDING_OWNER = 'pending-owner',
    VALIDATED = 'validated',
    REFUSED = 'refused',
    WAITING = 'waiting',
    PENDING_FINAL_VALIDATION = 'pending-final-validation'
}

export type UpdateProject<EXTRA_FIELDS extends string = ''> = {
    onboarding_steps?: EXTRA_FIELDS extends 'onboarding_steps' ? any : undefined
}

export type ProjectExamples = {
    examples: [Project<''>, Project<''>, Project<''>]
}

export type ProjectExamplesRequest = {
    force_recompute?: boolean
    with_delay?: boolean
}

export type ProjectSubmit = {
    message?: string
}

// LINK

export type Link = {
    crawlable: boolean
    followers_count: number
    id: number
    service_type: SharingService
    title: I18n
    url: string
    views_count: number
}

export type SharingService =
    | 'bandcamp'
    | 'dailymotion'
    | 'email'
    | 'facebook'
    | 'instagram'
    | 'linkedin'
    | 'soundcloud'
    | 'spotify'
    | 'tumblr'
    | 'twitch'
    | 'twitter'
    | 'vimeo'
    | 'youtube'
    | 'website'

// NEWS

export type News<WITH_PROJECT = false, WITH_AUTHOR = false> = {
    absolute_url: string
    comments_count: number
    content: I18n
    date_creation: string
    date_publication: string
    id: number
    reserved: boolean
    resource_uri: string
    slug: string
    status: NewsStatus
    title: I18n

    author: WITH_AUTHOR extends true ? PublicUser | undefined : undefined
    project: WITH_PROJECT extends true ? Project | undefined : undefined
}

export enum NewsStatus {
    ONLINE = 'online',
    WAITING = 'waiting'
}

export type NewsList<WITH_DETAIL = false> = {
    news: News<WITH_DETAIL, WITH_DETAIL>[] | null
}

// EVENT

export enum EventType {
    NEWS = 'news',
    PERCENT_RAISED = 'percent_raised',
    FIRST_ORDER = 'first_order',
    PROJECT_START = 'project_start',
    PROJECT_END_EXTRATIME = 'project_end_extra_time',
    PROJECT_END = 'project_end',
    SPONSORSHIP = 'sponsorship'
}

export type EventNews = {
    data: News<false, true>
    time: string
    type: EventType.NEWS
}

export type EventPercentRaised = {
    data: {
        percent: number
    }
    time: string
    type: EventType.PERCENT_RAISED
}

export type EventFirstOrder = {
    data: Order
    time: string
    type: EventType.FIRST_ORDER
}

export type EventProjectStart = {
    time: string
    type: EventType.PROJECT_START
}

export enum GoalStatus {
    SUCCESS = 'success',
    FAILED = 'failed'
}

export type EventProjectEnd = {
    data: {
        goal_status: GoalStatus
        has_extra_time: boolean
    }
    time: string
    type: EventType.PROJECT_END
}

export type EventProjectEndExtraTime = {
    time: string
    type: EventType.PROJECT_END_EXTRATIME
}

export type EventSponsorship = {
    data: Sponsorship
    time: string
    type: EventType.SPONSORSHIP
}

export type Event =
    | EventNews
    | EventPercentRaised
    | EventFirstOrder
    | EventProjectStart
    | EventProjectEnd
    | EventSponsorship

export type EventList = {
    draft_news?: News<false, true>[]
    events: Event[]
}

// SDG

export type SDG<WITH_TARGET = false> = {
    code: string
    description: I18n
    logo: I18n<ImageResource>
    title: I18n
    targets: WITH_TARGET extends true ? SDG[] : undefined
}

export type ProjectSDG = {
    id: number
    description: I18n
    sdg: SDG
}

export type Impact = {
    certifications: I18n
    challenges: I18n
    impact: I18n
    sdgs?: ProjectSDG[]
}

export type UpdateImpact = Omit<Impact, 'sdgs'> & {
    create_sdgs: {
        description: I18n
        sdg_code: string
    }[]
    update_sdgs: {
        description: I18n
        id: number
    }[]
    delete_sdgs: number[]
}

export type SDGList = {
    sdgs: SDG<true>[]
}

// SEARCH
export type QualityScore = 'A' | 'B' | 'C' | 'X'

export type SearchQualifier = {
    channel_ids?: number[]
    tag_id?: number
    status?: 'currently' | 'all' | 'success' | 'ended'
    country?: string
    lang?: string
    region_id?: number
    city_id?: number
    owner_id?: number
    partners?: string[]
    quality_score?: QualityScore[]
    sort?: 'popular' | 'amount' | 'ending-soon' | 'position' | 'new' | 'staff-pick'
}

// COMMENT

export type CreateComment = {
    comment: string
}

export type Comment<IS_ROOT = false> = {
    comment: string
    id: number
    is_public: boolean
    submit_date: string
    submit_date_formatted: string
    user: AuthenticatedUser & { role: Role } // TODO: Update to PublicUser

    replies_count: IS_ROOT extends true ? number : undefined
    replies: IS_ROOT extends true ? Comment[] | undefined : undefined
}

export type CommentList<IS_ROOT> = {
    comments: Comment<IS_ROOT>[]
}

// ADDRESS

export type BaseAddress = {
    address1: string
    address2: string
    city: string
    country: string
    postal_code: string
    state: string
}

type EntityAddress = BaseAddress & {
    entity_name: string
    first_name?: string
    last_name?: string
    type: 'business' | 'association'
}

type PersonalAddress = BaseAddress & {
    type: 'personal'
    first_name: string
    last_name: string
    entity_name?: string
}

export type Address<EXTRA_FIELDS extends string = ''> = (
    | EntityAddress
    | PersonalAddress
) & {
    id: number
    phone_number: string
    user_id: number
    has_shippings: EXTRA_FIELDS extends 'has_shippings' ? boolean : undefined
}

export type UpdateAddress = Partial<
    DistributiveOmit<Address, 'id' | 'user_id' | 'has_shippings'>
>

export type CreateAddress = PartialSome<
    DistributiveOmit<Address, 'id' | 'user_id' | 'has_shippings'>,
    'address2' | 'phone_number' | 'state'
>

export type Addresses<EXTRA_FIELDS extends string = ''> = {
    addresses: Address<EXTRA_FIELDS>[]
}

// ANALYTICS

export type AnalyticsTag = {
    id: number
    tag: string
    type: 'facebook' | 'google' | 'twitter'
}

export type AnalyticsTags = {
    analytics: AnalyticsTag[]
}

// DISCUSSIONS

export type UserDiscussionsStats = {
    unread_count: number
}

// ORDER

export type CreateOrder = {
    amount?: number
    payment_method: string
    pickup_point_id?: number
    return_url: string
    rewards?: RewardPayload[]
    tracking?: { [property: string]: string } // Undocumented on purpose, for internal use only
} & ({ shipping_address?: CreateAddress } | { shipping_address_id?: number }) &
    ({ billing_address?: CreateAddress } | { billing_address_id?: number })

type PropagateExtraField<
    EXTRA_FIELDS extends string,
    KEY extends string
> = EXTRA_FIELDS extends `${KEY}.${infer REST}` ? REST : never

export type Order<EXTRA_FIELDS extends string = ''> = {
    absolute_url: string | undefined
    billing_address?: Address | undefined
    created_at: string
    discount_total?: number
    edit_url: string | undefined
    id: number
    items?: OrderItem<PropagateExtraField<EXTRA_FIELDS, 'items'>>[]
    note: string | undefined
    order_shipping_total: number
    order_subtotal: number
    order_total: number
    payment_method: PaymentMethod
    payment_url: string
    pickup_point?: PickupPoint | undefined
    project?: Project
    project_id: number
    refunded: boolean
    resource_uri: string
    shipping_address?: Address | undefined
    shipping_type?: OrderShippingType | undefined
    status:
        | 'processing'
        | 'awaiting-confirmation'
        | 'payment-completed'
        | 'cancelled'
        | 'payment-done'
        | 'payment-invalid'
        | 'payment-reimbursed'
        | 'error'
    tip: number
    user?: AuthenticatedUser | undefined

    renew_url?: string /** not documented */
    shopify_order_number?: number /** not documented, bob specific */
    shopify_order_id?: number /** not documented, bob specific */

    children: 'children' extends EXTRA_FIELDS
        ? ChildOrder<PropagateExtraField<Exclude<EXTRA_FIELDS, 'children'>, 'children'>>[]
        : undefined /** not documented, bob specific */

    shipping_trackings: 'shipping_trackings' extends EXTRA_FIELDS
        ? OrderShippingTracking[]
        : undefined
    discount?: EXTRA_FIELDS extends 'discount'
        ?
              | {
                    code: string
                }
              | undefined
        : undefined
}

export type ChildOrder<EXTRA_FIELDS extends string = ''> = Omit<
    Order<EXTRA_FIELDS>,
    'project'
> & {
    project: Project
    parent?: Order
    has_expired_return_deadline?: boolean /** not documented, bob specific */

    /** not documented, bob specific */
    returns: 'returns' extends EXTRA_FIELDS ? Omit<OrderReturn, 'order'>[] : undefined
}

export type OrderList<EXTRA_FIELDS extends string = ''> = {
    orders: Order<EXTRA_FIELDS>[]
}

// TODO: merge with ShippingTracking
export type OrderShippingTracking = {
    created_at: string
    items?: {
        order_item: Omit<OrderItem, 'reward'> & {
            reward: Variant
        }
        quantity: number
    }[]
    status: ShippingTrackingStatus
    tracking_company?: string
    tracking_number?: string
    tracking_url?: string
}

export enum OrderShippingType {
    NO_SHIPPING = 'no_shipping',
    USER_ADDRESS = 'user_address',
    PICKUP_POINT = 'pickup_point'
}

export enum PaymentMethod {
    BANKWIRE = 'bankwire',
    CHECK = 'check',
    CREDITCARD = 'creditcard',
    DIRECTDEBIT = 'directdebit',
    IDEAL = 'ideal',
    MAESTRO = 'maestro',
    PAYLIB = 'paylib',
    PAYPAL = 'paypal',
    SAVING = 'saving'
}

export type OrderItem<EXTRA_FIELDS extends string = ''> = {
    discount_total: number
    fulfillment_status?: ItemFulfillmentStatus /** not documented, bob specific */
    line_shipping_total: number
    line_subtotal: number
    line_total: number
    quantity: number
    reward:
        | Reward<PropagateExtraField<EXTRA_FIELDS, 'reward'>>
        | Variant<PropagateExtraField<EXTRA_FIELDS, 'reward'>>
    reward_id: number
    unit_price: number

    discount?: EXTRA_FIELDS extends 'discount'
        ?
              | {
                    code: string
                }
              | undefined
        : undefined
}

export enum ItemFulfillmentStatus {
    PARTIAL = 'partial',
    FULFILLED = 'fulfilled'
}

// Return
export type ReturnList = {
    returns: OrderReturn[]
}

// REWARD

export type Reward<EXTRA_FIELDS extends string = ''> = {
    available: boolean
    delivery?: Delivery
    description: I18n
    has_custom_delivery: boolean
    id: number
    image?: I18n<ImageResource>
    is_featured: boolean
    is_hidden: boolean
    is_returnable?: boolean /** not documented, bob specific */
    is_virtual?: boolean /** not documented, bob specific */
    num_products?: number
    price: number
    project?: Project
    project_id: number
    resource_uri: string
    stock: number | null
    stock_available: number | null
    shopify_handle?: string /** not documented, bob specific */
    shopify_product_id?: number /** not documented, bob specific */
    stock_taken: number
    tags?: string[] /** not documented, bob specific */
    title: I18n
    variants?: Variant<EXTRA_FIELDS>[]
}

export type RewardPayload = {
    reward_id: number
    quantity: number
}

export type Variant<EXTRA_FIELDS extends string = ''> = {
    available: boolean
    description: I18n
    id: number
    image?: I18n<ImageResource>
    is_virtual?: boolean /** not documented, bob specific */
    parent?: Reward<EXTRA_FIELDS>
    price?: number /** not documented, bob specific */
    stock: number | null
    stock_available: number | null
    stock_taken: number
    title?: I18n /** not documented, bob specific */

    user_review?: EXTRA_FIELDS extends 'user_review'
        ? Review
        : undefined /** not documented, bob specific */
}

// SHIPPING

export enum ShippingType {
    NONE = 'none',
    NATIONAL_ONLY = 'national-only',
    NATIONAL_AND_SOME_COUNTRIES = 'national-and-some-countries',
    WORLDWIDE = 'worldwide'
}

export type Delivery = {
    address_required: boolean
    date_delivery: string | undefined
    force_address_required?: boolean
    force_phone_number_required?: boolean
    has_shippings: boolean
    phone_number_required: boolean
    pickup_points?: PickupPoint[]
    shipping_int: number | undefined
    shipping_nat: number | undefined
    shipping_type: ShippingType | undefined
    shippings?: Shipping[]
}

export type PickupPoint = {
    address: Address
    description: I18n
    name: I18n
    raw_address: I18n
    id: number
}

export type Shipping = {
    amount: number
    countries: Array<string> | null
    id: number
    zone?: string | null
}

// SHIPPING RATES (bob specific)

export type ShippingRateRequest = {
    country: string
    items: { quantity: number; shopify_variant_id: number }[]
}

export type ShippingRateList = {
    rates: {
        free_shipping_threshold: number | null
        name: string
        price: number
        project: Project
    }[]
    supported_countries: string[]
}

// PARTNERSHIP

export type Partnership = {
    id: number
    is_default: boolean
    is_support: boolean
    is_winner?: boolean /** not documented */
    partner: Partner
    project: Project
}

export type Partner = {
    id: number
    logo: ImageResource
    name: string
    ribbon?: ImageResource
    ribbon_winner?: ImageResource /** not documented */
    slug: string
    url: string
    user_id: number
}

// SPONSORSHIP

export enum SponsorshipType {
    AMOUNT = 'amount',
    PRIZE_WINNER = 'prize_winner',
    DOUBLED_DONATIONS = 'doubled_donations',
    CRUSH = 'crush',
    AUDIENCE = 'audience'
}

export enum SponsorshipMode {
    PARTICIPATES_IN = 'participates_in',
    SUPPORTED_BY = 'supported_by',
    PRIZE_WINNER = 'prize_winner',
    CRUSH = 'crush',
    COACHED_BY = 'coached_by'
}

export type Sponsorship<EXTRA_FIELDS extends string = ''> = {
    amount: number
    coefficient: number
    description: I18n
    id: number
    is_full: boolean
    main_color: string
    mode: SponsorshipMode
    priority: number
    project_id: number
    sponsor: Sponsor
    type?: SponsorshipType

    channel: EXTRA_FIELDS extends 'channel' ? Channel | undefined : undefined
}

export type Sponsor = {
    absolute_url: string
    id: number
    image: ImageResource
    link: string
    name: string
    position: number
    type: string
    user: PublicUser
}

// FAQ

export type CreateFaq = {
    question: I18n
}

export type Faq = {
    answer_author: PublicUser
    answer: I18n
    id: number
    question_author: PublicUser
    question: I18n
}

export type FaqList = {
    faq: Faq[]
}

// CHANNEL

export type Channel = {
    absolute_url: string
    background: ImageResource | undefined
    cta_background: ImageResource | undefined
    description: I18n
    name: I18n
    partner: Partner
    user: PublicUser
}

export type ChannelList = {
    channels: Channel[]
}

// PASSWORD

export type PasswordStrength = 'worst' | 'bad' | 'weak' | 'good' | 'strong'

// THREADS

export type Thread = {
    created_at: string
    id: number
    latest_message: Message
    messages_count: number
    metadata: any
    recipients_count: number
    recipients: PublicUser[]
    sender_deleted_at: string | null
    sender_id: number
    status: ThreadStatus
    subject: string
    updated_at: string
}

export enum ThreadStatus {
    UNREAD = 'unread',
    READ = 'read',
    DELETED = 'deleted'
}

export type Message = {
    attachments: Attachment[]
    body: string
    body_html: string
    id: number
    sender_deleted_at: string | null
    sender: PublicUser
    sent_at: string
    status: MessageStatus
    thread_id: number
}

export enum MessageStatus {
    DRAFT = 'draft',
    SENT = 'sent'
}

export type Attachment = {
    created_at: string
    file: string
    id: number
    name: string
}

// REVIEWS

export type CreateReview = {
    name: string
    comment: string
    score: number
}

export type UpdateReview = Partial<CreateReview>

export type Review = {
    comment: string
    created_at: string
    id: number
    is_highlighted: boolean
    name: string
    order: Order
    project?: Project<''>
    reward?: Variant
    score: number
}

export type ReviewList = {
    reviews: Review[]
}

export type ProductReviews = {
    avg_score?: number
    reviews: Review[]
}

// RETURN

export enum ReturnType {
    EXCHANGE = 'exchange',
    REFUND = 'refund',
    NEW_SHIPMENT = 'new_shipment'
}

export enum ReturnReasonType {
    CANCEL = 'cancel',
    WRONG_ITEM = 'wrong_item',
    DEFECTIVE_OR_DAMAGED_ITEM = 'defective_or_damaged_item',
    INCOMPLETE_ITEM = 'incomplete_item',
    MISSING_ITEM = 'missing_item',
    WRONG_DESCRIPTION = 'wrong_description',
    PURCHASE_MADE_BY_MISTAKE = 'purchase_made_by_mistake',
    SIZE_OR_COLOR_PROBLEM = 'size_or_color_problem',
    DOES_NOT_MEET_EXPECTATIONS = 'does_not_meet_expectations',
    CHANGE_OF_MIND = 'change_of_mind'
}

type CreateReturnItem = {
    reward_id: number
    type: ReturnType
    reason_type: ReturnReasonType
    quantity: number
    explanation?: string
    image_ids: number[]
}

export type CreateReturn = {
    dry_run: boolean
    items: CreateReturnItem[]
}

export enum ReturnShippingFeePolicy {
    OWNER = 'owner',
    CUSTOMER = 'customer'
}

export enum ReturnStatus {
    WAITING_APPROVAL = 'waiting-approval',
    ACCEPTED = 'accepted',
    REFUSED = 'refused'
}

export type OrderReturn = {
    accepted_at?: string
    answer?: string
    created_at: string
    closed_at?: string
    is_closed: boolean
    is_refunded?: boolean
    id: number
    items: OrderReturnItem[]
    order: ChildOrder<'items.discount'>
    refund_discount_total: number
    refund_shipping_total: number
    refund_subtotal: number
    refund_total: number
    refunded_at?: string
    refusal_type?: ReturnRefusalType
    refused_at?: string
    shipping_fees_policy: ReturnShippingFeePolicy
    shipping_total?: number
    shipping_tracking?: ShippingTracking
    status: ReturnStatus
}

export enum ShippingTrackingStatus {
    WAITING_SHIPPING = 'waiting-shipping',
    IN_TRANSIT = 'in-transit',
    DELIVERED = 'delivered',
    INFO_RECEIVED = 'info-received',
    FAILED_ATTEMPT = 'failed-attempt',
    RETURNED = 'returned',
    EXPIRED = 'expired'
}

export type ShippingTracking = {
    created_at: string
    label?: ImageResource
    status: ShippingTrackingStatus
    tracking_url?: string
}

export type OrderReturnItem = {
    images?: ImageResource[]
    explanation?: string
    line_subtotal: number
    quantity: number
    reason_type: ReturnReasonType
    reward: Reward
    type: ReturnType
    unit_price: number
}

export enum DashboardReturnRefusalType {
    CANCEL = 'cancel'
}

export enum ReturnRefusalType {
    EXPIRED = 'expired',
    VENDOR = 'refused_by_vendor'
}

// IMAGES

export enum ImageType {
    MAIN = 'main',
    SECONDARY = 'secondary',
    BACKGROUND = 'background',
    REWARD = 'reward',
    SHIPPING_LABEL = 'shipping-label' /* bob specific */,
    RETURN = 'return' /* bob specific */
}

export type CreateImage = {
    image: File
    lang: Lang
} & (
    | {
          type: ImageType.BACKGROUND
          color: string
      }
    | {
          type:
              | ImageType.MAIN
              | ImageType.SECONDARY
              | ImageType.BACKGROUND
              | ImageType.REWARD
              | ImageType.RETURN
              | ImageType.SHIPPING_LABEL
      }
)

// ENDPOINTS

const endpoints = {
    me: {
        url: '/me',
        methods: [
            {
                method: 'GET',
                options: { withToken: true }
            }
        ]
    } as Endpoint,
    myDiscussionsStats: {
        methods: [{ method: 'GET', options: { withToken: true } }],
        url: '/discussions/me'
    } as Endpoint,
    user: {
        url: ({ userId }) => `/users/${userId}`,
        methods: [
            {
                method: 'PATCH',
                options: { withToken: true, type: 'json' }
            },
            {
                method: 'GET',
                options: { type: 'json' }
            }
        ]
    } as Endpoint,
    projectAnalytics: {
        url: ({ projectId }) => `/projects/${projectId}/analytics`,
        methods: [
            // { method: 'DELETE', options: { withToken: true } },
            // { method: 'PATCH', options: { withToken: true } },
            // { method: 'POST', options: { withToken: true } },
            { method: 'GET', options: { withToken: true } }
        ]
    } as Endpoint,
    userAddresses: {
        url: ({ userId }) => `/users/${userId}/addresses`,
        methods: [
            {
                method: 'GET',
                options: { withToken: true }
            },
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    userImage: {
        url: ({ userId }) => `/users/${userId}/images`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'formdata' }
            }
        ]
    } as Endpoint,
    userOrders: {
        url: ({ userId }) => `/users/${userId}/orders`,
        methods: [
            {
                method: 'GET',
                options: { withToken: true }
            }
        ]
    } as Endpoint,
    userReviews: {
        url: ({ userId }) => `/users/${userId}/reviews`,
        methods: [
            {
                method: 'GET',
                options: { withToken: true }
            }
        ]
    } as Endpoint,
    addresses: {
        url: ({ addressId }) => `/addresses/${addressId}`,
        methods: [
            {
                method: 'GET',
                options: { withToken: true }
            },
            {
                method: 'PATCH',
                options: { withToken: true, type: 'json' }
            },
            {
                method: 'DELETE',
                options: { withToken: true }
            }
        ]
    } as Endpoint,
    commentReplies: {
        url: ({ commentId }) => `/comments/${commentId}/replies`,
        methods: [
            {
                method: 'GET'
            },
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    channels: {
        url: () => `/channels`,
        methods: [
            {
                method: 'GET'
            }
        ]
    } as Endpoint,
    likeProject: {
        url: ({ projectId }) => `/projects/${projectId}/like`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true }
            }
        ]
    } as Endpoint,
    project: {
        url: ({ projectId }) => `/projects/${projectId}`,
        methods: [
            {
                method: 'GET'
            },
            {
                method: 'PATCH',
                options: { type: 'json', withToken: true }
            }
        ]
    } as Endpoint,
    projectFaq: {
        url: ({ projectId }) => `/projects/${projectId}/faq`,
        methods: [
            {
                method: 'GET',
                options: { type: 'json' }
            }
        ]
    } as Endpoint,
    projectImpact: {
        url: ({ projectId }) => `/projects/${projectId}/impact`,
        methods: [
            {
                method: 'GET',
                options: { type: 'json' }
            },
            {
                method: 'PATCH',
                options: { type: 'json', withToken: true }
            }
        ]
    } as Endpoint,
    projectRewards: {
        url: ({ projectId }) => `/projects/${projectId}/rewards`,
        methods: [{ method: 'GET' }]
    } as Endpoint,
    projectComment: {
        url: ({ projectId }) => `/projects/${projectId}/comments`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            },
            {
                method: 'GET'
            }
        ]
    } as Endpoint,
    projectComputeExamples: {
        url: ({ projectId }) => `/projects/${projectId}/compute-examples`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    projectSubmit: {
        url: ({ projectId }) => `/projects/${projectId}/submit`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    createProjectFaq: {
        url: ({ projectId }) => `/projects/${projectId}/faq`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    projectOrder: {
        url: ({ projectId }) => `/projects/${projectId}/orders`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    officialUsers: {
        url: `/officialusers`,
        methods: [
            {
                method: 'GET'
            }
        ]
    } as Endpoint,
    order: {
        url: ({ orderId }) => `/orders/${orderId}`,
        methods: [
            {
                method: 'GET',
                options: { withToken: true }
            }
        ]
    } as Endpoint,
    passwordScore: {
        url: '/passwords/score',
        methods: [
            {
                method: 'POST',
                options: { type: 'json' }
            }
        ]
    } as Endpoint,
    renewOrder: {
        url: ({ orderId }) => `/orders/${orderId}/renew`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    projectFan: {
        url: ({ projectId }) => `/projects/${projectId}/fans`,
        methods: [
            {
                method: 'GET',
                options: { type: 'json' }
            }
        ]
    } as Endpoint,
    projectSupporter: {
        url: ({ projectId }) => `/projects/${projectId}/supporters`,
        methods: [
            {
                method: 'GET',
                options: { type: 'json' }
            }
        ]
    } as Endpoint,
    rewardReview: {
        url: ({ variantId }) => `/rewards/${variantId}/reviews`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    review: {
        url: ({ reviewId }) => `/reviews/${reviewId}`,
        methods: [
            {
                method: 'PATCH',
                options: { withToken: true, type: 'json' }
            },
            {
                method: 'DELETE',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    reward: {
        url: ({ rewardId }) => `/rewards/${rewardId}`,
        methods: [
            {
                method: 'GET',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    marketplaceProductReview: {
        url: ({ productId }) => `/rewards/shopify-${productId}/reviews`,
        methods: [
            {
                method: 'GET',
                options: { type: 'json' }
            }
        ]
    } as Endpoint,
    unlikeProject: {
        url: ({ projectId }) => `/projects/${projectId}/unlike`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true }
            }
        ]
    } as Endpoint,
    sdgs: {
        url: '/sdgs',
        methods: [
            {
                method: 'GET',
                options: { type: 'json' }
            }
        ]
    } as Endpoint,
    comment: {
        url: ({ commentId }) => `/comments/${commentId}`,
        methods: [
            {
                method: 'DELETE',
                options: { withToken: true } // only accessible to staff
            }
        ]
    } as Endpoint,
    thread: {
        url: ({ threadId }) => `/threads/${threadId}`,
        methods: [
            {
                method: 'GET',
                options: { withToken: true }
            }
        ]
    } as Endpoint,
    projectNews: {
        url: ({ projectId }) => `/projects/${projectId}/news`,
        methods: [
            {
                method: 'GET'
            }
        ]
    } as Endpoint,
    news: {
        url: ({ newsId }) => `/news/${newsId}`,
        methods: [
            {
                method: 'GET'
            }
        ]
    } as Endpoint,
    newsComment: {
        url: ({ newsId }) => `/news/${newsId}/comments`,
        methods: [
            {
                method: 'GET'
            },
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    search: {
        url: '/search/projects',
        methods: [
            {
                method: 'GET'
            }
        ]
    } as Endpoint,
    projectEvents: {
        url: ({ projectId }) => `/projects/${projectId}/events`,
        methods: [
            {
                method: 'GET'
            }
        ]
    } as Endpoint,
    orderReturn: {
        url: ({ orderId }) => `/orders/${orderId}/returns`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    returns: {
        url: () => `/returns`,
        methods: [
            {
                method: 'GET',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    returnDetail: {
        url: ({ id }) => `/returns/${id}`,
        methods: [
            {
                method: 'GET',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    returnAccept: {
        url: ({ id }) => `/returns/${id}/accept`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    returnRefuse: {
        url: ({ id }) => `/returns/${id}/refuse`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    returnOpen: {
        url: ({ id }) => `/returns/${id}/open`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    returnClose: {
        url: ({ id }) => `/returns/${id}/close`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    returnRefund: {
        url: ({ id }) => `/returns/${id}/refund`,
        methods: [
            {
                method: 'POST',
                options: { withToken: true, type: 'json' }
            }
        ]
    } as Endpoint,
    shippingRates: {
        url: '/shipping-rates',
        methods: [
            {
                method: 'POST',
                options: { type: 'json' }
            }
        ]
    } as Endpoint,
    subscribeNewsLetter: {
        url: '/newsletters/monthly',
        methods: [
            {
                method: 'POST',
                options: { type: 'json' }
            }
        ]
    } as Endpoint,
    variant: {
        url: ({ variantId }) => `/rewards/${variantId}`,
        methods: [
            {
                method: 'GET',
                options: { type: 'json', withToken: true }
            }
        ]
    } as Endpoint
}

export type Api = {
    delete: BaseMethod & {
        addresses: ClientMethod<void, void, 'addressId'>
        comment: ClientMethod<void, void, 'commentId'>
        review: ClientMethod<void, void, 'reviewId'>
    }
    get: BaseMethod & {
        addresses: ClientMethod<undefined, Address, 'addressId'>
        channels: ClientMethod<undefined, Paginable<ChannelList>, never>
        commentReplies: ClientMethod<
            undefined,
            Paginable<CommentList<false>>,
            'commentId'
        >
        marketplaceProductReview: ClientMethod<
            undefined,
            Paginable<ProductReviews>,
            'productId'
        >
        me: ClientMethod<undefined, AuthenticatedUser<string>>
        myDiscussionsStats: ClientMethod<undefined, UserDiscussionsStats>
        news: ClientMethod<undefined, News<true, true>, 'newsId'>
        newsComment: ClientMethod<undefined, Paginable<CommentList<true>>, 'newsId'>
        officialUsers: ClientMethod<undefined, Paginable<PublicUserList>>
        order: ClientMethod<undefined, Order<string>, 'orderId'>
        project: ClientMethod<undefined, Project<string>, 'projectId'>
        projectAnalytics: ClientMethod<undefined, AnalyticsTags, 'projectId'>
        projectComment: ClientMethod<undefined, Paginable<CommentList<true>>, 'projectId'>
        projectEvents: ClientMethod<undefined, Paginable<EventList>, 'projectId'>
        projectFan: ClientMethod<undefined, Paginable<FanList>, 'projectId'>
        projectFaq: ClientMethod<undefined, Paginable<FaqList>, 'projectId'>
        projectImpact: ClientMethod<undefined, Impact, 'projectId'>
        projectNews: ClientMethod<undefined, Paginable<NewsList<true>>, 'projectId'>
        projectRewards: ClientMethod<undefined, Reward[], 'projectId'>
        reward: ClientMethod<undefined, Reward<any>, 'rewardId'>
        variant: ClientMethod<undefined, Variant<any>, 'variantId'>
        search: ClientMethod<undefined, Paginable<ProjectList<string>>>
        sdgs: ClientMethod<undefined, SDGList, never>
        projectSupporter: ClientMethod<
            undefined,
            Paginable<SupporterList<string>>,
            'projectId'
        >
        returns: ClientMethod<undefined, Paginable<ReturnList>>
        returnDetail: ClientMethod<undefined, OrderReturn, 'id'>
        thread: ClientMethod<undefined, Thread, 'threadId'>
        user: ClientMethod<undefined, PublicUser, 'userId'>
        userAddresses: ClientMethod<undefined, Addresses<string>, 'userId'>
        userOrders: ClientMethod<undefined, Paginable<OrderList<any>>, 'userId'>
        userReviews: ClientMethod<undefined, Paginable<ReviewList>, 'userId'>
    }
    patch: BaseMethod & {
        addresses: ClientMethod<UpdateAddress, Address<string>, 'addressId'>
        project: ClientMethod<UpdateProject, Project<string>, 'projectId'>
        projectImpact: ClientMethod<UpdateImpact, Impact, 'projectId'>
        review: ClientMethod<UpdateReview, Review, 'reviewId'>
        user: ClientMethod<UpdateUser, AuthenticatedUser, 'userId'>
    }
    post: BaseMethod & {
        returnAccept: ClientMethod<{ image_id?: number }, OrderReturn, 'id'>
        returnRefuse: ClientMethod<
            { answer: string; refusal_type?: DashboardReturnRefusalType },
            OrderReturn,
            'id'
        >
        returnOpen: ClientMethod<undefined, OrderReturn, 'id'>
        returnClose: ClientMethod<undefined, OrderReturn, 'id'>
        returnRefund: ClientMethod<undefined, OrderReturn, 'id'>
        commentReplies: ClientMethod<CreateComment, Comment, 'commentId'>
        createProjectFaq: ClientMethod<CreateFaq, Faq, 'projectId'>
        likeProject: ClientMethod<unknown, void, 'projectId'>
        newsComment: ClientMethod<CreateComment, Comment<true>, 'newsId'>
        orderReturn: ClientMethod<CreateReturn, OrderReturn, 'orderId'>
        passwordScore: ClientMethod<{ password: string }, { score: PasswordStrength }>
        projectComment: ClientMethod<CreateComment, Comment<true>, 'projectId'>
        projectComputeExamples: ClientMethod<
            ProjectExamplesRequest,
            ProjectExamples | undefined,
            'projectId'
        >
        projectOrder: ClientMethod<CreateOrder, Order, 'projectId'>
        projectSubmit: ClientMethod<ProjectSubmit, void, 'projectId'>
        renewOrder: ClientMethod<Order, Order, 'orderId'>
        rewardReview: ClientMethod<CreateReview, Review, 'variantId'>
        shippingRates: ClientMethod<ShippingRateRequest, ShippingRateList, never>
        subscribeNewsLetter: ClientMethod<{
            country: string
            email: string
            lang: string
        }>
        unlikeProject: ClientMethod<unknown, void, 'projectId'>
        userAddresses: ClientMethod<CreateAddress, Address<string>, 'userId'>
        userImage: ClientMethod<
            CreateImage,
            { id: number; versions?: Record<string, ImageVersion> },
            'userId'
        >
    }
    put: BaseMethod
    oauth2: OAuth2
    endpoints: typeof endpoints
}

export const api: Api = client({
    endpoints,
    options: {
        version: '2020-11-10'
    },
    rootUrl: `${process.env.NEXT_PUBLIC_ULULE_API_URL}/v1`,
    request: request
}) as any
