import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type NotificationBannerProps = {
    children: React.ReactNode
} & S.notificationBanner.WrapperProps

function NotificationBannerComponent({
    children,
    ...rest
}: NotificationBannerProps): React.ReactElement {
    return (
        <S.notificationBanner.Wrapper {...rest}>{children}</S.notificationBanner.Wrapper>
    )
}

export const NotificationBanner = styled(NotificationBannerComponent)``
