import React from 'react'
import styled from 'styled-components'

import { BlobPicture } from '../BlobPicture'

import * as S from '../../styles'
import { CartItemSkeleton } from '../CartItem'

export type CartCardProps = {
    children: React.ReactNode
    className?: string
    banner?: React.ReactNode
    logo: {
        alt: string | null
        height: number | null
        src: string
        width: number | null
    }
    title: string
    total: string
}

function CartCardComponent({
    className,
    logo,
    banner,
    title,
    children,
    total
}: CartCardProps): React.ReactElement {
    return (
        <S.card.cart.Wrapper className={className}>
            <S.card.cart.Outer hasBanner={!!banner}>
                <S.card.cart.Header>
                    <BlobPicture
                        alt={logo.alt ?? title}
                        color="light-green"
                        size={80}
                        sizing="cover"
                        src={logo.src}
                        variant={2}
                    />
                    <S.card.cart.Title>{title}</S.card.cart.Title>
                </S.card.cart.Header>
                <S.card.cart.Body>
                    {React.Children.map(children, child => {
                        return child
                    })}
                </S.card.cart.Body>
                <S.card.cart.Footer>
                    <S.copy.Text5>Total vendeur</S.copy.Text5>
                    <S.heading.TitleCardArticle>{total}</S.heading.TitleCardArticle>
                </S.card.cart.Footer>
            </S.card.cart.Outer>
            {banner}
        </S.card.cart.Wrapper>
    )
}

export const CartCard = styled(CartCardComponent)``

type CartCardSkeletonProps = {
    className?: string
}

function CartCardSkeletonComponent({ className }: CartCardSkeletonProps) {
    return (
        <S.card.cart.Wrapper className={className}>
            <S.card.cart.Outer hasBanner={false}>
                <S.card.cart.Header>
                    <S.skeleton.Circle radius={80} />
                    <S.skeleton.Text size="medium" width={20} />
                </S.card.cart.Header>
                <S.card.cart.Body>
                    <CartItemSkeleton />
                </S.card.cart.Body>
                <S.card.cart.Footer>
                    <S.skeleton.Text size="small" width={10} />
                    <S.skeleton.Text size="small" width={10} />
                </S.card.cart.Footer>
            </S.card.cart.Outer>
        </S.card.cart.Wrapper>
    )
}

export const CartCardSkeleton = styled(CartCardSkeletonComponent)`
    ${S.card.cart.Header} {
        ${S.skeleton.Circle} {
            flex: 0 0 auto;
            margin: 10px;
        }
        ${S.skeleton.Text} {
            flex: 0 0 auto;
        }
    }

    ${S.cartItem.Controls} {
        ${S.skeleton.Circle} {
            width: 34px;
        }
        ${S.skeleton.Text} {
            width: 20px;
            margin: 7px 7px;
        }
    }
`
