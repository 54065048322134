import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-search-def'

FullSearch.filename = SYMBOL_ID
export function FullSearch(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="search">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.7959 17.3237 14.4339 16.2118 15.6731L15.6731 16.2118C14.4339 17.3237 12.7959 18 11 18C7.13401 18 4 14.866 4 11ZM16.6177 18.0319C15.078 19.2635 13.125 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.125 19.2635 15.078 18.0319 16.6177L22.4138 20.9996L20.9996 22.4138L16.6177 18.0319Z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const Search = styled(function Search(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="search">
            <use href={href} />
        </icon.Icon>
    )
})``
