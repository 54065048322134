import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as blob from '../Blob'

const SYMBOL_ID = 'blob-4-def'

FullBlob4.filename = SYMBOL_ID
export function FullBlob4(props: blob.SpecificIconProps): React.ReactElement {
    return (
        <blob.Blob {...props} viewBox="0 0 99 74" name="blob-4">
            <defs>
                <path
                    id={SYMBOL_ID}
                    d="m92.369 8.2082c7.1404 5.7314 8.1077 17.576 4.8346 28.084-3.2726 10.508-10.785 19.741-22.909 27.128-12.124 7.4508-28.785 13.182-43.214 9.3613s-26.628-17.194-30.049-31.777c-3.4214-14.52 1.8595-30.376 12.942-36.872 11.082-6.4956 27.892-3.6936 42.917-2.4836 15.099 1.21 28.338 0.82787 35.479 6.5592z"
                />
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </blob.Blob>
    )
}

export const Blob4 = styled(function Blob4(
    props: blob.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <blob.Blob {...props} viewBox="0 0 99 74" name="blob-4">
            <use href={href} />
        </blob.Blob>
    )
})``
