import * as React from 'react'

import { useLocalStorage } from '@bob/localstorage'
import * as entity from '@bob/entities'
import * as core from '@bob/core-services'
import { getStoredUTM } from '@bob/utm'
import { useToast } from '@bob/toasts'

import { Cart, cartContext } from './cartContext'

const CHECKOUT_ID_LOCAL_STORAGE_KEY = 'bob_checkout_id'
const CHECKOUT_LOCAL_STORAGE_KEY = 'bob_checkout_content'

let touched = false
let hasGotCheckout = false

export function CartProvider(props: { children: React.ReactNode }) {
    const cart = useCart()
    return <cartContext.Provider value={cart}>{props.children}</cartContext.Provider>
}

function useCart(): Cart {
    const localStorageCheckoutId = useLocalStorage<string>(CHECKOUT_ID_LOCAL_STORAGE_KEY)
    const localStorageCheckout = useLocalStorage<entity.checkout.Checkout>(
        CHECKOUT_LOCAL_STORAGE_KEY
    )
    const toast = useToast()

    const [loaded, setLoaded] = React.useState(false)

    React.useEffect(() => {
        getCheckout()
    }, [localStorageCheckoutId.ready])

    React.useEffect(() => {
        if (localStorageCheckoutId.ready && localStorageCheckout.value?.completedAt) {
            localStorageCheckoutId.deleteValue()
            localStorageCheckout.deleteValue()
        }
    }, [localStorageCheckout, localStorageCheckoutId])

    if (!localStorageCheckoutId.ready || !localStorageCheckout.ready || !loaded) {
        return { ready: false, touched: false }
    }

    if (
        localStorageCheckout.value === null ||
        localStorageCheckout.value.vendors.length === 0
    ) {
        return {
            empty: true,
            data: localStorageCheckout.value ?? undefined,
            ready: true,
            touched,
            add,
            total: () => 0,
            updateAttributes
        }
    }

    return {
        data: localStorageCheckout.value,
        empty: false,
        touched,
        ready: true,
        add,
        navigateToCheckout,
        remove,
        update,
        total,
        updateAttributes
    }

    async function navigateToCheckout() {
        if (localStorageCheckout.value === null) {
            return
        }

        const checkout = await core.checkout.client.get(localStorageCheckout.value.id)
        if (checkout === null) {
            return
        }

        const cleanCheckout = await removeOfflineProducts(checkout)
        localStorageCheckout.setValue(cleanCheckout)

        if (cleanCheckout.vendors.length === 0) {
            return
        }

        const webUrl = new URL(cleanCheckout?.webUrl)
        webUrl.searchParams.append('locale', 'fr')

        const primaryUrl = process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_URL
        if (primaryUrl) {
            const shopUrl = new URL(primaryUrl)
            webUrl.hostname = shopUrl.hostname
            webUrl.protocol = shopUrl.protocol
        }

        //await clean()
        window.location.href = `${webUrl}`
    }

    async function add(lineItems: entity.checkout.PartialLineItem[]): Promise<{
        error?: entity.checkout.CheckoutError[]
        data?: entity.checkout.Checkout
    }> {
        if (!localStorageCheckoutId.ready || !localStorageCheckout.ready) {
            console.error("can't useCart.add if local storage is not ready ")
            return { error: [] }
        }

        try {
            touched = true
            if (localStorageCheckoutId.value === null) {
                const utm = getStoredUTM()

                // localstorage ready, but no checkout id. We must create it
                const checkout = await core.checkout.client.create(
                    lineItems,
                    Object.entries(utm).map(([key, value]) => ({ key, value }))
                )
                localStorageCheckoutId.setValue(checkout.id)
                localStorageCheckout.setValue(checkout)

                return { data: checkout }
            } else {
                // checkout already exists, simply add items
                const checkoutId = localStorageCheckoutId.value
                const checkout = await core.checkout.client.addLineItems(
                    checkoutId,
                    lineItems
                )

                const cleanCheckout = await removeOfflineProducts(checkout)

                localStorageCheckout.setValue(cleanCheckout)

                return { data: checkout }
            }
        } catch (error: any) {
            return { error }
        }

        return { error: [] }
    }

    async function update(
        lineItems: entity.checkout.LineItem[]
    ): Promise<entity.checkout.CheckoutError[]> {
        if (!localStorageCheckoutId.ready || !localStorageCheckout.ready) {
            console.error("can't useCart.update if local storage is not ready ")
            return []
        }

        try {
            if (localStorageCheckoutId.value === null) {
                console.error("can't useCart.update if no cart was created")
                return []
            } else {
                touched = true

                const checkoutId = localStorageCheckoutId.value
                const checkout = await core.checkout.client.updateLineItems(
                    checkoutId,
                    lineItems
                )
                const cleanCheckout = await removeOfflineProducts(checkout)

                localStorageCheckout.setValue(cleanCheckout)
            }
        } catch (error: any) {
            return error
        }

        return []
    }

    async function remove(
        lineItems: { id: string }[]
    ): Promise<entity.checkout.CheckoutError[]> {
        if (!localStorageCheckoutId.ready || !localStorageCheckout.ready) {
            console.error("can't useCart.remove if local storage is not ready ")
            return []
        }

        try {
            if (localStorageCheckoutId.value === null) {
                console.error("can't useCart.remove if no cart was created")
                return []
            } else {
                touched = true

                const checkoutId = localStorageCheckoutId.value
                const checkout = await core.checkout.client.removeLineItems(
                    checkoutId,
                    lineItems
                )
                const cleanCheckout = await removeOfflineProducts(checkout)

                localStorageCheckout.setValue(cleanCheckout)
            }
        } catch (error: any) {
            return error
        }

        return []
    }

    function total(vendor?: string | null): number {
        if (localStorageCheckout.value === null) {
            return 0
        }

        if (vendor) {
            const entry = localStorageCheckout.value.vendors.find(
                entry => entry.brand.identifier === vendor
            )
            if (entry === undefined) {
                return 0
            }
            return lineItemsTotal(entry.lineItems)
        }

        return localStorageCheckout.value.vendors.reduce((total, entry) => {
            return total + lineItemsTotal(entry.lineItems)
        }, 0)
    }

    function lineItemsTotal(lineItems: entity.checkout.LineItem[]): number {
        return lineItems.reduce((total, item) => {
            const totalDiscount = entity.checkout.getTotalDiscount(item)
            return total + item.quantity * item.variant.price.amount - totalDiscount
        }, 0)
    }

    async function getCheckout() {
        if (!localStorageCheckoutId.ready) {
            return
        }

        if (hasGotCheckout) {
            return
        }

        hasGotCheckout = true

        if (localStorageCheckoutId.ready && localStorageCheckoutId.value !== null) {
            const result = await core.checkout.client.get(localStorageCheckoutId.value)
            setLoaded(true)
            if (result !== null) {
                const cleanCheckout = await removeOfflineProducts(result)

                if (localStorageCheckout.ready) {
                    localStorageCheckout.setValue(cleanCheckout)
                }

                return cleanCheckout
            }

            toast.dispatch({
                id: Date.now(),
                children: 'Une erreur est survenue avec votre panier',
                status: 'error'
            })
            const fallbackEmptyCheckout = await core.checkout.client.create([])
            if (localStorageCheckoutId.ready) {
                localStorageCheckoutId.setValue(fallbackEmptyCheckout.id)
            }
            if (localStorageCheckout.ready) {
                localStorageCheckout.setValue(fallbackEmptyCheckout)
            }
            return fallbackEmptyCheckout
        }
        if (localStorageCheckoutId.ready) {
            setLoaded(true)
        }
    }

    async function removeOfflineProducts(
        checkout: entity.checkout.Checkout
    ): Promise<entity.checkout.Checkout> {
        const offlineLineItems = checkout.vendors.flatMap(vendor => {
            return vendor.lineItems.filter(lineItem => {
                return lineItem.variant.product.offline
            })
        })

        if (offlineLineItems.length !== 0) {
            toast.dispatch({
                id: Date.now(),
                children:
                    'Votre panier à été mis à jour car un ou plusieurs articles sont désormais indisponibles.',
                status: 'alert'
            })
            return await core.checkout.client.removeLineItems(
                checkout.id,
                offlineLineItems
            )
        }

        return checkout
    }

    async function updateAttributes(attributes: { key: string; value: string }[]) {
        if (!localStorageCheckoutId.ready || !localStorageCheckout.ready) {
            console.error("can't useCart.update if local storage is not ready ")
            return []
        }

        try {
            if (localStorageCheckoutId.value === null) {
                console.error("can't useCart.update if no cart was created")
                return []
            } else {
                touched = true

                const checkoutId = localStorageCheckoutId.value
                const checkout = await core.checkout.client.updateAttributes(
                    checkoutId,
                    attributes
                )
                const cleanCheckout = await removeOfflineProducts(checkout)

                localStorageCheckout.setValue(cleanCheckout)
            }
        } catch (error: any) {
            return error
        }

        return []
    }
}

export function useCartItemCount(): number {
    const localStorageCheckout = useLocalStorage<entity.checkout.Checkout>(
        CHECKOUT_LOCAL_STORAGE_KEY
    )

    if (!localStorageCheckout.ready || localStorageCheckout.value === null) {
        return 0
    }

    return localStorageCheckout.value.vendors.reduce((itemCount, vendor) => {
        return vendor.lineItems.reduce(
            (itemCount, lineItem) => itemCount + lineItem.quantity,
            itemCount
        )
    }, 0)
}
