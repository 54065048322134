import styled, { css } from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as font from '../constants/fonts'

const _baseStyle = css`
    font-family: ${font.SECONDARY};
    font-weight: 400;
    letter-spacing: -0.035em;
    margin: 0;
    padding: 0;
`

export const Base = styled.div<React.HTMLAttributes<HTMLDivElement>>`
    ${_baseStyle}
`

export const TextButton = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 16px;
    font-weight: 500;
    line-height: 21.5px;
    @media screen and (${breakpoint.TABLET}) {
        font-size: 20px;
        line-height: 23.5px;
    }
`

export const TextPrice = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
`

export const _textArticleStyle = css`
    ${_baseStyle};
    font-size: 18px;
    line-height: 150%;
`

export const TextArticle = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    ${_textArticleStyle}
`

export const Text1 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 16px;
    line-height: 21px;
`

export const _text2Style = css`
    ${_baseStyle};
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
`

export const Text2 = styled.div<React.HTMLAttributes<HTMLDivElement>>`
    ${_text2Style};
`

export const Text3 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
`

export const Text4 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 14px;
    line-height: 21px;
`

export const Text5 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 20px;
    line-height: 30px;
`

export const TextInfo = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 12px;
    line-height: 16px;
`

export const TextInfoSemiBold = styled(TextInfo)`
    font-weight: 600;
`

export const TextHyperlink = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
`

export const TextTag = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
    font-size: 10px;
`
