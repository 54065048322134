import * as React from 'react'

import { AutocompleteListApi } from './types'

export type AutocompleteContext = AutocompleteListApi['register']

export const autocompleteContext = React.createContext<AutocompleteContext>(() => {
  throw Error('AutocompleteItem outside of AutocompleteList')
})

export function useAutocompleteContext(): AutocompleteContext {
  return React.useContext(autocompleteContext)
}

export const AutocompleteContextProvider = autocompleteContext.Provider
