import styled, { css } from 'styled-components'

import * as color from '../constants/colors'
import * as copy from './copy'
import * as heading from './heading'
import * as breakpoints from '../constants/breakpoints'

export const Body = styled.div`
    max-width: 450px;
    padding: 0px 0px 0px 15px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (${breakpoints.TABLET}) {
        padding: 15px 1px 0px 15px;
    }
`

export const Title = styled(heading.TitleCard)`
    color: ${color.GRAY_3};
    flex-basis: 100%;
`

export const Subtitle = styled(copy.Text3)`
    color: ${color.GRAY_3};
    flex-basis: 100%;
`

export const Stock = styled(copy.TextInfo)`
    color: ${color.LIGHT_GREEN};
`

export const Price = styled(copy.TextPrice)`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

export const DiscountedPrice = styled(copy.Text1)`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-decoration: line-through;
    color: ${color.GRAY_1};
`

export const Footer = styled.div`
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    padding-top: 10px;
`

export const Controls = styled.div`
    display: flex;

    > :last-child {
        margin-left: 13px;

        @media screen and (${breakpoints.TABLET}) {
            margin-left: 13px;
        }
    }
`

export const Quantity = styled(copy.TextArticle)`
    margin: 0 20px;
    padding-top: 4px;
`

export const SaveForLater = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;

    > :last-child {
        margin-left: 10px;
        max-width: 76px;

        @media screen and (${breakpoints.MOBILE_L}) {
            max-width: max-content;
            margin-right: 20px;
        }
    }
`

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 584px;

    // small class to simulate mobile mediaquery
    // TODO: FIXME: Use prop instead, when integrating real-world components
    &.small {
        max-width: 355px;

        ${Body} {
            padding: 0px 0px 0px 15px;
        }

        ${Title} {
            font-size: 16px;
        }

        ${SaveForLater} {
            margin-left: 70px;

            > :last-child {
                margin-right: 0;
            }
        }
    }
`
