import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as icon from '../Icon'

const SYMBOL_ID = 'icon-check-def'

FullCheck.filename = SYMBOL_ID
export function FullCheck(props: icon.SpecificIconProps): React.ReactElement {
    return (
        <icon.Icon {...props} name="check">
            <defs>
                <g id={SYMBOL_ID}>
                    <path d="M9 15.586L19.293 5.293l1.414 1.414L9 18.414l-5.707-5.707 1.414-1.414L9 15.586z" />
                </g>
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </icon.Icon>
    )
}

export const Check = styled(function Check(
    props: icon.SpecificIconProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <icon.Icon {...props} name="check">
            <use href={href} />
        </icon.Icon>
    )
})``
