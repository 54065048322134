import { useRouter } from 'next/router'
import styled from 'styled-components'

import * as ds from '@bob/design-system'
import * as cold from '@bob/cold-data'
import { RichText } from '@bob/rich-text'

const coldNotification = cold.notification

export function NotificationBanner(): React.ReactElement | null {
    const router = useRouter()

    if (
        router.pathname.includes('/about') ||
        router.pathname.includes('/account') ||
        router.pathname.includes('/faq') ||
        router.pathname.includes('/404') ||
        router.pathname.includes('/500')
    ) {
        return null
    }

    if (!coldNotification?.notification) return null

    return (
        <Banner color={coldNotification.color ?? undefined}>
            <RichText
                document={coldNotification.notification.json}
                renderNode={{ Paragraph: ds.styles.copy.Text4 }}
            />
        </Banner>
    )
}

const Banner = styled(ds.NotificationBanner)`
    ${ds.styles.wysiwyg.Hyperlink} {
        color: inherit;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
`
