import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../styles'

export type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> & {
    type?: 'text' | 'search' | 'email' | 'password'
    status?: S.form.input.Status
    button?: React.ButtonHTMLAttributes<HTMLButtonElement>
} & S.form.input.InputWrapperProps

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
    function InputComponent(
        { status, button, small, className, ...inputProps },
        inputRef
    ) {
        return (
            <S.form.input.InputWrapper className={className} small={small}>
                <S.form.input.InputStyle
                    $disabled={inputProps.disabled}
                    status={status}
                    haveButton={Boolean(button)}
                >
                    <S.form.input.TextInput ref={inputRef} type="text" {...inputProps} />
                    {button && <S.form.input.Button {...button} />}
                </S.form.input.InputStyle>
            </S.form.input.InputWrapper>
        )
    }
)

export const Input = styled(InputComponent)``
