import * as React from 'react'
import dynamic from 'next/dynamic'
import { default as Link, LinkProps } from 'next/link'
import styled, { ThemeProvider } from 'styled-components'

import * as ds from '@bob/design-system'
import { GeolocationProvider } from '@bob/geolocation'
import { LocalStorageProvider } from '@bob/localstorage'
import { CartProvider } from '@bob/cart'
import { ToastsProvider } from '@bob/toasts'

import { AppFooter } from './Footer'
import { AppHeader } from './Header'
import { NotificationBanner } from './NotificationBanner'

import { useHappening } from '@bob/goodgood/src/hooks/useHappening'

import type { BlackFridayModalProps } from '@bob/goodgood/src/components/BlackFridayModal'
const BlackFridayModal = dynamic<BlackFridayModalProps>(() =>
    import('@bob/goodgood/src/components/BlackFridayModal').then(
        mod => mod.BlackFridayModal
    )
)

type LayoutProps = {
    children: React.ReactNode
}

type GenericLinkProps = Omit<LinkProps, 'href'> & { href?: LinkProps['href'] }

function GenericLink({
    href,
    children,
    ...props
}: React.PropsWithChildren<GenericLinkProps>) {
    if (href !== undefined) {
        return (
            <Link {...props} href={href} prefetch={false}>
                {children}
            </Link>
        )
    }
    return <>{children}</>
}

export function Layout({ children }: LayoutProps): React.ReactElement<unknown> {
    const { isActive, markAsSeen } = useHappening(
        'black-friday',
        '11/25/2022 00:00',
        '11/28/2022 23:59'
    )

    return (
        <>
            <ds.GlobalStyleDesignSystem />
            <React.StrictMode>
                <App>
                    <ds.LinkWrapperProvider value={GenericLink}>
                        <ThemeProvider theme={ds.theme}>
                            <ToastsProvider>
                                <LocalStorageProvider>
                                    <CartProvider>
                                        <GeolocationProvider>
                                            <AppHeader />
                                            <NotificationBanner />
                                            {isActive && (
                                                <BlackFridayModal
                                                    onClose={() => markAsSeen()}
                                                />
                                            )}
                                            <Main>{children}</Main>
                                            <AppFooter />
                                        </GeolocationProvider>
                                    </CartProvider>
                                </LocalStorageProvider>
                            </ToastsProvider>
                        </ThemeProvider>
                    </ds.LinkWrapperProvider>
                </App>
            </React.StrictMode>
        </>
    )
}

export const App = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

export const Main = styled.main`
    flex-grow: 1;
    margin-top: 16px;
    position: relative;
`
