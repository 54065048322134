import styled, {
    css,
    AnyStyledComponent,
    StyledComponent,
    StyledComponentInnerAttrs,
    StyledComponentInnerComponent,
    StyledComponentInnerOtherProps
} from 'styled-components'

export function fromBottom<COMPONENT extends AnyStyledComponent>(
    component: COMPONENT
): StyledComponent<
    StyledComponentInnerComponent<COMPONENT>,
    any,
    StyledComponentInnerOtherProps<COMPONENT> & {
        step: 'before' | 'during' | 'after'
    },
    StyledComponentInnerAttrs<COMPONENT>
> {
    return styled(component)<{ step: 'before' | 'during' | 'after' }>`
        transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

        ${({ step }) => {
            switch (step) {
                case 'before': {
                    return css`
                        transform: translate(0, 100vh);
                    `
                }
                case 'after': {
                    return css`
                        transform: translate(0, 100vh);
                    `
                }
            }
        }}
    `
}
