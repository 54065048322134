import * as React from 'react'
import * as ds from '@bob/design-system'
import styled, { css } from 'styled-components'

type DiscountProps = {
    inPopup?: boolean
    name: string
    src: string
    isBusy: boolean
    onAccept: () => void
    onReject: () => void
}

export function Discount(props: DiscountProps) {
    switch (props.name) {
        case 'free-sogood': {
            return <FreeSoGood {...props} />
        }
    }
    return <></>
}

function FreeSoGood({ isBusy, inPopup, src, onAccept, onReject }: DiscountProps) {
    return (
        <DiscountWrapper inPopup={inPopup}>
            <ds.styles.copy.Text1>
                C’est cadeau&nbsp;! Souhaitez-vous recevoir gratuitement notre{' '}
                <strong>coffret de 5 jeux éco-conçus</strong>&nbsp;?
                <br />
                <i>(offre non cumulable)</i>
            </ds.styles.copy.Text1>
            <ds.Button color="grey" small disabled={isBusy} onClick={onAccept}>
                Oui, ajouter !
            </ds.Button>
            <ds.Picture src={src} />
            <ds.icons.Cross size={18} onClick={onReject} />
        </DiscountWrapper>
    )
}

const DiscountWrapper = styled.div<{ inPopup?: boolean }>`
    position: relative;
    border-radius: 12px;
    padding: 25px 120px 25px 12px;
    background-color: ${ds.color.PINK};
    margin-bottom: 7px;
    overflow: hidden;

    ${ds.icons.Cross} {
        position: absolute;
        top: 23px;
        right: 23px;
    }

    ${ds.styles.copy.Text1} {
        margin-bottom: 20px;
    }

    ${ds.Picture} {
        width: 170px;
        position: absolute;
        top: 70px;
        right: -30px;
    }

    ${({ inPopup }) => {
        if (!inPopup) {
            return css`
                padding-right: 100px;
                padding-left: 12px;
                margin-bottom: 20px;

                ${ds.Picture} {
                    right: -30px;
                }

                @media screen and (${ds.breakpoint.MOBILE_M}) {
                    padding-right: 120px;
                    ${ds.Picture} {
                        right: -10px;
                    }
                }

                @media screen and (${ds.breakpoint.MOBILE_L}) {
                    padding-right: 48%;
                    ${ds.Picture} {
                        right: 5%;
                        top: auto;
                        bottom: 10px;
                    }
                }

                @media screen and (${ds.breakpoint.LAPTOP}) {
                    padding-right: 38.27%;
                    ${ds.Picture} {
                        right: 17px;
                    }
                }

                @media screen and (${ds.breakpoint.LAPTOP_L}) {
                    padding-right: 240px;
                    ${ds.Picture} {
                        right: 50px;
                        top: 20px;
                        bottom: auto;
                    }
                }
            `
        }
    }}
`
