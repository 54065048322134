import Link from 'next/link'
import styled, { css } from 'styled-components'

import * as ds from '@bob/design-system'
import * as url from '@bob/url'

type NavigationMenuProps = {
    desktopDrawerIsOpen: boolean
    setDesktopDrawerAsOpen: (desktopDrawerOpen: boolean) => void
}

function NavigationMenuComponent({
    desktopDrawerIsOpen,
    setDesktopDrawerAsOpen
}: NavigationMenuProps): React.ReactElement {
    return (
        <>
            <DrawerButton
                aria-controls="categories-drawer"
                aria-expanded={desktopDrawerIsOpen}
                onClick={event => {
                    event.preventDefault()
                    setDesktopDrawerAsOpen(!desktopDrawerIsOpen)
                }}
            >
                <span>Catégories</span>
                <Arrow expanded={desktopDrawerIsOpen} fill="none" height="24" width="24">
                    <path d="M6 9l6 6 6-6" stroke="currentColor" strokeWidth="2" />
                </Arrow>
            </DrawerButton>

            <Link prefetch={false} href={url.giftIdeas().path} passHref>
                <DrawerLink>Idées cadeaux</DrawerLink>
            </Link>
            <Link prefetch={false} href={url.articles().path} passHref>
                <DrawerLink>Guides et actus</DrawerLink>
            </Link>
        </>
    )
}

export const DesktopLeftNavigation = styled(NavigationMenuComponent)``

export type ArrowProps = React.HTMLAttributes<HTMLDivElement> & {
    expanded: boolean
}

export const Arrow = styled.svg<ArrowProps>`
    margin-left: 8px;
    transform: rotate(${props => (props.expanded ? 180 : 0)}deg);
    transition: transform 100ms ease-out;
`

export const drawerLinkStyles = css`
    font-family: ${ds.font.PRIMARY};
    font-size: 16px;
    line-height: 1;
    padding: 10px;
    text-align: center;

    @media screen and (${ds.breakpoint.LAPTOP}) {
        font-size: 18px;
        padding: 10px 16px;
    }
`

type DrawerButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

export const DrawerButton = styled.button<DrawerButtonProps>`
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    ${drawerLinkStyles};

    &:focus {
        outline: none;
    }
`

export const DrawerLink = styled(ds.LinkButton)`
    ${drawerLinkStyles};
    display: inline-flex;
    text-decoration: none;

    ${ds.icons.Icon} {
        display: none;
    }

    &:hover {
        font-weight: bolder;
    }
`
