import styled, { css } from 'styled-components'
import { toDataUrl } from '../utils/svg'

export type WrapperProps = {
    $color: string
    $blob: Parameters<typeof toDataUrl>[0]
    $marginLeft?: number
    $marginRight?: number
    $marginTop?: number
    $marginBottom?: number
}

const BaseWrapper = styled.div`
    background-position: center;
    background-size: cover;
`

export const Wrapper = styled(BaseWrapper)<WrapperProps>`
    display: inline-block;
    background-image: url('${dataUrl}');
    ${({ $marginLeft }) => {
        return (
            $marginLeft &&
            css`
                margin-left: -${$marginLeft}px;
                padding-left: ${$marginLeft}px;
            `
        )
    }}
    ${({ $marginRight }) => {
        return (
            $marginRight &&
            css`
                margin-right: -${$marginRight}px;
                padding-right: ${$marginRight}px;
            `
        )
    }}
    ${({ $marginTop }) => {
        return (
            $marginTop &&
            css`
                margin-top: -${$marginTop}px;
                padding-top: ${$marginTop}px;
            `
        )
    }}
    ${({ $marginBottom }) => {
        return (
            $marginBottom &&
            css`
                margin-bottom: -${$marginBottom}px;
                padding-bottom: ${$marginBottom}px;
            `
        )
    }}
`

function dataUrl({ $color, $blob }: WrapperProps) {
    return toDataUrl($blob, { fill: $color })
}
