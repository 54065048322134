import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type CircularButtonProps = S.button.CircularButtonProps &
    React.ButtonHTMLAttributes<HTMLElement>

function CircularButtonComponent({
    borderless,
    children,
    ...buttonProps
}: CircularButtonProps): React.ReactElement {
    return (
        <S.button.CircularButton as="button" borderless={borderless} {...buttonProps}>
            {typeof children === 'string' || typeof children === 'number' ? (
                <S.button.Text>{children}</S.button.Text>
            ) : (
                children
            )}
        </S.button.CircularButton>
    )
}

export const CircularButton = styled(CircularButtonComponent)``
