import * as React from 'react'

import { toastContext } from './context'
import { useToastManager } from './useToastManager'
import { ToastList } from './ToastList'

export function ToastsProvider({
    children
}: {
    children: React.ReactNode
}): React.ReactElement {
    const toastManager = useToastManager()

    return (
        <toastContext.Provider value={toastManager}>
            {children}
            <ToastList />
        </toastContext.Provider>
    )
}
